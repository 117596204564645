import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import moment from 'moment';
import 'moment/locale/es';

// reactstrap components
import {
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    Row
} from "reactstrap";

class Datepicker extends React.Component {
    state = {};
    render() {
        return (
            <>
                <FormGroup>
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <ReactDatetime
                            locale="es-es"
                            closeOnSelect={true}
                            timeConstraints={{ hours: { min: 0, max: 23, step: 1 }, minutes:{step: 10}}}
                            inputProps={{
                                placeholder: "Seleciona fecha"
                            }}
                            timeFormat={"HH:mma"}
                        />
                    </InputGroup>
                </FormGroup>
            </>
        );
    }
}

export default Datepicker;
import React from "react";
import {Button, Card, CardBody, CardHeader, Form, Input, Modal} from "reactstrap";
import {Line} from "react-chartjs-2";
import {chartExample3} from "../../../../variables/charts";
import axios from "axios";


const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
    'Content-Type': 'application/json',
    'x-api-key': VOLTS_API_KEY
};

//ver qué pasa con gw-mac: id, hay que pasar el dato
async function put_booking(event,id) {
    const axios_config = {'headers': api_headers}
    const booking = {
        'gw-mac': id,
        'unit-uuid': '',
        'check-in': event.target[0].value,
        'check-out': event.target[1].value,
        'pax-total': event.target[2].value,
        'kids':event.target[3].value,
        'booking-reference': event.target[4].value,
        //'country-origin': ,
        //'booking-provider':,
        //'status':
        //'language':
    }
    console.log("PUT_BOOKING headers", api_headers)
    console.log(event)
    const res = await axios.put('https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/bookings', booking, axios_config);
    console.log("BOOKING: ->>>", res)
    return (res)
}
class Modals extends React.Component {

    state = {
        exampleModal: false
    };
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    handleSubmit(event)
    {
        event.preventDefault()
        console.log('EVENT FORM', event)
        console.log(put_booking(event))
        //console.log("STATE_VALUE", this.state.value)
        ;
        //toggleModal('formModal')
    }

    render() {
        return (
            <>
                <Button
                    color="primary"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal")}
                >
                    Check Unit
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={() => this.toggleModal("formModal")}
                >
                    Check Form
                </Button>
                <Modal
                    className="modal-xl"
                    //size="sm"
                    isOpen={this.state.formModal}
                    toggle={() => this.toggleModal("formModal")}
                >
                    <div className="modal-body p-0">
                        <Card className="bg-secondary shadow border-0">
                            <CardHeader className="bg-transparent pb-5">
                                {/*<div className="text-muted text-center mt-2 mb-3">
                      <small>Create New Booking</small>
                    </div>*/}
                                <h5 className="h3 mb-0">Crear Booking Manual</h5>
                                {/*<div className="btn-wrapper text-center">
                    </div>*/}
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.toggleModal("formModal")}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </CardHeader>
                            <CardBody className="px-lg-5 py-lg-5">
                                {/*<div className="text-center text-muted mb-2">
                      <medium>Create</medium>
                    </div>*/}
                                <Form role="form"  onSubmit={this.handleSubmit}>

                                    <label
                                        className="form-control-label"
                                        htmlFor="example-search-input"
                                    >
                                        Check-In Date
                                    </label>
                                    <Input
                                        defaultValue={new Date().getFullYear() + "-11-23T15:00:00"}
                                        id="checkin-datetime-local-input"
                                        type="datetime-local"
                                    />

                                    <label
                                        className="form-control-label"
                                        htmlFor="example-search-input"
                                    >
                                        Check-Out Date
                                    </label>
                                    <Input
                                        defaultValue={new Date().getFullYear() + "-" + new Date().getMonth() + "-23T11:00:00"}
                                        id="checkout-datetime-local-input"
                                        type="datetime-local"
                                    />

                                    <label
                                        className="form-control-label"
                                        htmlFor="example-search-input"
                                    >
                                        Pax Total
                                    </label>
                                    <Input
                                        defaultValue={"2"}
                                        id="pax-input"
                                        type="number"
                                    />
                                    <div className="custom-control custom-control-alternative custom-checkbox">

                                        <input
                                            className="custom-control-input"
                                            id="child-check"
                                            type="checkbox"
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="child-check"
                                        >
                                            <span className="text-muted">Have Kids?</span>
                                        </label>
                                    </div>
                                    <label
                                        className="form-control-label"
                                        htmlFor="reference-input"
                                    >
                                        Booking Reference
                                    </label>
                                    <Input
                                        defaultValue={"#"}
                                        id="reference-input"
                                        type="text"
                                    />
                                    {/*<div className="custom-control custom-control-alternative custom-checkbox">
                        <label
                            className="form-control-label"
                            htmlFor="example-search-input"
                        >
                          Force StandBy?
                        </label>
                        <label className="custom-toggle">
                          <input defaultChecked type="checkbox" />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </div>*/}
                                    {/*}
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Email" type="email" />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Password" type="password" />
                        </InputGroup>
                      </FormGroup>
                      */}
                                    {/*<div className="custom-control custom-control-alternative custom-checkbox">

                        <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                        />
                        <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                        >
                          <span className="text-muted">Remember me</span>
                        </label>
                      </div>*/}
                                    <div className="text-right">
                                        <Button
                                            color="secondary"
                                            data-dismiss="modal"
                                            type="button"
                                            onClick={() => this.toggleModal("formModal")}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="my-4"
                                            color="primary"
                                            type="submit" value="Submit"
                                        >
                                            Create Booking
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.exampleModal}
                    toggle={() => this.toggleModal("exampleModal")}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Modal title
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("exampleModal")}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Card>
                            <CardHeader>
                                <h6 className="surtitle">Overview</h6>
                                <h5 className="h3 mb-0">Total Events</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="chart">
                                    <Line
                                        data={chartExample3.data}
                                        options={chartExample3.options}
                                        id="chart-sales"
                                        className="chart-canvas"
                                    />
                                </div>
                            </CardBody>
                        </Card>

                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("exampleModal")}
                        >
                            Close
                        </Button>
                        <Button color="primary" type="button">
                            Save changes
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default Modals
import React from "react";
import List from "list.js";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  UncontrolledTooltip, CardBody,
} from "reactstrap";


function SortableIncident({incidents}) {
  const firstListRef = React.useRef(null);
  React.useEffect(() => {
    new List(firstListRef.current, {
      valueNames: ["time", "severity", "incident", "state"],
      listClass: "list",
    });
  }, []);

  let incident_cells = incidents
  console.log(incident_cells)
  return (
    <>
      {/*<Container className="mt--6" fluid>
        <Row>
          <div className="col">*/}
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Incidentes</h3>
              </CardHeader>
              <CardBody>
                {incident_cells.length > 0 ?
                    <div className="table-responsive" ref={firstListRef}>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                        <tr>
                          <th className="sort" data-sort="time" scope="col">
                            Time
                          </th>
                          <th className="sort" data-sort="severity" scope="col">
                            Severity
                          </th>
                          <th className="sort" data-sort="incident" scope="col">
                            Incident
                          </th>
                          <th scope="col">State</th>
                          <th className="sort" data-sort="state" scope="col">
                            State
                          </th>
                          <th scope="col"/>
                        </tr>
                        </thead>
                        <tbody className="list">
                        {incident_cells.map((incidents_row, idx) => (
                            <tr key={"child" + incidents_row['uuid']}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a
                                      className="avatar rounded-circle mr-3"
                                      href="#pablo"
                                      id={"s" + idx.toString()}
                                      onClick={(e) => e.preventDefault()}
                                  >
                                    <img
                                        alt="..."
                                        src={
                                          require("assets/img/theme/bootstrap.jpg")
                                              .default
                                        }
                                    />
                                    <UncontrolledTooltip
                                        delay={0}
                                        target={"s" + idx.toString()}
                                    >
                                      {incidents_row['uuid']}
                                    </UncontrolledTooltip>
                                  </a>
                                  <Media>
                                <span className="time mb-0 text-sm">
                                  {new Date(Number(incidents_row['timestamp']) * 1000).toLocaleString()}
                                </span>
                                  </Media>
                                </Media>
                              </th>
                              <td>
                                <Button color={"warning"} outline size="sm" type="button">
                                  {incidents_row.severity}
                                </Button>
                              </td>
                              <td>


                                <div className="d-flex align-items-center">
                                  <span className="battery mr-2">{}</span>
                                  <div>
                                    {incidents_row['incident-text']}
                                    {/*}
                                <Progress max="100" value={Number(states_row['attribute_battery_state'])} color="warning"/>
                                */}
                                  </div>
                                </div>
                              </td>
                              <td className="state">{incidents_row['status']}</td>
                              <td>
                                <Badge color="" className="badge-dot mr-4">
                                  <i className="state bg-warning"/>
                                  <span className="status">{incidents_row['status']}</span>
                                </Badge>
                              </td>
                              <td className="text-right">
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </Table>
                    </div>
                    :
                    <>{"No incidents on file"}</>
                }
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
      {/*}
          </div>
        </Row>


        <Row>
          <div className="col">


          </div>
        </Row>
      </Container>
*/}
    </>
  );
}

export default SortableIncident;

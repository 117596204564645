// nodejs library to set properties for components
import PropTypes from "prop-types";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import DashHeaderCard from "../VoltsCards/volts-base-cards/volts-dash-header-card";
import DashHeaderCardSimple from "../VoltsCards/volts-base-cards/volts-dash-header-base-card";

function CardsHeader({ name, parentName, unit }) {
  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
                <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="/auth/login">
                      {/*onClick={(e) => e.preventDefault()}*/}
                      <i className="fas fa-home"/>
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="/admin/dashboard">
                      {/*onClick={(e) => e.preventDefault()}*/}
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              {/*}
              <Col md="6" xl="3">
                <DashHeaderCard seriesParams={{
                  "title": "Relay Energy",
                  "measure-unit": "dB",
                  "params": {
                    "sensor-mac": "XzFvolts-test",
                    "attribute": "relay_energy",
                    "frequency": 900,
                    "format": "query",
                    "graph-series-attr": "",
                    "sensor-functionalities": "BOILER,HVAC",
                    "gw-mac": "voltsgy112",
                    "unit-uuid": "f00dcfd4-ba42-4e4e-bf4c-fad2d634226b"
                  }
                }}/>
              </Col>
              */}
              {/*}
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Active Alarms
                        </CardTitle>
                        <p className="text-sm text-warning mb-0"><b>Floods</b>: <b>1</b></p>
                        <p className="text-sm mb-0">Power Outage: <b>0</b></p>
                        <p className="text-sm mb-0">Internet Drop: <b>0</b></p>
                      </div>

                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-bell-55" />
                        </div>
                      </Col>
                    </Row>


                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Last 72 hours</span>
                    </p>


                  </CardBody>
                </Card>
              </Col>
*/}
              {/*}
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Check-outs Today
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">0</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-send" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 5.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <DashHeaderCardSimple unit={unit}/>
              </Col>
              */}

            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;

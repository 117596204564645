import React from "react";
import {
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    Table,
    Media,
    Badge,
    UncontrolledTooltip,
    Progress,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Pagination,
    PaginationItem,
    PaginationLink, CardFooter, Card, CardHeader, CardBody
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import VoltsCard from "./voltsCardAc";
import ListUnits from "./ListUnits";
import VoltsUnitCard from "./volts-unit-card";


function CardsUnits({units}) {
    const thirdListRef = React.useRef(null);

    let loadede = false
    let latestMessage = "Bye"
    console.log("CARDS: " , units)
    return (
        <>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h5 className="h3 mb-0">Units</h5>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                        <Row className={"mt-3"}>
                            {units.map((unit) => (
                                <div key={unit.unit['gw-mac']} className="col-md-6">
                                    <Col className={"mt-1"}>
                                        <VoltsUnitCard unit={ unit }/>
                                    </Col>
                                </div>
                            ))}
                        </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default CardsUnits;
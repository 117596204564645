import React from "react";
import { Routes, Router, Switch, Route, Link, useParams} from "react-router-dom";
import Order from "./Order"
import { useState, useEffect, useCallback, useRef } from "react";
import SocketPower from "./volts-socket-power-graph";
import Progresses from "../../../components/VoltsCards/Volts-Booking-Progress";
import Modals from "../../../views/pages/volts/volts-unit/booking-modals";
import ListUnits from "../../../components/VoltsCards/ListUnits";
import VoltsToDo from "../../../components/VoltsCards/volts-to-do";
import VoltsMainDoor from "../../../components/VoltsCards/volts-alerts"
import VoltsEvents from "../../../components/VoltsCards/volts-events";
import VoltsSensorCards from "../../../components/VoltsCards/volts-sensor-cards";
import VoltsNoiseGraph from "../../../components/VoltsCards/volts-noise-graph";
import VoltsMapCard from "../../../components/VoltsCards/volts-map-card";
import Socket from "./volts-socket-cards";
import VoltsBar from "../../../components/VoltsCards/volts-bar"
import Activities from "../../../components/VoltsCards/volts-activities";
import CardSimple from "../../../components/VoltsCards/CardSimple";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components


import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";

// core components
import CardsHeaderUnit from "components/Headers/CardsHeaderUnit.js";


import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2, chartExample3,
} from "variables/charts.js";

import axios from "axios"
import moment from "moment";
//import { useState, useEffect, useCallback, useRef } from "react";
//import Text from "quill/blots/text";


let unit_item = {};


async function axios_unit(id) {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/unit/full", {params:{
        'gw-mac': id
  }})
  console.log("AXIOS UNIT",response)
  return response.data
}


function set_unit(data) {
  unit_item = data
  console.log("SET UNIT", unit_item)
  return unit_item
}

const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
  'Content-Type': 'application/json',
  'x-api-key': VOLTS_API_KEY
};

function VoltsUnit() {

  let param = useParams();

  const [unit, setUnit] = React.useState(null);
  //const [mapparams, setMapparams] = React.useState(null);
  const [maindoor, setMaindoor] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const thirdListRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const { id } = useParams();
  const { space } = useParams();

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    const loadUnitData = async () => {
      const data = await axios_unit(id);
      setUnit(data);
      set_unit(data);
      //params = {location: data.unit.latlng, address: data.unit.address, friendlyname: data.unit.friendlyname}
      //setMapparams(params)
      console.log("ID->>", id)
      console.log("DATA UNIT", data)
      console.log("UNIT: ", data, id)
      {/*}
      var indexSensor = data['sensors'].findIndex(function (el) {
        return el['sensor-location'] === 'MAIN-DOOR';
      });
      console.log("INDEX SENSOR", indexSensor)
      if (indexSensor !== -1) {
        setMaindoor(indexSensor)
        console.log("MAIN DOOR IS: ", data['sensors'][indexSensor]['friendly-name'])
      }
      */}

      setLoaded(true);
    };
    setTimeout(() => {
      loadUnitData();
    }, 100);
  },[]);


  //axios_unit().then(unit_items => set_units(unit_items));
  //console.log("UNITS", unit_items)

  //console.log("IDDDDDD", id)
  //console.log("PARAMS", param)
  //console.log("Unit Items in volts unit ", units.items)
  //console.log("IDsssssss--->", param, id, args)

  let main_door_sensor = "0xa4c1385eab6f5490"
  let noise_sensor = "Xzivolts0000000"
  let spaceA = 'MAIN-DOOR'

  let params = {
  params:{
    'gw-mac': id,
    'sensor-mac': main_door_sensor,
    'timestamp-offset': '1665700000.0',
    'timestamp-end': '1665783026.0',
    'attributes': 'contact',
    'items': 4,
  }
}
  console.log("O&E ", (moment().unix() - 36000) + ".0" , (moment().unix()) + ".0")
  console.log("SPACE--->", space)
  console.log("ID--->", id)
  if (typeof (space)!= 'undefined') {
    spaceA = space.toUpperCase()
  }
  //console.log("UNIT--->", unit.unit)
  return (
      <>
        { loaded ? <CardsHeaderUnit className="mb-3" unit={unit} /> : <></> }
        <Container className="mt--5" fluid>
          <Row>
            <Col xl="8">
              {/*}
              {loaded && maindoor !== null? <VoltsBar main_door_sensor={
                                {params:{
                                    'gw-mac': id,
                                    'sensor-mac': unit.sensors[maindoor]['sensor-mac'],
                                    'timestamp-end': '1667779200' + '.0',
                                    'timestamp-offset': '1667606400' + '.0',
                                    'attributes': 'contact',
                                    'items': 40,
                                  }
                                }}/> : <></>}

*/}
              {loaded  ? <Socket unit={unit.unit} space={spaceA}/> : <></>}

              {/*---<<<<<<<<}
              {loaded ? <Activities unit={unit.unit}/>: <></>}
              */}

              {/*<VoltsNoiseGraph />*/}

              {/*TEST---><Modals />*/}

              {/*loaded ? <VoltsSensorCards unit={unit.unit['gw-mac']} /> : <></>*/}
            </Col>
            <Col xl="4">
              {/*}
              {loaded ? <Progresses unit={unit.unit}/> : <>Loading</>}
              */}

              {/*}
              {loaded ? <SocketPower unit={unit.unit['gw-mac']}/> : <></> }
              */}

              { loaded ? <VoltsMapCard params={unit['map-params']}/> : <> {"Loading MAP"}</>}

              {/*}
              {loaded && maindoor !== null? <VoltsEvents params={
                {params:{
                  'gw-mac': id,
                  'sensor-mac': unit.sensors[maindoor]['sensor-mac'],
                    'timestamp-offset': (moment().unix() - 72000) + ".0",
                    'timestamp-end': (moment().unix())+ ".0",
                  'attributes': 'contact,is_new',
                  'items': 100,
                }
              }}/> : <>Loading</>}*/}
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              {/*}
              <td className="align-middle text-center text-sm">
                <span className="badge badge-sm badge-secondary">Offline</span>
              </td>
              <td className="align-middle text-center text-sm">
                <span className="badge badge-sm badge-success">Online</span>
              </td>
              */}
              {/*-<<<<<<<<}
              {loaded ? <VoltsToDo sensor={main_door_sensor}/> : <>Loading...</>}
              */}
            </Col>
            <Col xl="6">
              {/*<VoltsEvents />*/}
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default VoltsUnit;

import { faBolt } from '@fortawesome/pro-solid-svg-icons'
import { faTicketAirline } from '@fortawesome/pro-solid-svg-icons'
import { faTag } from '@fortawesome/pro-solid-svg-icons'
import { faChargingStation } from '@fortawesome/pro-solid-svg-icons'
import { faTransformerBolt } from '@fortawesome/pro-solid-svg-icons'
import { faWifi } from '@fortawesome/pro-solid-svg-icons'
import { faRouter } from '@fortawesome/pro-solid-svg-icons'
import { faSimCard } from '@fortawesome/pro-solid-svg-icons'
import { faSunrise } from '@fortawesome/pro-solid-svg-icons'
import { faClipboardCheck } from '@fortawesome/pro-solid-svg-icons'
import { faBell } from '@fortawesome/pro-solid-svg-icons'
import { faWaveform } from '@fortawesome/pro-solid-svg-icons'
import { faFireExtinguisher } from '@fortawesome/pro-solid-svg-icons'
import { faHeartPulse } from '@fortawesome/pro-solid-svg-icons'
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons'
import { faKey } from '@fortawesome/pro-solid-svg-icons'
import { faHouseFloodWater } from '@fortawesome/pro-solid-svg-icons'
import { faDoorClosed } from '@fortawesome/pro-solid-svg-icons'
import { faPersonThroughWindow } from '@fortawesome/pro-solid-svg-icons'
import { faPersonShelter } from '@fortawesome/pro-solid-svg-icons'
import { faTemperatureHigh } from '@fortawesome/pro-solid-svg-icons'
import { faDropletPercent } from '@fortawesome/pro-solid-svg-icons'
import { faBoltLightning } from '@fortawesome/pro-solid-svg-icons'
import { faBoltSlash } from '@fortawesome/pro-solid-svg-icons'
import { faUser} from '@fortawesome/pro-solid-svg-icons'
import { faAirConditioner} from "@fortawesome/sharp-solid-svg-icons";

import { faBuildingUser } from '@fortawesome/pro-solid-svg-icons'
import { faHouseTree } from '@fortawesome/pro-solid-svg-icons'
import { faBed } from '@fortawesome/pro-solid-svg-icons'
import { faBuilding } from '@fortawesome/pro-solid-svg-icons'
import { faBedBunk } from '@fortawesome/pro-solid-svg-icons'
import { faBusinessTime } from '@fortawesome/pro-solid-svg-icons'
import { faMartiniGlass } from '@fortawesome/pro-solid-svg-icons'
import { faSquareParking } from '@fortawesome/pro-solid-svg-icons'
import { faDesktop } from '@fortawesome/pro-solid-svg-icons'
import { faKitchenSet } from '@fortawesome/pro-solid-svg-icons'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { faMapLocation } from '@fortawesome/pro-solid-svg-icons'
import { faPhone } from '@fortawesome/pro-solid-svg-icons'
import { faWarehouseFull } from '@fortawesome/pro-solid-svg-icons'
import { faBellConcierge } from '@fortawesome/pro-solid-svg-icons'
import { faFile } from '@fortawesome/pro-solid-svg-icons'
import {faEnvelope} from '@fortawesome/pro-solid-svg-icons'


export const sensorIcons = {
    "PONG": {"icon": "droplet-percent", "true": "fa-door-closed", "false": "fa-door-open", "off": "", "family": "", "style": "fa-solid"},
    "RFID": {"icon": "tag", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "STAY": {"icon": "ticket-airline", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "EV_CHARGER": {"icon": "charging-station", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "ELECTRICAL_SUPPLY": {"icon": "transformer-bolt", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "POWER": {"icon": "transformer-bolt", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "WIFI_ACCESS_POINT": {"icon": "wifi", "true": "", "false": "fa-wifi-slash", "off": "", "family": "", "style": "fa-solid"},
    "IP_CHECK": {"icon": "router", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "SIM-CARD": {"icon": "sim-card", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "SUN": {"icon": "sunrise", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "ACTIONS": {"icon": "clipboard-check", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "ALERTS": {"icon": "bell", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "NOISE": {"icon": "waveform", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "SMOKE": {"icon": "fire-extinguisher", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "LAST_SEEN": {"icon": "heart-pulse", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "HVAC": {"icon": "air-conditioner", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "BOILER": {"icon": "heart-pulse", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "LAT_LNG": {"icon": "location-dot", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "LOCK": {"icon": "key", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "FLOOD": {"icon": "house-flood-water", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
    "DOOR": {"icon": "door-closed", "true": "fa-door-closed", "false": "fa-door-open", "off": "", "family": "", "style": "fa-solid"},
    "WINDOW": {"icon": "person-through-window", "true": "fa-door-closed", "false": "fa-door-open", "off": "", "family": "", "style": "fa-solid"},
    "HUMAN_ACTIVITY": {"icon": "person-shelter", "true": "fa-door-closed", "false": "fa-door-open", "off": "", "family": "", "style": "fa-solid"},
    "PRESENCE": {"icon": "person-shelter", "true": "fa-door-closed", "false": "fa-door-open", "off": "", "family": "", "style": "fa-solid"},
    "TEMP": {"icon": "temperature-high", "true": "fa-door-closed", "false": "fa-door-open", "off": "", "family": "", "style": "fa-solid"},
    "HUM": {"icon": "droplet-percent", "true": "fa-door-closed", "false": "fa-door-open", "off": "", "family": "", "style": "fa-solid"},
    "VOLTS_STATUS": {"icon": "transformer-bolt", "true": "", "false": "", "off": "", "family": "", "style": "fa-solid"},
}





export const voltsIcons = {
    "bolt": faBolt,
    "envelope": faEnvelope,
    "ticket-airline": faTicketAirline,
    "tag": faTag,
    "charging-station": faChargingStation,
    "transformer-bolt": faTransformerBolt,
    "wifi": faWifi,
    "router": faRouter,
    "simcard": faSimCard,
    "sunrise": faSunrise,
    "clipboard-check": faClipboardCheck,
    "bell": faBell,
    "waveform": faWaveform,
    "fire-extinguiser": faFireExtinguisher,
    "heart-pulse": faHeartPulse,
    "location-dot": faLocationDot,
    "key": faKey,
    "house-flood-water": faHouseFloodWater,
    "door-closed": faDoorClosed,
    "person-through-window": faPersonThroughWindow,
    "person-shelter": faPersonShelter,
    "temperature-high": faTemperatureHigh,
    "droplet-percent": faDropletPercent,
    "bolt-lightning": faBoltLightning,
    "bolt-slash": faBoltSlash,
    "user": faUser,
    "air-conditioner": faAirConditioner,

    "building-user": faBuildingUser,
    "house-tree": faHouseTree,
    "bed": faBed,
    "building": faBuilding,
    "bed-bunk": faBedBunk,
    "business-time": faBusinessTime,
    "martini-glass": faMartiniGlass,
    "square-parking": faSquareParking,
    "desktop": faDesktop,
    "kitchen-set": faKitchenSet,
    "lock": faLock,
    "map-location": faMapLocation,
    "phone": faPhone,
    "warehouse-full": faWarehouseFull,
    "bell-concierge": faBellConcierge,

    "file": faFile

}
import React from "react";
import {useParams} from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
//import {func} from "prop-types";
import axios from "axios"
import moment from "moment";
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
    CardTitle,
  //Form,
  //Input,
  Container,
  Row,
  Col, Badge,
  //Modal,
} from "reactstrap";

import VoltsBaseCardJson from "../../../components/VoltsCards/volts-base-cards/volts-base-card-json";
import VoltsTemperatureGauge from "./volts-temperature-gauge";
import CardsHeaderSensor from "components/Headers/CardsHeaderSensor.js";
import VoltsSensorSortable from "../tables/voltsSensorSortableContact";
import VoltsCardsCard from "../../../components/VoltsCards/volts-voltscards-card";
import VoltsSensorSpecCard from "../../../components/VoltsCards/volts-sensor-specs-card";
import VoltsSensorLatestStateCard from "../../../components/VoltsCards/volts-sensor-latest-state-card";
import VoltsSwitchboxCard from "../../../components/VoltsCards/volts-sensor-graph-card";
import VoltsSensorNumeralGraph from "../../../components/VoltsCards/volts-sensor-graph-card-numeral"
import GetMainAttr from "../../../variables/attributeArray"
import {GetAttrObject} from "../../../variables/attributeArray";
//import Datepicker from "../forms/voltsDatePicker";
import BodyCardSeriesGraph from "../../../components/VoltsCards/volts-base-cards/volts-body-card-series-graph";
import Modals from "../../../components/VoltsCards/volts-base-cards/volts-base-modal-json"
import DashHeaderCardGraph from "../../../components/VoltsCards/volts-base-cards/volts-dash-header-base-card_graph"
import VoltsMultiRadialCardBooking from "../../../components/VoltsCards/volts-graph-cards/volts-multi-radial-graph-booking";

function BookingTimeline()  {

  return (
  <>

    <Card className="bg-gradient-default shadow">
      <CardHeader className="bg-transparent">
        <h3 className="mb-0 text-white">Acciones volts</h3>
      </CardHeader>
      <CardBody>
        <div
            className="timeline timeline-one-side"
            data-timeline-axis-style="dashed"
            data-timeline-content="axis"
        >
          <div className="timeline-block">
                    <span className="timeline-step badge-success">
                      <i className="ni ni-bell-55"/>
                    </span>
            <div className="timeline-content">
              <small className="text-light font-weight-bold">
                10:30 AM
              </small>
              <h5 className="text-white mt-3 mb-0">New message</h5>
              <p className="text-light text-sm mt-1 mb-0">
                Nullam id dolor id nibh ultricies vehicula ut id elit.
                Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus.
              </p>
              <div className="mt-3">
                <Badge color="success" pill>
                  design
                </Badge>
                <Badge color="success" pill>
                  system
                </Badge>
                <Badge color="success" pill>
                  creative
                </Badge>
              </div>
            </div>
          </div>
          <div className="timeline-block">
                    <span className="timeline-step badge-danger">
                      <i className="ni ni-html5"/>
                    </span>
            <div className="timeline-content">
              <small className="text-light font-weight-bold">
                10:30 AM
              </small>
              <h5 className="text-white mt-3 mb-0">Product issue</h5>
              <p className="text-light text-sm mt-1 mb-0">
                Nullam id dolor id nibh ultricies vehicula ut id elit.
                Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus.
              </p>
              <div className="mt-3">
                <Badge color="danger" pill>
                  design
                </Badge>
                <Badge color="danger" pill>
                  system
                </Badge>
                <Badge color="danger" pill>
                  creative
                </Badge>
              </div>
            </div>
          </div>
          <div className="timeline-block">
                    <span className="timeline-step badge-info">
                      <i className="ni ni-like-2"/>
                    </span>
            <div className="timeline-content">
              <small className="text-light font-weight-bold">
                10:30 AM
              </small>
              <h5 className="text-white mt-3 mb-0">New likes</h5>
              <p className="text-light text-sm mt-1 mb-0">
                Nullam id dolor id nibh ultricies vehicula ut id elit.
                Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus.
              </p>
              <div className="mt-3">
                <Badge color="info" pill>
                  design
                </Badge>
                <Badge color="info" pill>
                  system
                </Badge>
                <Badge color="info" pill>
                  creative
                </Badge>
              </div>
            </div>
          </div>
          <div className="timeline-block">
                    <span className="timeline-step badge-success">
                      <i className="ni ni-bell-55"/>
                    </span>
            <div className="timeline-content">
              <small className="text-light font-weight-bold">
                10:30 AM
              </small>
              <h5 className="text-white mt-3 mb-0">New message</h5>
              <p className="text-light text-sm mt-1 mb-0">
                Nullam id dolor id nibh ultricies vehicula ut id elit.
                Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus.
              </p>
              <div className="mt-3">
                <Badge color="success" pill>
                  design
                </Badge>
                <Badge color="success" pill>
                  system
                </Badge>
                <Badge color="success" pill>
                  creative
                </Badge>
              </div>
            </div>
          </div>
          <div className="timeline-block">
                    <span className="timeline-step badge-danger">
                      <i className="ni ni-html5"/>
                    </span>
            <div className="timeline-content">
              <small className="text-light font-weight-bold">
                10:30 AM
              </small>
              <h5 className="text-white mt-3 mb-0">Product issue</h5>
              <p className="text-light text-sm mt-1 mb-0">
                Nullam id dolor id nibh ultricies vehicula ut id elit.
                Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus.
              </p>
              <div className="mt-3">
                <Badge color="danger" pill>
                  design
                </Badge>
                <Badge color="danger" pill>
                  system
                </Badge>
                <Badge color="danger" pill>
                  creative
                </Badge>
              </div>
            </div>
          </div>
        </div>

      </CardBody>
    </Card>

    </>)
}

{/*}
import {
  chartOptions,
  parseOptions,
  //chartExample1,
  //chartExample2, chartExample3,
} from "variables/charts.js";
*/
}
//from "../../../components/VoltsCards/volts-base-cards/volts-dash-header-base-card_graph";

{/*
import { Chart } from "chart.js";
*/}


//import Progresses from "../../../components/VoltsCards/Volts-Booking-Progress";
//import ListUnits from "../../../components/VoltsCards/ListUnits";
//import VoltsToDo from "../../../components/VoltsCards/volts-to-do";
//import VoltsMainDoor from "../../../components/VoltsCards/volts-alerts"
//import VoltsEvents from "../../../components/VoltsCards/volts-events";
//import VoltsSensorCards from "../../../components/VoltsCards/volts-sensor-cards";
//import VoltsNoiseGraph from "../../../components/VoltsCards/volts-noise-graph";
//import VoltsMapCard from "../../../components/VoltsCards/volts-map-card";
//import Socket from "./volts-socket-cards";
//import VoltsBar from "../../../components/VoltsCards/volts-bar"
//import ActivitiesGyg from "../../../components/VoltsCards/volts-activities";
//import CardSimple from "../../../components/VoltsCards/CardSimple";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
//import { Line, Bar } from "react-chartjs-2";


let sensor_item = {};
let graph_data = {};


async function axios_graph(unit_uuid, sensor_mac, gw_mac, functionality, timestamp_offset, timestamp_end) {
  //let json_test = {attributes: ["temperature", "occupancy"]}
  const response = await axios.get(
      "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/dashboards/switchbox/relay-graph",
      {params:{
          'gw-mac': gw_mac,
          'sensor-list': sensor_mac,
          'unit-uuid': unit_uuid,
          'graph-attribute': GetMainAttr(functionality),
          'power-sensor-types': 'ZIGBEE',
        //'timestamp-offset': (moment().unix() - 28800) + ".0",
        //'timestamp-end': (moment().unix())+ ".0",
          'timestamp-offset': timestamp_offset,
          'timestamp-end': timestamp_end,
        //'attributes': JSON.stringify(json_test)
      //'timestamp-offset':'1671397069.396703',
       // 'timestamp-end': '1671483469.397184'
    }})
  //console.log("AXIOS graph", response)
  //console.log("%%%%%%%%%%", functionality)
  return response.data
}


async function axios_sensor(unit_uuid, sensor_mac, gw_mac) {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/sensors", {params:{
        'gw-mac': gw_mac,
        'sensor-mac': sensor_mac,
        'unit-uuid': unit_uuid
  }})
  //console.log("AXIOS RESPONSE SENSOR ",response)
  return response.data
}


function set_graph_data(datar) {
  graph_data = datar
  console.log("GRAPH DATA: ", graph_data)
  return graph_data
}


function set_sensor(data) {
  sensor_item = data
  console.log("SET SENSOR", sensor_item)
  return sensor_item
}


const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
  'Content-Type': 'application/json',
  'x-api-key': VOLTS_API_KEY
};


function VoltsBookingDetails() {

  //let param = useParams();
  const [sensor, setSensor] = React.useState(null);
  const [graphData, setgraphData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const [loadedr, setLoadedr] = React.useState(null);
  const [mainAttr, setmainAttr] = React.useState(null);
  const [mainAttrObject, setmainAttrObject] = React.useState(null);
  const [timestampOffset, settimestampOffset] = React.useState((moment().unix() - 86400));
  const [timestampEnd, settimestampEnd] = React.useState((moment().unix()));
  const thirdListRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

  //PARAMETROS PASADOS EN URL
  const { booking_uuid } = useParams();
  //const { sensor_mac } = useParams();
  const { gw_mac } = useParams(); // gw-mac
  //const { sensor_function } = useParams();


  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };


  {/*}
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  */}


  useEffect(() => {

    const loadSensorGraph = async () => {
      //console.log("TIMES: " , timestampOffset, timestampEnd)
      const datar = await axios_graph(
          //unit_uuid,
          //sensor_mac,
          //gw_mac,
          //sensor_function.toUpperCase(),
          timestampOffset,
          timestampEnd
      );
      console.log("SENSORGRPAH--->", datar)
      setgraphData(datar)
      set_graph_data(datar)
      setLoadedr(true)
    };


    const loadSensorData = async () => {

      //const data = await axios_sensor(unit_uuid, sensor_mac, gw_mac);
      //console.log("dddddddddATA: ", data)
      //setSensor(data);
      //set_sensor(data);
      setLoaded(true);
      //setmainAttr(GetMainAttr(data['sensor']['sensor-function']));
      //setmainAttrObject(GetAttrObject(data['sensor']['sensor-function']))
      //console.log("MAINATTR", mainAttr)

    };
    setTimeout(() => {
      //loadSensorData();
      //loadSensorGraph();
    }, 1);
  },[]);


  {/*let sensorf = sensor_function*/}

  //console.log ("SSSENNSOR: ", sensorf, sensor_function)
  //console.log("sensor-->", sensor)

  let energy = {
    "timestamp-offset": 1682510400,
    "unit-uuid": "f00dcfd4-ba42-4e4e-bf4c-fad2d634226b",
    "energy": {
      "total-watt-minute": "6796846.47",
      "energy-labels": [
        "Climatización",
        "Termo ACS"
      ],
      "kwh-series-total": [
        "0.06",
        "113.28"
      ],
      "kwh-total": "113.34",
      "percent-kwh-series": [
        "0.05",
        "99.95"
      ],
      "kwh-series": [
        []
          ],
      "timestamp": 1683397343,
      "series-total": [
        "451725.0",
        "18956179.0"
      ]
    },
    "timestamp-end": 1682637300,
    "booking-uuid": "343366a4-6141-42a9-906e-1c5a9ace02e1"
  }

  let unit = {
    "typo": "NOT_SET",
    "gw-state": "PROVISIONING",
    "cms-id": 240688219,
    "device-type": "GW",
    "address": "Calle de Goya, 112, 28009 Madrid, Spain",
    "friendlyname": "Apartamento Goya 112",
    "logger": "ERROR",
    "pms-unit-id": " ",
    "creation-time": "1672337088.3620348",
    "hosted-zone": "eu-west-1",
    "ui-lang": "es-ES",
    "latlng": {
      "lng": "-3.6699493",
      "lat": "40.4243316"
    },
    "uuid": "f00dcfd4-ba42-4e4e-bf4c-fad2d634226b",
    "mac-address": "30:ae:7b:e2:e6:01",
    "unit-family": "f00dcfd4-ba42-4e4e-bf4c-fad2d634226b",
    "mqtt-topic": "GW/voltsgy112",
    "gw-mac": "voltsgy112"
  }
  let series = [3316990, 6796846]
  let percent_series = []
  let kwh_series = []
  let total_power = 0.0
  let kwh_labels = ['Climatización', 'Termo ACS']
  let label_colors = ["primary", "default"]
  let check_in =  1682510400000
  let check_out= 1682637300000

  const checkinDate = new Date(check_in)
  let checkinFormat = checkinDate.toLocaleDateString();

  const checkoutDate = new Date(check_out)
  let checkoutFormat = checkoutDate.toLocaleDateString();


  series.map((serie) => {
    kwh_series.push(((Number(serie)/1000)/60).toFixed(2))
    total_power+= (Number(serie)/1000)/60
  })
  console.log(kwh_series, total_power)
  kwh_series.map((perc) =>{
    percent_series.push((Number(100/total_power ) * Number(perc)).toFixed(2))
  })

  return (
      <>
        <CardsHeaderSensor
            className="mb-3"
            unit={unit}
        />
        <Container className="mt--5" fluid>
          <Row>
            <Col xl="8">
        <Card>
          <CardHeader >
            <h6 className="surtitle">Apartamento Calle Goya 112</h6>
            <span>
            <h5 className="h3 mb-0">Resumen del Consumo Energético de la Reserva
            <Badge className="ml-2 badge-lg badge-alarm" color="alarm">
              <b>HC73409</b>
            </Badge>
              </h5>
              </span>
          </CardHeader >
          <CardBody >
            <Row >
              <Col >
                <VoltsMultiRadialCardBooking labels={kwh_labels} series={percent_series} measureu={"wats"}/>
              </Col>
              <Col className={"mt-7 align-items-center justify-content-center"}>
                <div className="checklist-item checklist-item-danger">
                  <div className="checklist-info">
                    <Badge className="badge-lg" color="info">
                      Energía
                    </Badge>
                    <h3 className="checklist-title mb-0">{total_power.toFixed(2)}{" kWh"}</h3>
                  </div>
                </div>
                { percent_series.map((serie,idx) => (
                  <div>
                    {/*<div className="progress-label default">
                      <span>Task completed</span>
                    </div>*/}
                    <div className={"checklist-item checklist-item-"+label_colors[idx]}>
                      <div className={"checklist-"+label_colors[idx]}>
                      <Badge className="badge-md badge-primary" color={"secondary"}>
                        {kwh_labels[idx]}
                      </Badge>
                    <h4 className="checklist-title mb-0"> {serie + "%"}</h4>
                    <small>{kwh_series[idx] + " kWh"}</small>
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
            <Row className="mb-1">
              <Col lg="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Fecha de Inicio
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{checkinFormat}</span>
                      </div>
                      {/*<Col className="col-auto">
                        <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                          <i className="ni ni-active-40" />
                        </div>
                      </Col>
                      */}
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                  <span className="text-danger mr-2">
                    <i className="fa fa-arrow-up" />
                    +13.08%
                  </span>
                      <span className="text-nowrap">Consumo Energético</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card className="card-stats">

                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Finalización
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{checkoutFormat}</span>
                      </div>
                      {/*}
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                          <i className="ni ni-chart-pie-35" />
                        </div>
                      </Col>
                      */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
            </Col>
            <Col xl="4">
              <BookingTimeline />
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default VoltsBookingDetails;
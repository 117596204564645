import React from "react";
import classnames from "classnames";
// reactstrap components
import {
    Card,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane, CardHeader
} from "reactstrap";
import ListCheckins from "./ListCheckins";
import CardsUnits from "./CardsUnits";
import {MapDefault} from "./volts-dash-map";
import VoltsUnitCard from "./volts-unit-card";
import TableCheckins from "./TableCheckins";
//import Mapview from "../../views/pages/maps/Mapview";

class VoltsDashPaneCheckins extends React.Component {
    state = {
        tabs: 1,
        checkins: this.props.checkins
    };
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };
    render() {
        console.log("dASH PANE", this.props.checkins)
        return (
            <>
                {/*<CardBody >*/}
                <div className="nav-wrapper">
                    <Nav
                        className="nav-fill flex-column flex-md-row"
                        id="tabs-icons-text"
                        pills
                        role="tablist"
                    >

                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 1}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 1
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 1)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-calendar-grid-58 mr-2" />
                                Cards
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 2}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 2
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 2)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-building mr-2" />
                                Table
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 3}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 3
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 3)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-map-big mr-2" />
                                Vista Mapa {this.props.hello}
                            </NavLink>
                        </NavItem>

                    </Nav>
                </div>
                {/*}</CardBody>*/}
                {/*<Card className="shadow">
                    <CardBody>*/}
                        <TabContent activeTab={"tabs" + this.state.tabs}>
                            <TabPane tabId="tabs1">
                                <ListCheckins checkins={this.state.checkins} />
                            </TabPane>
                            <TabPane tabId="tabs2">

                                {/* <Card className="bg-default shadow">
                                    <CardHeader className="bg-transparent border-0">*/}
                                        <TableCheckins checkins={this.state.checkins} />
                                {/*}   </CardHeader>
                                </Card>*/}
                            </TabPane>

                            <TabPane tabId="tabs3">

                                    <Card className="bg-default shadow">
                                        <CardHeader className="bg-transparent border-0">
                                            {/*<MapDefault unit_data={this.state.units} />*/}
                                        </CardHeader>
                                    </Card>

                            </TabPane>

                        </TabContent>
                {/*}</CardBody>
                </Card>*/}
            </>
        );
    }
}

export default VoltsDashPaneCheckins;
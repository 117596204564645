//import React from "react";
import React, { useState, useContext } from "react";

import { Account } from "../components/Accounts";
//import Signup from "../components/Signup";
import Login_ from "../components/Login_";
//import Status from "../components/Status";
//import Settings from "../components/Settings";


// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  //FormGroup,
  //Form,
  //Input,
  //InputGroupAddon,
  //InputGroupText,
  //InputGroup,
  Container,
  Row,
  Col, //NavbarBrand,
} from "reactstrap";
// core components

import AuthHeader from "components/Headers/AuthHeader.js";
//import {Link} from "react-router-dom";

function Login() {
  //const [focusedEmail, setfocusedEmail] = React.useState(false);
  //const [focusedPassword, setfocusedPassword] = React.useState(false);
  //const [email, setEmail] = useState("");
  //const [password, setPassword] = useState("");


  const onSubmit = event => {
    event.preventDefault();

  };
  return (
    <>

      <AuthHeader
        title="Bienvenido a volts!"
        lead="Accede a tu cuenta en volts Realtime Control."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pb-5">
                {/*}
                  <img
                      alt="..."
                      src={require("assets/img/brand/volts-logo.png").default}

                  />
*/}
                <div className="text-primary text-center mt-2 mb-3">
                  <big><b>Inicio de Sesión en volts</b></big>
                </div>
                {/*<div className="btn-wrapper text-center">*/}
                {/*}
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/github.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button>*/}
                  {/*}
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                  */}
                {/*}</div>*/}
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Identifícate con tu email y contraseña</small>
                </div>
              <Account>
                <Login_ />
              </Account>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="https://volts360.com/contacts"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>¿Olvidaste tu contraseña?</small>
                </a>
              </Col>

              <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="https://volts360.com/contacts"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Ayuda</small>
                </a>
              </Col>

            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;

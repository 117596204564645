import React from "react";
import { useLocation, Route, Switch, Redirect, useParams } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
//import Unit from "views/pages/volts/volts-unit.js";
//import PropTypes from "prop-types";
//import { Account } from '../views/pages/components/Accounts';
//import Status from "../views/pages/components/Status";
import Pool from "../UserPool";
import axios from "axios";
//import Cardsac from "../views/pages/components/cardsac";
//import VoltsDash from "../views/pages/dashboards/volts-dash";


let unit_items = [];

async function axios_units(params) {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/units",
      params)
  //console.log("AXIOS UNITS IN ADMIN", response)
  return response.data
}


function set_units(data) {
  unit_items = data
  //console.log("UNIT ITEMS : " , unit_items)
  {/*}
  unit_items['items'].map((unit, key) => {
    routes[0]['views'].push({
      path: "/unit/" + unit['gw-mac'],
      name: unit["friendlyname"],
      miniName: "D",
      component: "Unit",
      layout: "/admin",
    })

  })
  */}
  //console.log('ROUTES NEW', routes)
  //console.log("SET UNITS", unit_items)
  return unit_items
}


function Admin() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const [loggedOut, setLoggedOut] = React.useState(null);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const [currentUser, setcurrentUser] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const [myUnits, setmyUnits] = React.useState(null);
  const [unitsLoaded, setunitsLoaded] = React.useState(null);

  //const { params } = useParams()
  //const { unit_uuid } = useParams();
  //const { sensor_mac } = useParams();

  const { id } = useParams();
  React.useEffect(() => {

    const loadData = async (userName) => {
      const data = await axios_units({params:{userName: userName}});
      setmyUnits(data);
      set_units(data);
      //console.log("DATA", data)
      // check if response has units inside
      if (data['statusCode'] === 200){
        setLoaded(true);
      }
    };

    const user = Pool.getCurrentUser();
    setcurrentUser(user)
    //console.log("USER IN ADMIN LAYOUT ...:", user, units)

    setTimeout(() => {
        //console.log("USER IN USE EFFECT: ", user['username'])
        loadData(user['username']);
      }, 10);

    if (user === null) {
      setLoggedOut(true)
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);


  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        //console.log("PROP ", prop.path)
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };


  const getBrandText = (path) => {
    //console.log("ROUTE", routes)
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal


  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };


  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  //console.log("USE PaRAMS", params)
  //console.log("ID-ADMIN", id)
  let units = []
  return (
    <>
      { loaded ?
      <Sidebar
        //mUnits={myUnits}
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/admin",
          imgSrc: require("assets/img/brand/volts-th-green-min.png").default,
          imgAlt: "volts home automation for short-term and vacation rentals",
        }}
      />
          : <></>}
      <div className="main-content" ref={mainContentRef}>
        {loaded ? <AdminNavbar
            mUnits={myUnits}
            unitValue={id}
            theme={getNavbarTheme()}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
        />
            :
            <></>
        }
        {
          loaded ?
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
            :
              <></>
        }
        {loaded ?
        <AdminFooter />
            : <></>}
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default Admin;
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
//import "@fortawesome/fontawesome-free/css/all.min.css"
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
//import "@fortawesome/fontawesome-pro"
//import "react-fontawesome"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, light, thin, duotone, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


import AdminLayout from "layouts/Admin.js";
//import RTLLayout from "layouts/RTL.js";
import AuthLayout from "layouts/Auth.js";
//import IndexView from "views/Index.js";

ReactDOM.render(
    <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        {/*<Route path="/rtl" render={(props) => <RTLLayout {...props} />} />*/}
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        {/*<Route path="/" render={(props) => <IndexView {...props} />} />  */}
      <Route path="/" render={(props) => <AuthLayout {...props} />} />
      <Redirect from="*" to="/admin" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
import {
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    Table,
    Media,
    Badge,
    UncontrolledTooltip,
    Progress,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Pagination,
    PaginationItem,
    PaginationLink, CardFooter, Card, CardHeader, CardBody
} from "reactstrap";

//import { faBolt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {voltsIcons} from "../../variables/volts-icons";
import React from "react";
import VoltsCard from "./voltsCardAc";
import VoltsCardCheckin from "./voltsCardCheckin";
import CheckinModal from "./volts-checkin-modal";


function ListCheckins({checkins}) {
    const thirdListRef = React.useRef(null);
    return (
        <>
            {/*<Card>*/}
                {/*<Container>*/}
                {/*}
                <CardHeader>
                    <Row>

                        <Col>
                            <h5 className="h3 mb-0">Checkin Dashboard</h5>
                        </Col>

                    </Row>
                </CardHeader>
                */}
            {/*<CardBody>*/}

                        <Row className={"mt-1"}>
                            {checkins.map((checkin) => (
                                <div key={"k"+checkins.uuid} className="col-md-6">
                                    <Col className={"mt-0"}>
                                        <VoltsCardCheckin checkin={checkin} />
                                    </Col>
                                </div>
                            ))}
                        </Row>


            {/*}</CardBody>*/}
            {/*}</Card>*/}
            {/*</Container>*/}

            {/*</Container>*/}

        </>
    );
}

export default ListCheckins;
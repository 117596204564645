import React from "react";
import Button from "reactstrap"
export const MapDefault = ({unit_data}) => {
    //const [units, setUnits] = React.useState();
    const mapRef = React.useRef(null);
    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;
        let myLatlng = new google.maps.LatLng(unit_data[0].unit.latlng.lat, unit_data[0].unit.latlng.lng);

        const mapOptions = {
            zoom: 9,
            center: myLatlng,
            scrollwheel: false,
            zoomControl: true,
        };

        map = new google.maps.Map(map, mapOptions);

        {/*const contentString =
        '<div class="info-window-content"><h2>XX</h2>' +
        "<p>A premium Admin for Reactstrap, Bootstrap, React, and React Hooks.</p></div>";*/}
        {/*
    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });*/}

        {/*const infowindow = new google.maps.InfoWindow;*/}
        let marker , u, infowindow, contentString, content
        for (u = 0; u < unit_data.length; u++) {
            //LATLNG = marker.latlng
            myLatlng = new google.maps.LatLng(unit_data[u]['unit']['latlng']['lat'], unit_data[u]['unit']['latlng']['lng']);
            marker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                animation: google.maps.Animation.DROP,
                title: unit_data[u]['unit']['friendlyname'],
            });
            content = unit_data[u]['unit']['friendlyname']
            contentString = '<div class="info-window-content"><h2>' + unit_data[u]['unit']['friendlyname'] +'</h2>' +
                "<p>volts</p></div>";
            google.maps.event.addListener(marker, 'click', (function(marker, u) {
                return function() {
                    infowindow = new google.maps.InfoWindow({
                        content:
                            '<a href="/admin/unit/' + unit_data[u]['unit']['gw-mac'] + '" > ' +
                            '<div class="info-window-content"><h2>' + unit_data[u]['unit']['friendlyname'] +'</h2>' +
                            "<p>"+ unit_data[u]['unit'].address+ "</p></div>" +
                            '<Button color="secondary"' +
                            'type="button">' + "Acceder"+'</Button>' +
                            '</a>'
                    });
                    {/*infowindow.setContent({content: unit_data[u]['friendlyname']});*/}
                    infowindow.open(map, marker);
                }
            })(marker, u));
        }
    }, []);
    {/*console.log("MADPSXX______>", unit_data)*/}

    return (

        <div
            style={{ height: `450px` }}
            className="map-canvas"
            id="map-default"
            ref={mapRef}
        />

    );
};


import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";

import VoltsBaseCardJson from "./volts-base-card-json";
import {voltsIcons} from "../../../variables/volts-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ModalsJson extends React.Component {
    json_object = this.props.json_object;
    state = {
        exampleModal: false
    };

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    render() {
        return (
            <>

                {/*<Button
                    color="primary"
                    outline
                    size="sm"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal")}
                >*/}
                    <FontAwesomeIcon className={"text-grey"}
                                     onClick={() => this.toggleModal("exampleModal")}
                                     icon={voltsIcons["file"]} />
                {/*}</Button>*/}

                <Modal
                    className="modal-xl"
                    isOpen={this.state.exampleModal}
                    toggle={() => this.toggleModal("exampleModal")}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Contenido completo del Evento JSON
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("exampleModal")}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <VoltsBaseCardJson json_object={this.json_object}/>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("exampleModal")}
                        >
                            Cerrar
                        </Button>
                        <Button color="primary" type="button">
                            Guardar cambios
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ModalsJson;
import {
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip, Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    ListGroup,
    ListGroupItem, CardTitle
} from "reactstrap";
import axios from "axios"
import React, { useState, useEffect, ReactDOM } from "react";
import moment from "moment";
import List from "list.js";


let data_items = []


const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/events/states";


async function axios_data() {
    const response = await axios.get(API_URL, {
        params:{
            'gw-mac': "vvbnmdn-001",
            'sensor-mac': '0x00124b002342bf55',
            'timestamp-offset': (moment().unix() -3600 ) + ".0",
            'timestamp-end': (moment().unix() )+ ".0",
            'attributes': 'state,linkquality',
            //'format': 'line-graph',
            'items': 90,
            //'frequency': 600
        }
    })
    return response.data
}


function VoltsSensorLatestStateCard({ sensor, card, latest_state }) {
    const [data_items, setDataItems] = React.useState(null);
    const [loaded, setLoaded] = React.useState(null);
    const domContainer = document.querySelector('#app');


    useEffect(() => {

        const loadData = async () => {
            const data = await axios_data();
            setDataItems(data);
            //set_data_items(data);
            console.log("DATA", data)
            setLoaded(true);

        };
        setTimeout(() => {
            //------->>>>>>>>>>>>>>>>>>desactivada llamada loadData();
        }, 300);
    }, []);
    //console.log(data_items)

    return (
        <>
            <Col xl="14">
                <Card>
                    <div>
                        <CardHeader>
                            <h6 className="surtitle">{sensor['sensor-function']} in {sensor['sensor-location']} </h6>
                            <h5 className="h3 mb-0">{"Atributos "} {sensor['friendly-name']}</h5>
                        </CardHeader>

                        <CardBody >
                            { latest_state.attributes.map((attribute_row, idx) => (
                                <div>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                    {attribute_row['attribute']}:
                                    </CardTitle>

                                    <p className="mt-0 mb-2">
                                        {typeof(attribute_row['state']) !== "object" ?
                                        <Badge color="success" className="mr-2 badge-md text-md">{attribute_row['state']}{attribute_row['unit']}</Badge>
                                            : <></>}
                                        <span className="text-xs">{new Date( Number(attribute_row['timestamp']) * 1000).toLocaleString()}</span>
                                    </p>

                                </div>

                            ))}

                        </CardBody>
                    </div>
                </Card>
            </Col>
        </>
    );
}

export default VoltsSensorLatestStateCard;
import React, {useEffect} from "react";
import {Card, CardBody, CardTitle, Col, Row, UncontrolledTooltip} from "reactstrap";
import VoltsMultiRadialCard from "../volts-graph-cards/volts-multi-radial-graph";



function DashHeaderCardGraph({link, card, graph, unit}) {
    console.log("CARD: ", card)
    let percentage = card.percentage+"%"
    let percentageText = card.percentageText
    let percentageIcon = card.percentageIcon
    let icon = card.icon
    let attributeText = card.attributeText
    let attributeState = card.attributeState
    let attributeUnit = card.attributeUnit

    console.log("CAdR GRPH:", graph)
    let percent_series = []
    let total_power = Number(unit['unit_ops']['dash']['power']["total-power"])
    graph.map((perc) =>{
        percent_series.push((Number(100/total_power ) * Number(perc)).toFixed(2))
    })
    console.log("PERCENT SERIES:", percent_series)
    return (
        <>
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <div id={"pg" + unit['unit_ops']['dash']['unitInfo']['unit-uuid']} className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                <UncontrolledTooltip
                                    delay={0}
                                    innerClassName={"bg-neutral"}
                                    target={"pg" + unit['unit_ops']['dash']['unitInfo']['unit-uuid']}>
                                    <div className="align-items-start col-auto">
                                        <VoltsMultiRadialCard labels={unit['unit_ops']['dash']['power']['series-power']['labels']} series={percent_series} measureu={"wats"}/>
                                    </div>
                                </UncontrolledTooltip>
                                {attributeText}

                            </CardTitle>
                            <span className="h2 mb-0"><b>{attributeState}</b>{attributeUnit}</span>
                        </div>
                        {/*}
                        <Col className="col-auto">
                            <VoltsMultiRadialCard labels={unit['unit_ops']['dash']['power']['series-power']['labels']} series={percent_series} measureu={"wats"}/>
                        </Col>
                        */}
                    </Row>
                    <p className="mt-0 mb-0 text-sm">
                        {
                            unit['unit_ops']['dash']['power']["states"].map((powers) => (
                                <div className={"mb-0"}>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target={"ps" + powers["sensor-mac"]}>
                                        {powers.toolTip}
                                    </UncontrolledTooltip>
                                    <tr id={"ps" + powers["sensor-mac"]} className={"ml-0 badge badge-"+ powers['color']}>
                                        <span className={"mb-0"}><b>{powers.state}</b></span>
                                    </tr>
                                    <span className={"mb-0 text-sm ml-1"}>{powers['friendly-name']}</span>
                                </div>
                            ))
                        }
                        {/*}
                      <span className="text-success mr-2">
                        <i className={"fa " + percentageIcon} /> {percentage}
                      </span>{" "}
                        <span className="text-nowrap">{percentageText}</span>
                        */}
                    </p>
                </CardBody>
            </Card>
        </>
    );
}

export default DashHeaderCardGraph


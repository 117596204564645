import React from "react";
import { Routes, Router, Switch, Route, Link, useParams} from "react-router-dom";
import Order from "./Order"
import { useState, useEffect, useCallback, useRef } from "react";
import SocketPower from "./volts-socket-power-graph";
import VoltsBookingProgress from "../../../components/VoltsCards/Volts-Booking-Progress";
import ListUnits from "../../../components/VoltsCards/ListUnits";
import VoltsToDo from "../../../components/VoltsCards/volts-to-do";
import VoltsMainDoor from "../../../components/VoltsCards/volts-alerts"
import VoltsEvents from "../../../components/VoltsCards/volts-events";
import VoltsSensorCards from "../../../components/VoltsCards/volts-sensor-cards";
import VoltsNoiseGraph from "../../../components/VoltsCards/volts-noise-graph";
import VoltsMapCard from "../../../components/VoltsCards/volts-map-card";
import Socket from "./volts-socket-cards";
import VoltsBar from "../../../components/VoltsCards/volts-bar"
import Activities from "../../../components/VoltsCards/volts-activities";
import CardSimple from "../../../components/VoltsCards/CardSimple";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components

import VoltsBaseCard from "../../../components/VoltsCards/volts-base-card"
import VoltsGraphCard from "../../../components/VoltsCards/volts-graph-card"


import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";

// core components
import CardsHeaderUnit from "components/Headers/CardsHeaderUnit.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2, chartExample3,
} from "variables/charts.js";

import axios from "axios"
import moment from "moment";
import VoltsSortable from "../tables/voltsSortable";
import SimpleHeader from "../../../components/Headers/SimpleHeader";


let unit_item = {};


async function axios_unit(id) {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/unit/full", {params:{
        'gw-mac': id
  }})
  console.log("AXIOS UNIT",response)
  return response.data
}


function set_unit(data) {
  unit_item = data
  console.log("SET UNIT", unit_item)
  return unit_item
}

const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
  'Content-Type': 'application/json',
  'x-api-key': VOLTS_API_KEY
};

function Dashboard() {
  const [unit, setUnit] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const thirdListRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const { id } = useParams();
  const { dash } = useParams();

  {/*
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
*/}
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    const loadGuestData = async () => {
      let data = axios_unit(id);
      setUnit(data);
      set_unit(data);
      setLoaded(true);
    };
    setTimeout(() => {
      loadGuestData();
    }, 100);
  },[]);


  return (
      <>
        <SimpleHeader name="Reference Cards" parentName="Tables" />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="8">
              {/*<VoltsSortable/>*/}
              <VoltsGraphCard />

              {/*<VoltsNoiseGraph/>*/}
              <VoltsBaseCard/>

            </Col>
            <Col xl="4">
              <VoltsBaseCard/>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              { loaded ? unit.unit['friendlyname']: <> NOT LOADED </>}
            </Col>
            <Col xl="6">
              COL XL 6
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default Dashboard;

{/*
{loaded ? <VoltsBookingProgress unit={unit.unit}/> : <>Loading</>}
              { loaded ? <VoltsMapCard params={unit['map-params']}/> : <> {"Loading MAP"}</>}
              {loaded && maindoor !== null? <VoltsEvents params={
                {params:{
                  'gw-mac': id,
                  'sensor-mac': unit.sensors[maindoor]['sensor-mac'],
                    'timestamp-offset': (moment().unix() - 36000) + ".0",
                    'timestamp-end': (moment().unix())+ ".0",
                  'attributes': 'contact',
                  'items': 41,
                }}}/> : <>Loading</>}
*/}
{/*<CardsHeaderUnit name={loaded ?  unit.unit.friendlyname: id} parentName="units"/>*/}

{/*
var indexSensor = data['sensors'].findIndex(function (el) {
        return el['sensor-location'] === 'MAIN-DOOR';
      });

      if (indexSensor !== -1) {
        setMaindoor(indexSensor)
        console.log("MAIN DOOR IS: ", data['sensors'][indexSensor]['friendly-name'])
      }
*/}

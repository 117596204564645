import {
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import axios from "axios"
import React, { useState, useEffect, ReactDOM } from "react";
import moment from "moment";
import List from "list.js";
import VoltsCard from "./voltsCardAc";


function VoltsCardsCard({ volts_cards, device_type, mac_address, unit_uuid }) {
    const [data_items, setDataItems] = React.useState(null);

    return (
        <>
            <Col xl="14">
                <Card>
                    <div>
                        <CardHeader>
                            <h6 className="surtitle">Sensor</h6>
                            <h5 className="h3 mb-0">Último Estado Registrado</h5>
                        </CardHeader>
                        <CardBody>
                            <div >
                                {volts_cards.map((card) => (
                                    <div key={card['sensor-mac']}>
                                        <VoltsCard volts_sensor={card} device_type={device_type} mac_address={mac_address} unit_uuid={unit_uuid}/>
                                    </div>
                                ))}
                            </div>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        </>
    );
}

export default VoltsCardsCard;
import React from "react";
import List from "list.js";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  //DropdownMenu,
  //DropdownItem,
  //DropdownToggle,
  //UncontrolledDropdown,
  //Container,
  //Row,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  UncontrolledTooltip, CardBody,
} from "reactstrap";
//import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from "axios";
import {AttributeArray} from "../../../variables/attributeArray";
import ModalsJson from "../../../components/VoltsCards/volts-base-cards/volts-base-modal-json";
import {voltsIcons} from "../../../variables/volts-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {sensorIcons} from "../../../variables/volts-icons";
//import {attribute_array} from "../../volts-sensor-attributes";

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/switchs"


function SensorSortableContact({sensor, states}) {
  const firstListRef = React.useRef(null);
  //const secondListRef = React.useRef(null);
  //const thirdListRef = React.useRef(null);

  const getData = async (sensor_mac, command) => {
    const response = await axios.put(API_URL, null,{
      headers: {
        'Content-Type': 'application/json'
      },
      params:
          {
            "sensor-mac": sensor_mac,
            "gw-mac": "volts00000103",
            "command": command
          }
    })
    return response.data;
  }
  {/*}
  const handleData = async () => {
    const resp = await axios.get("api/city");
    const data = await resp.data.content;
    setData(data);
    setFilteredData(data);
    //setData(data);
  };
  */}

  React.useEffect(() => {
    new List(firstListRef.current, {
      valueNames: ["hora", "estado", "batería", "cobertura"],
      listClass: "list",
    });
  }, []);

  function StateCells() {
    let attribute_name = ''
    let attribute_state = ''
    let attribute_color = ''
    let attribute_label = ''
    let attribute_timestamp = ''
    let signal_state = ''
    let battery_state = null
    let attr_index = null
    let states_row = []
    let percentage_state = null
    //console.log("STATE CELL: ", sensor, state_attrs)

    states.map((states_attrs, state_index) => {

      AttributeArray.map((attr, idx) => {
        //console.log(attr.sensor_function, sensor['sensor-function'])
        if (attr.sensor_function == sensor["sensor-function"]) {
          //console.log("Attribute: ", idx, attr)
          attribute_name = attr.main_attribute
          attr_index = idx
        }
      })

      //console.log(states_attrs['attributes'] , typeof (states_attrs['attributes']))
      //let len_states = states_attrs['attributes'].length
      //console.log("LEN", len_states)
      if (typeof(states_attrs['attributes']) !== "undefined") {
        states_attrs['attributes'].map((state) => {
          if (state.attribute === AttributeArray[attr_index].main_attribute) {
            attribute_state = state.state
            attribute_timestamp = states_attrs.timestamp
            if (attribute_state === "True") {
              attribute_color = AttributeArray[attr_index].true_color
              attribute_label = AttributeArray[attr_index].true_label
            } else {
              if (attribute_state === "False") {
                attribute_color = AttributeArray[attr_index].false_color
                attribute_label = AttributeArray[attr_index].false_label
              } else {
                attribute_color = AttributeArray[attr_index].true_color
                attribute_label = attribute_state + AttributeArray[attr_index].unit
              }
            }
          }
          //console.log("ANTES DE %", state.attribute)
          if (state.attribute === AttributeArray[attr_index].percentage_attr) {

            percentage_state = state.state
            //console.log("percentage attr", percentage_state)
          }

          if (state.attribute === 'battery') {
              battery_state = state.state
            } else {
              if (state.attribute === 'rssi') {
                signal_state = state.state
              }
            }

        })

        states_row.push({
          attribute_state: attribute_state,
          attribute_label: attribute_label,
          attribute_color: attribute_color,
          attribute_unit: AttributeArray[attr_index].unit,
          attribute_timestamp: attribute_timestamp,
          attribute_battery_state: battery_state,
          attribute_signal_state: signal_state,
          attribute_percentage: percentage_state
        })
      }
    })


    //console.log(states_row)
    return states_row
    {/*}
    return (
        <div>
        <Button color={attribute_state === 'True' ? attribute_array[attr_index].true_color: attribute_array[attr_index].false_color} outline size="sm" type="button">
          {attribute_state === 'True' ? attribute_array[attr_index].true_label : attribute_array[attr_index].false_label}{attribute_array[attr_index].unit}
        </Button>
        <span>
          {battery_state}
        </span>
        <span>
          {signal_state}
        </span>
  </div>
    )
    */}
  }

  function OnOption(e, sensor_mac, sw_command)
  {
    getData(sensor_mac, sw_command)
    e.preventDefault()
    //console.log("E", e.button)
    //alert(sensor_mac +" " + sw_command);
  }
  //console.log("Sensor: ", sensor, "States: ", states)
  {/*
  COLUMNAS
  uuid
  time
  is:new
  users ->
  battery <Progress max="100" value="60" color="warning" />
  */}
  let att_index = 0
  let state_cells = StateCells()
  //console.log(state_cells)

  return (
    <>
      {/*<Container className="mt--6" fluid>
        <Row>
          <div className="col">*/}
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Cronología de eventos en sensor {sensor['friendly-name']}</h3>
              </CardHeader>
              <CardBody>
              <div className="table-responsive" ref={firstListRef}>
                <Table className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="hora" scope="col">
                        Hora
                      </th>
                      <th className="sort" data-sort="estado" scope="col">
                        Estado
                      </th>
                      <th className="sort" data-sort="bateria" scope="col">
                        Batería
                      </th>
                      <th scope="col">Estado</th>
                      <th className="sort" data-sort="cobertura" scope="col">
                        Cobertura
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                  { state_cells.map((states_row, idx) => (
                    <tr key={"child"+states_row['state-uuid']}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar bg-secondary rounded-circle mr-3"
                            href="#pablo"
                            id={"s" + idx.toString()}
                            onClick={(e) => e.preventDefault()}
                          >

                            <FontAwesomeIcon className={"text-success"}
                                             icon={voltsIcons[sensorIcons[sensor['sensor-function']]['icon']]} />


                            {/*<img
                              alt="..."
                              src={
                                require("assets/img/theme/bootstrap.jpg")
                                  .default
                              }
                            />*/}

                            <UncontrolledTooltip
                                delay={0}
                                target={"s"+idx.toString()}
                            >
                              {"tooltip"}
                            </UncontrolledTooltip>
                          </a>
                          <Media>
                            <span className="hora mb-0 text-sm">
                              {new Date( Number(states_row['attribute_timestamp']) * 1000).toLocaleString()}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>
                        <Button color={states_row.attribute_color} outline size="sm" type="button">
                          {states_row.attribute_label}{state_cells.unit}
                        </Button>
                      </td>
                      <td>


                        <div className="d-flex align-items-center">
                          <span className="bateria mr-2">{}</span>
                          {states_row['attribute_battery_state'] !== null
                              ?
                              <div>
                                {states_row['attribute_battery_state']+"%"}
                                <Progress max="100" value={Number(states_row['attribute_battery_state'])} color="warning"/>
                              </div>
                              :
                              <div>
                                {states_row['attribute_percentage']+"%"}
                                <Progress max="100" value={Number(states_row['attribute_percentage'])} color="warning"/>
                              </div>
                          }

                        </div>
                      </td>
                      {/*<td className="budget">{states_row['sensor-location']}</td>*/}
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="cobertura bg-warning" />
                          <span className="status">{states_row['attribute_signal_state']}</span>
                        </Badge>
                      </td>
                      <td className="align-items-center">
                        <ModalsJson json_object={states[idx]["attributes"]} />
                      </td>
                      <td className="text-right">

                      </td>
                    </tr>
                    ))}


                  </tbody>
                </Table>
              </div>
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
      {/*}
          </div>
        </Row>


        <Row>
          <div className="col">


          </div>
        </Row>
      </Container>
*/}
    </>
  );
}

export default SensorSortableContact;

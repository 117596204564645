import React from "react";
import {useParams} from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios"
import moment from "moment";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import { Chart } from "chart.js";
import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";


import CardsHeaderUnit from "components/Headers/CardsHeaderUnit.js";
import CardsHeaderDash from "../../../components/Headers/CardsHeaderDash";
import VoltsActionsSortable from "../tables/voltsActionsSortable";


let incident_data = {};


async function axios_sensor_actions(sensor_mac, gw_mac) {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/actions", {params:{
      'gw-mac': gw_mac,
      'sensor-mac': " "
    }})
  console.log("AXIOS RESPONSE ACTIONS ",response)
  return response.data
}


function set_action_data(datar) {
  let action_data = datar
  return action_data
}


const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
  'Content-Type': 'application/json',
  'x-api-key': VOLTS_API_KEY
};


function VoltsActions() {

  const [sensor, setSensor] = React.useState(null);
  const [actionData, setactionData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const [loadedActions, setloadedActions] = React.useState(null);
  const [timestampOffset, settimestampOffset] = React.useState((moment().unix() - 28800));
  const [timestampEnd, settimestampEnd] = React.useState((moment().unix()));
  const thirdListRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

  //PARAMETROS PASADOS EN URL

  const { sensor_mac } = useParams();
  const { gw_mac } = useParams(); // gw-mac
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {

    const loadSensorGraph = async () => {
      const datar = await axios_sensor_actions(
          sensor_mac,
          gw_mac,
          timestampOffset,
          timestampEnd
      );
      setactionData(datar)
      set_action_data(datar)
      setloadedActions(true)
    };

    setTimeout(() => {
      loadSensorGraph()
    }, 1);
  },[]);
  console.log("ACTION DATA: ", actionData)
  return (
      <>

        <CardsHeaderDash
            className="mb-3"
            unit={loadedActions ?  actionData.unit.friendlyname : gw_mac}
            address={loadedActions ?  actionData.unit.address : gw_mac}
            parentName={loadedActions ? actionData.unit.address: gw_mac}
        />

        <Container className="mt--5" fluid>
          <Row>
            <Col xl="12">
              { loadedActions ?
                  <VoltsActionsSortable
                      actions={actionData.actions}
                  />
                  :
                  <></>
              }

            </Col>
            {/*}
            <Col xl="4">

              {loaded ?
                  <VoltsCardsCard
                      volts_cards={[sensor.card]}
                      device_type={sensor.unit['device-type']}
                      mac_address={sensor.unit['mac-address']}
                      unit_uuid={sensor.unit['uuid']}
                  />
                  :
                  <></>
              }

              {loaded ?
                  <VoltsSensorLatestStateCard
                      sensor={sensor.sensor}
                      card={sensor.card}
                      latest_state={sensor['latest-state']}
                  />
                  :
                  <></>
              }

              {loaded ?
                  <VoltsSensorSpecCard
                      sensor={sensor.sensor}
                      card={sensor.card}/>
                  :
                  <></>
              }

            </Col>
            */}
          </Row>
        </Container>
      </>
  );
}

export default VoltsActions;

import React from "react";
import {useParams} from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
//import {func} from "prop-types";

import axios from "axios"
import moment from "moment";
import {
  //Button,
  //Card,
  //CardHeader,
  //CardBody,
  //Form,
  //Input,
  Container,
  Row,
  Col,
  //Modal,
} from "reactstrap";


import { Chart } from "chart.js";
import {
  chartOptions,
  parseOptions,
  //chartExample1,
  //chartExample2, chartExample3,
} from "variables/charts.js";


//import Progresses from "../../../components/VoltsCards/Volts-Booking-Progress";
//import ListUnits from "../../../components/VoltsCards/ListUnits";
//import VoltsToDo from "../../../components/VoltsCards/volts-to-do";
//import VoltsMainDoor from "../../../components/VoltsCards/volts-alerts"
//import VoltsEvents from "../../../components/VoltsCards/volts-events";
//import VoltsSensorCards from "../../../components/VoltsCards/volts-sensor-cards";
//import VoltsNoiseGraph from "../../../components/VoltsCards/volts-noise-graph";
//import VoltsMapCard from "../../../components/VoltsCards/volts-map-card";
//import Socket from "./volts-socket-cards";
//import VoltsBar from "../../../components/VoltsCards/volts-bar"
//import ActivitiesGyg from "../../../components/VoltsCards/volts-activities";
//import CardSimple from "../../../components/VoltsCards/CardSimple";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
//import { Line, Bar } from "react-chartjs-2";


import VoltsBaseCardJson from "../../../components/VoltsCards/volts-base-cards/volts-base-card-json";
import VoltsTemperatureGauge from "./volts-temperature-gauge";
import CardsHeaderSensor from "components/Headers/CardsHeaderSensor.js";
import VoltsSensorLatestStateCard from "../../../components/VoltsCards/volts-sensor-latest-state-card";

import VoltsSensorSortable from "../tables/voltsSensorSortableContact";
import VoltsCardsCard from "../../../components/VoltsCards/volts-voltscards-card";
import VoltsSensorSpecCard from "../../../components/VoltsCards/volts-sensor-specs-card";
//import VoltsSensorLatestStateCard from "../../../components/VoltsCards/volts-sensor-latest-state-card";
import VoltsSwitchboxCard from "../../../components/VoltsCards/volts-sensor-graph-card";
import VoltsSensorNumeralGraph from "../../../components/VoltsCards/volts-sensor-graph-card-numeral"
import GetMainAttr from "../../../variables/attributeArray"
import {GetAttrObject} from "../../../variables/attributeArray";
//import Datepicker from "../forms/voltsDatePicker";
import BodyCardSeriesGraph from "../../../components/VoltsCards/volts-base-cards/volts-body-card-series-graph";
import Progresses from "../../../components/VoltsCards/Volts-Booking-Progress";
import BookingSortable from "../tables/voltsBookingSortable";
import Modals from "../../../components/VoltsCards/volts-base-cards/volts-base-modal-json"

let booking_item = {};
let graph_data = {};

async function axios_booking(gw_mac) {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/bookings", {params:{
        'gw-mac': gw_mac,
  }})
  console.log(response.data)
  return response.data
}


function set_sensor(data) {
  booking_item = data
  console.log("SET BOOKING", booking_item)
  return booking_item
}


const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
  'Content-Type': 'application/json',
  'x-api-key': VOLTS_API_KEY
};


function VoltsBooking() {

  //let param = useParams();
  const [booking, setBooking] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const thirdListRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const { gw_mac } = useParams(); // gw-mac
  const { unit_uuid } = useParams();
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {

    const loadBookingData = async () => {

      const data = await axios_booking(gw_mac);
      setBooking(data);
      setLoaded(true);

    };
    setTimeout(() => {
      loadBookingData();
    }, 1);
  },[]);

  return (
      <>
        {
          loaded ?
            <>
            <CardsHeaderSensor
            className="mb-3"
            unit={booking['gw-mac']}
        />

            </>
            : <></>
        }
        <Container className="mt--4" fluid>
          <Row>
            <Col xl="8">
              { loaded ? <BookingSortable bookings={booking.bookings} /> : <></>}
              {/*<Datepicker />
              { loadedr ?
                  <VoltsSwitchboxCard
                      data_series={graphData}
                      functionality={sensorf.toUpperCase()}
                  />
                  :
                  <></>
              }
              */}
              {/*}

              { loadedr ?
                  <VoltsSensorNumeralGraph
                      data_series={graphData}
                      functionality={sensorf.toUpperCase()}
                  />
                  :
                  <></>
              }
*/}

              {/*loaded ? <ActivitiesGyg unit={sensor.unit}/>: <></>*/}
              {/*loaded ? <VoltsSensorCards unit={unit.unit['gw-mac']} /> : <></>*/}

            </Col>
            <Col xl="4">
              {loaded ?
                  <VoltsCardsCard
                      volts_cards={[booking['stay-card']]}
                      device_type={null}
                      mac_address={booking['stay-sensor']['sensor-mac']}
                      unit_uuid={booking['stay-sensor']['unit-uuid']}
                  />
                  :
                  <></>
              }
              { loaded ? <VoltsSensorLatestStateCard
                  sensor={booking['stay-sensor']}
                  card={booking['stay-card']}
                  latest_state={booking['stay-state']}
              /> : <></>}
              <Progresses unit={{
                "gw-mac": gw_mac,
                "uuid": unit_uuid
              }} />
            </Col>

          </Row>
          <Row>


              {/*}
              { loaded ?
                  <>{mainAttr}
                    <VoltsBaseCardJson json_object={sensor['latest-state']} />
                  </>
                  :
                  <></>
              }
*/}

            <Col xl="4">

            </Col>
            <Col xl="6">
              {/*<VoltsEvents />*/}
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default VoltsBooking;
import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Progress,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardFooter,
    CardTitle,
    Container,
    UncontrolledTooltip, ListGroup, ListGroupItem
} from "reactstrap";


import VoltsBaseCardJson from "../VoltsCards/volts-base-cards/volts-base-card-json";
import {voltsIcons} from "../../variables/volts-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {CopyToClipboard} from "react-copy-to-clipboard";
import VoltsNavs from "./volts-tab-base";

let API_URL = ""
let bookingProviders = {
    hostify: "https://app.hostify.com/reservations/view/",
    hostfully: "https://platform.hostfully.com/app/#/calendar"
}
let hostify_booking_url = "https://app.hostify.com/reservations/view/"
let hostfully_booking_url = "https://platform.hostfully.com/app/#/calendar"


function CheckinMessageList({checkin}) {
    if (!checkin["lock-messages"]) {
    return (<></>)
    }


    return (
        <Card>
            <CardHeader>
                <h5 className="h3 mb-0">Access Information</h5>
            </CardHeader>
            <CardBody className="p-0">
                <ListGroup flush>
                    { checkin["lock-messages"]["locks"].map((lock) =>


                    <>
                    <ListGroupItem
                        className="list-group-item-action flex-column align-items-start py-4 px-4"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tag="a"
                    >
                        <div className="d-flex w-100 justify-content-between">
                            <div>
                                <div className="d-flex w-100 align-items-center">
                                    <img
                                        alt="..."
                                        className="avatar avatar-xs mr-2"
                                        src={require("assets/img/theme/team-1.jpg").default}
                                    />
                                    <h5 className="mb-1">Tim</h5>
                                </div>
                            </div>
                            <small>2 hrs ago</small>
                        </div>
                        <h4 className="mt-3 mb-1">{lock["door"]}{"-"}{"DOOR"}</h4>
                        <p className="text-sm mb-0">
                            {lock.message}
                        </p>
                    </ListGroupItem>
                    </>
                    )}
                    {checkin["lock-messages"]["wifi"] ?
                        <>
                        <ListGroupItem
                            className="list-group-item-action flex-column align-items-start py-4 px-4"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tag="a"
                        >
                            <div className="d-flex w-100 justify-content-between">
                                <div>
                                    <div className="d-flex w-100 align-items-center">
                                        <img
                                            alt="..."
                                            className="avatar avatar-xs mr-2"
                                            src={require("assets/img/theme/team-1.jpg").default}
                                        />
                                        <h5 className="mb-1">Tim</h5>
                                    </div>
                                </div>
                                <small>2 hrs ago</small>
                            </div>
                            <h4 className="mt-3 mb-1">{"WI-FI Details"}</h4>
                            <p className="text-sm mb-0">
                                {checkin["lock-messages"]["wifi"]["message"]}
                            </p>
                        </ListGroupItem>
                        </> : <></>
                    }


                </ListGroup>
            </CardBody>
        </Card>

    )

}

function CheckinTimeline({checkin}) {
    return (
        <Card>
            <CardHeader>
                <h5 className="h3 mb-0">Latest notifications</h5>
            </CardHeader>
            <CardBody>
                <div
                    className="timeline timeline-one-side"
                    data-timeline-axis-style="dashed"
                    data-timeline-content="axis"
                >
                    <div className="timeline-block">
                    <span className="timeline-step badge-success">
                      <i className="ni ni-bell-55" />
                    </span>
                        <div className="timeline-content">
                            <div className="d-flex justify-content-between pt-1">
                                <div>
                          <span className="text-muted text-sm font-weight-bold">
                            New message
                          </span>
                                </div>
                                <div className="text-right">
                                    <small className="text-muted">
                                        <i className="fas fa-clock mr-1" />2 hrs ago
                                    </small>
                                </div>
                            </div>
                            <h6 className="text-sm mt-1 mb-0">
                                Let's meet at Starbucks at 11:30. Wdyt?
                            </h6>
                        </div>
                    </div>
                    <div className="timeline-block">
                    <span className="timeline-step badge-danger">
                      <i className="ni ni-html5" />
                    </span>
                        <div className="timeline-content">
                            <div className="d-flex justify-content-between pt-1">
                                <div>
                          <span className="text-muted text-sm font-weight-bold">
                            Product issue
                          </span>
                                </div>
                                <div className="text-right">
                                    <small className="text-muted">
                                        <i className="fas fa-clock mr-1" />3 hrs ago
                                    </small>
                                </div>
                            </div>
                            <h6 className="text-sm mt-1 mb-0">
                                A new issue has been reported for Argon.
                            </h6>
                        </div>
                    </div>
                    <div className="timeline-block">
                    <span className="timeline-step badge-info">
                      <i className="ni ni-like-2" />
                    </span>
                        <div className="timeline-content">
                            <div className="d-flex justify-content-between pt-1">
                                <div>
                          <span className="text-muted text-sm font-weight-bold">
                            New likes
                          </span>
                                </div>
                                <div className="text-right">
                                    <small className="text-muted">
                                        <i className="fas fa-clock mr-1" />5 hrs ago
                                    </small>
                                </div>
                            </div>
                            <h6 className="text-sm mt-1 mb-0">
                                Your posts have been liked a lot.
                            </h6>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>

    )

}

function CheckinCopy({checkin}) {
    const [copiedText, setCopiedText] = React.useState(null);

    if (checkin["checkin-links"].length === 0){
        return(<>{"No links"}</>)
    }

    return (
        <Row className={"ml-2 mr-2"} >

        {checkin["checkin-links"].map((checkin_link) =>
<>
            <Col lg="3" md="4">

            <CopyToClipboard
                text={checkin_link.endpoint}
                onCopy={() => setCopiedText(checkin_link["endpoint"])}
            >

                <button
                    className="btn-icon-clipboard"
                    data-clipboard-text="album-2"
                    id={"tooltip" + checkin_link.uuid}
                    type="button"
                >
                    <div>
                        <span><b>{checkin_link.text}</b></span>
                        <a href={checkin_link["endpoint"]} target="_blank">
                            <i className="ml-2 ni ni-curved-next" />
                        </a>

                    </div>
                </button>
            </CopyToClipboard>
            <UncontrolledTooltip
                delay={0}
                trigger="hover focus"
                target={"tooltip" + checkin_link.uuid}
            >
                {copiedText === checkin_link["endpoint"]
                    ? "Link Copied!"
                    : "Copy Link"}
            </UncontrolledTooltip>
            </Col>

            </>
            )}
        </Row>
    )

}

function GuestList({checkin}) {
    let guest_list = checkin["checkin-guest-list"]
    console.log("Guests: ", guest_list.length)
    return (
        <>
        { checkin["checkin-guest-list"] ?
                <Card>
                    <CardHeader>
                        <b>{"Registered Guests"}</b>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <ul>
                                {guest_list.map((guest) =>
                                    <li><b>{guest["guestName"]}{" "}{guest["guestSurname"]}</b>{" "}{guest["phone"]}{" "}{guest["email"]}</li>
                                )}
                            </ul>
                        </div>
                    </CardBody>
                </Card> : <></>
        }
        </>
    )
}

function CheckinLinks({checkin}) {
    if (checkin["checkin-links"].length === 0){
        return(<>{"No links"}</>)
    }

    return (
        <ul>
            {checkin["checkin-links"].map((checkin_link) =>
                    <>
                    <Col md="6" xl="6">
                        <li><a href={checkin_link["endpoint"]} target="_blank"><b>{checkin_link.text}</b></a></li>
                    </Col>
                    </>
            )}
            {/*}
            {
                checkin["checkin-guest-data-link"] !== "" ?
                    <li><a href={checkin["checkin-guest-data-link"]} target="_blank">Partee Link</a></li> :
                    <></>
            }

            <li><a href={checkin["checkin-guest-link"]} target="_blank">volts Guest Link</a></li>
            <li><a href={hostify_booking_url + checkin["uuid"].replace("-hostify", "")} target="_blank">PMS link</a></li>
            <li><a href={hostify_booking_url + checkin["uuid"].replace("-hostify", "")} target="_blank">SmartLock Links</a></li>
            <li><a href={hostify_booking_url + checkin["uuid"].replace("-hostify", "")} target="_blank">StoryBlok link</a></li>
            <li><a href={hostify_booking_url + checkin["uuid"].replace("-hostify", "")} target="_blank">Google Maps link</a></li>
            <li><a href={hostify_booking_url + checkin["uuid"].replace("-hostify", "")} target="_blank">Route link</a></li>
*/}
        </ul>

    )

}


function VoltsSentimentCard({})
{

    function OnOption(e, sensor_mac, sw_command)
    {
        //getData(sensor_mac, sw_command)
        e.preventDefault()
        //console.log("E", e.button)
        //toggleModal("exampleModal")
        alert("Hi" + e.button + sw_command + " " + "Bye");

    }
    const getData = async (sensor_mac, command) => {
        API_URL = ""
        const response = await axios.get(API_URL, null,{
            headers: {
                'Content-Type': 'application/json'
            },
            params:
                {
                    //"sensor-mac": sensor_mac,
                    //"gw-mac": "volts00000103",
                    //"command": command
                }
        })
        return response.data;
    }

    return (

            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                {"Access Keys"}
                            </CardTitle>
                            <span className="h2 mb-0"><b>{"No  Seintement"}</b> {" Card empty"}</span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-neutral text-white rounded-circle shadow">
                                <a href={"link"} >
                                    <i className={"ni ni-building"} />
                                </a>
                            </div>
                        </Col>
                    </Row>

                    <p className="mt-3 mb-0 text-sm">
                                                <span className="text-success mr-2">
                                                    <i className={"fa " + "percentageIcon"} /> {"percentage"}
                                                </span>{" "}
                        <span className="text-nowrap">{"percentageText"}</span>
                    </p>

                </CardBody>
            </Card>
    )
}

function VoltsCheckinDropdown(){
    const switch_row = [{}]
    function OnOption(e, sensor_mac, sw_command)
    {
        //getData(sensor_mac, sw_command)
        e.preventDefault()
        //console.log("E", e.button)
        //toggleModal("exampleModal")
        alert("Hi" + e.button + sw_command + " " + "Bye");

    }
    const getData = async (sensor_mac, command) => {
        const response = await axios.put(API_URL, null,{
            headers: {
                'Content-Type': 'application/json'
            },
            params:
                {
                    //"sensor-mac": sensor_mac,
                    //"gw-mac": "volts00000103",
                    //"command": command
                }
        })
        return response.data;
    }
    return (
        <>
            <UncontrolledDropdown>
                <DropdownToggle
                    className="btn-icon-only text-light"
                    color=""
                    role="button"
                    size="sm"
                >
                    <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" left>
                    <DropdownItem
                        href="#pablo"
                        onClick={(e) => OnOption(e, switch_row['sensor-mac'], 0)}
                    >
                        View ROI Actions
                    </DropdownItem>
                    <DropdownItem
                        href="#pablo"
                        onClick={(e) => OnOption(e,switch_row['sensor-mac'],1)}
                    >
                        View Incidents
                    </DropdownItem>
                    <DropdownItem
                        href="#pablo"
                        onClick={(e) => OnOption(e,switch_row['sensor-mac'],2)}
                    >
                        View Sycn Bookings
                    </DropdownItem>
                    <DropdownItem
                        href="#pablo"
                        onClick={(e) => OnOption(e,switch_row['sensor-mac'],3)}
                    >
                        unit / booking Realtime
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}


class CheckinModal extends React.Component {

    checkin = this.props.json_object;
    checkin_progress = (100/ this.checkin.adults) * this.checkin["checkin-guests-checked"]
    state = {
        exampleModal: false
    };

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };


    render() {
        return (
            <>
                {/*<Button
                    color="primary"
                    outline
                    size="sm"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal")}
                />
                */}

                    <FontAwesomeIcon className={"text-grey"}
                                     onClick={() => this.toggleModal("exampleModal")}
                                     icon={voltsIcons["file"]} />

                {/*}</Button>*/}

                {/*<VoltsCheckinDropdown />*/}

                <Modal
                    className="modal-xl"
                    isOpen={this.state.exampleModal}
                    toggle={() => this.toggleModal("exampleModal")}
                >
                    <div className="bg-secondary modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            <b>{this.checkin["unit-friendlyname"]}</b>
                        </h5>

                        {/*}</Button>*/}
                        <button
                            aria-label="Close"
                            className="close text-white"
                            data-dismiss="modal"
                            type="button"

                            onClick={() => this.toggleModal("exampleModal")}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body bg-secondary modal-fluid" >
                        <Row>
                        <Col md="6" xl="4">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle
                                                tag="h5"
                                                className="text-uppercase text-muted mb-0"
                                            >
                                                {"Main Guest"}
                                            </CardTitle>
                                            <span className="h2 mb-0"><b>{this.checkin["invoice-name"]}</b></span>

                                            <p>
                                                <a href={"tel:"+this.checkin["phone-number"].replaceAll(" ","")}>
                                                    <i className={"ni ni-mobile-button"} />{" "}
                                                </a>
                                                {this.checkin["phone-number"].replaceAll(" ","")}
                                            </p>

                                        </div>
                                        <Col className="col-auto">
                                            <div className="icon icon-shape bg-neutral text-white rounded-circle shadow">
                                                <a href={"link"} >
                                                    <i className={"ni ni-single-02"} />
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <p className="mt-3 mb-0 text-sm">
                                        <span className="text-success mr-2">
                                            <i className={voltsIcons["file"]} />
                                            <b>
                                                <a href={bookingProviders[this.checkin["booking-provider"]] + this.checkin["uuid"].replace("-hostify","")} target="_blank">
                                                    {this.checkin["booking-provider"]}
                                                </a>
                                            </b>
                                        </span>{" "}
                                        <span className="text-nowrap"><b>{this.checkin["booking-reference"]}</b>{" "}{this.checkin["channel"]}</span>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xl="4">
                            <Card className="card-stats">
                                <CardBody>

                                    <Row>
                                        <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                        >
                                                            {"Check-In Data"}
                                                        </CardTitle>
                                            {/*<span className="h2 mb-0"><b>{"attributeState"}</b> {"attributeUnit"}</span>*/}
                                        </div>
                                                    <Col className="col-auto">
                                                        <div className="icon icon-shape bg-neutral text-white rounded-circle shadow">
                                                            <a href={this.checkin["checkin-guest-link"]} target="_blank">
                                                                <i className={"ni ni-badge"} />
                                                            </a>
                                                        </div>
                                                    </Col>
                                    </Row>
                                    <p className="mt-0 mb-0 text-sm">
                                        {/*}
                                        <span className="text-success mr-2">
                                            <i className={"fa " + "percentageIcon"} />
                                        </span>
                                        */}
                                        {/*<span className="text-nowrap">{"percentageText"}</span>*/}
                                        <div className="progress-wrapper">
                                            <div className="progress-info">
                                                <div className="progress-label">
                                                    <span>{this.checkin["checkin-guests-checked"]}{" of "}{this.checkin["adults"]}{" Adults completed"}</span>
                                                </div>
                                                <div className="progress-percentage">
                                                    <span>{this.checkin_progress + "%"}</span>
                                                </div>
                                            </div>
                                            <Progress max="100" value={this.checkin_progress} color="default" />
                                        </div>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                            <Col md="6" xl="4">
                                <VoltsSentimentCard />
                                {/*}
                                <Card className="card-stats">
                                    <CardBody>

                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    {"Access Keys"}
                                                </CardTitle>
                                                <span className="h2 mb-0"><b>{"No  Data to Show"}</b> {"empty"}</span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-neutral text-white rounded-circle shadow">
                                                    <a href={"link"} >
                                                        <i className={"ni ni-building"} />
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>

                                        <p className="mt-3 mb-0 text-sm">
                                        <span className="text-success mr-2">
                                            <i className={"fa " + "percentageIcon"} /> {"percentage"}
                                        </span>{" "}
                                            <span className="text-nowrap">{"percentageText"}</span>
                                        </p>

                                    </CardBody>
                                </Card>
                                */}
                            </Col>

                        </Row>
                        <Card>
                            <CardHeader>
                                <CardTitle>
                                    <b>{"Management Links - "}</b>{this.checkin["name"] + " " + this.checkin["unit-friendlyname"]}
                                </CardTitle>
                            </CardHeader>
                            <Row >
                                <CheckinCopy checkin={this.checkin} />
                            </Row>

                        </Card>
                        {/*<CheckinTimeline checkin={this.checkin} />*/}
                            <VoltsNavs checkin={this.checkin}/>
                        {/*}
                        <Card>
                            <Col md="6" xl="8">
                                <Card>
                                    <CardHeader>
                                        <b>{"Links"}</b>
                                    </CardHeader>
                                    <CheckinLinks checkin={this.checkin}/>
                                </Card>
                            </Col>
                            <Col md="6" xl="8">
                                <Card>
                                    <CardHeader>
                                        {"DDD"}
                                    </CardHeader>
                                    <CheckinCopy checkin={this.checkin} />
                                </Card>
                            </Col>
                        </Card>
                        */}



                        <VoltsBaseCardJson json_object={this.checkin}/>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("exampleModal")}
                        >
                            Cerrar
                        </Button>
                        <Button color="primary" type="button">
                            Guardar cambios
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default CheckinModal;
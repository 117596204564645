import React from "react";
import { Card, Container, Row } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from "axios";
import { useState, useEffect, useCallback, useRef } from "react";

let unit_items = [];

async function axios_units() {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/units")
  console.log("AXIOS UNITS",response)
  return response.data
}


function set_units(data) {
  unit_items = data
  //console.log("SET UNITS", unit_items)
  return unit_items
}


const MapDefault = ({unit_data}) => {
  const [units, setUnits] = React.useState();
  const mapRef = React.useRef(null);
  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    let myLatlng = new google.maps.LatLng(unit_data[0].latlng.lat, unit_data[0].latlng.lng);

    const mapOptions = {
      zoom: 10,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
    };

    map = new google.maps.Map(map, mapOptions);

    {/*const contentString =
        '<div class="info-window-content"><h2>XX</h2>' +
        "<p>A premium Admin for Reactstrap, Bootstrap, React, and React Hooks.</p></div>";*/}
    {/*
    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });*/}

    {/*const infowindow = new google.maps.InfoWindow;*/}
    let marker , u, infowindow, contentString, content
    for (u = 0; u < unit_data.length; u++) {
      //LATLNG = marker.latlng
      myLatlng = new google.maps.LatLng(unit_data[u]['latlng']['lat'], unit_data[u]['latlng']['lng']);
      marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        animation: google.maps.Animation.DROP,
        title: unit_data[u]['friendlyname'],
      });
      content = unit_data[u]['friendlyname']
      contentString = '<div class="info-window-content"><h2>$content</h2>' +
          "<p>volts</p></div>";
      google.maps.event.addListener(marker, 'click', (function(marker, u) {
        return function() {
          infowindow = new google.maps.InfoWindow({
            content: contentString,
          });
          infowindow.setContent({content: unit_data[u]['friendlyname']});
          infowindow.open(map, marker);
        }
      })(marker, u));

    }

  }, []);
  {/*console.log("MADPSXX______>", unit_data)*/}

  return (
    <div
      style={{ height: `600px` }}
      className="map-canvas"
      id="map-default"
      ref={mapRef}
    />
  );
};

function Google() {
  const [units, setUnits] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);

  useEffect(() => {
    const loadData = async () => {
      const data = await axios_units();
      setUnits(data);
      set_units(data);
      //console.log("DATAMAPS", data)
      setLoaded(true);
    };

    setTimeout(() => {
      loadData();
    }, 10);
  },[]);

  return (
    <>
      <SimpleHeader name="Map View" parentName="volts" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="border-0">
              {loaded ? <MapDefault unit_data={units.items} />: <h2>loading maps</h2>}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Google;

import Chart from "react-apexcharts";



const slugify = str =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');


const graphType = 'radialBar'


var colors = {
    gray: {
        100: "#f6f9fc",
        200: "#e9ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#8898aa",
        700: "#525f7f",
        800: "#32325d",
        900: "#212529",
    },
    theme: {
        default: "#002842",
        primary: "#00e7bd",
        secondary: "#f4f5f7",
        info: "#11cdef",
        success: "#2dce89",
        danger: "#ff5f69",
        warning: "#ffbcbb",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
};


const temperature = []


function VoltsMultiRadialCardBooking({labels, series, measureu}) {
    {/*
    series, measure y labels debeben llegar para componer aquí


    let series_graph = {
            name: seriesParams.title,
            //data: data.data.graph
            data: data['data']['series-total']['graph-totals']
    }
    series_graph = series
    */}


    var cp = {
        //series: [44, 55, 67, 83],
        chart: {
            height: 350,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '22px',
                    },
                    value: {
                        fontSize: '16px',
                    },
                    total: {
                        show: true,
                        label: 'Total',
                        formatter: function (w) {
                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                            return 249
                        }
                    }
                }
            }
        },
        //labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
    };

    var chartPower = {chart:
            {
                height: 300,
                type: chartPower,
            },
        series: series.data,
        colors: [colors.theme.info],
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#293450"
                },
                track: {
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        blur: 4,
                        opacity: 0.15
                    }
                },
                dataLabels: {
                    name: {
                        offsetY: -20,
                        color: "#fff",
                        fontSize: "20px"
                    },
                    value: {
                        color: "#fff",
                        fontSize: "40px",
                        show: true,
                        formatter: function ( val ) { return val * (50/100) + temperature[temperatureIndex].unit}
                    }
                }
            }
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "vertical",
                gradientToColors: [colors.theme.danger],
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: "round"
        },
        labels: ["Temperatura"]
    }


    const options = { //data on the x-axis
        colors:['#00e7bd'],
        chart: {
            //id: slugify(series[0].name),
            id: "g2911",
            type: graphType,
            //height: 350,
            zoom: {
                autoScaleYaxis: true
            }},
        plotOptions: {
            bar: {
                borderRadius: 1,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return (Math.round(val * 100) / 100 ) + measureu;
            },
            offsetY: -2,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },

        yaxis: {
            //min: 20,
            //max: 90,
            labels: {
                show: false,
                formatter: function(val) {return (Math.round(val * 100) / 100 ) +  measureu}
            },
            //title: {
            //    text: title
            //},
        },
        stroke: {curve: 'smooth'},

        xaxis: {
            //categories: date,
            type: 'datetime',
            //min: new Date('1 JAN 2022').getTime(),
            tickAmount: 6,
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0.2,
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 500, 1000]
                },
            },
        },
        tooltip: {
            x: {
                format: 'dd MMM HH:MM:ss'
            }
        },
    }


    let options_multi = {

        //series: [76, 67, 61, 90],
        options: {

        plotOptions: {
            radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 340,
                    hollow: {
                        margin: 5,
                        size: '20%',
                        background: 'transparent',
                        image: undefined,
                    },
                    dataLabels: {

                        enabled: false,
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        }
                    }
            }
        },
            colors: [colors.theme.primary, colors.theme.default],
            labels: labels,
            legend: {
            show: false,
                floating: true,
                fontSize: '12px',
                position: 'left',
                offsetX: 5,
                offsetY: 5,
                labels: {
                useSeriesColors: true,
            },
            markers: {
                size: 0
            },

            itemMargin: {
                vertical: 0
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: true
                }
            }
        }]
    },
    };


    //console.log(series)
    const temperatureIndex = 0
    temperature.push({"unit": 10.4})
    console.log("SERIES", series[0])

    return(

    <Chart
        options={options_multi['options']}
        //series={[44, 55, 67, 83]}
        //series={series[0].data}
        series={series}
        type={graphType}
        measureu={measureu}
        labels={["A","B"]}
        //width="300"
        height="450"
    />

)}


export default VoltsMultiRadialCardBooking
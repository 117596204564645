import React from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    FormGroup,
    UncontrolledTooltip,
    CardHeader,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
//import {useState} from 'react';
import axios from "axios";
import VoltsBaseCardJson from "./volts-base-cards/volts-base-modal-json";
import VoltsMultiRadialCard from "./volts-graph-cards/volts-multi-radial-graph";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment'

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/switchs"

function VoltsUnitCard({ unit }) {


    function VoltsUnitDropdown(){
        const switch_row = [{}]
        function OnOption(e, sensor_mac, sw_command)
        {
            //getData(sensor_mac, sw_command)
            e.preventDefault()
            console.log("E", e.button)
            //alert(sensor_mac +" " + sw_command);
        }
        const getData = async (sensor_mac, command) => {
            const response = await axios.put(API_URL, null,{
                headers: {
                    'Content-Type': 'application/json'
                },
                params:
                    {
                        //"sensor-mac": sensor_mac,
                        //"gw-mac": "volts00000103",
                        //"command": command
                    }
            })
            return response.data;
        }
        return (
            <>
                <UncontrolledDropdown>
                    <DropdownToggle
                        className="btn-icon-only text-light"
                        color=""
                        role="button"
                        size="sm"
                    >
                        <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" left>
                        <DropdownItem
                            href="#pablo"
                            onClick={(e) => OnOption(e, switch_row['sensor-mac'], 1)}
                        >
                            View ROI Actions
                        </DropdownItem>
                        <DropdownItem
                            href="#pablo"
                            onClick={(e) => OnOption(e,switch_row['sensor-mac'],0)}
                        >
                            View Incidents
                        </DropdownItem>
                        <DropdownItem
                            href="#pablo"
                            onClick={(e) => OnOption(e,switch_row['sensor-mac'],0)}
                        >
                            View Sycn Bookings
                        </DropdownItem>
                        <DropdownItem
                            href="#pablo"
                            onClick={(e) => OnOption(e,switch_row['sensor-mac'],0)}
                        >
                            unit / booking Realtime
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </>
        )
    }


    {/*
    var statusswtich = "",toggleSlidermode = "";
    const statusbatery = volts_sensor['battery-status'];
    const statusbadgestyle  = volts_sensor['attribute']['badge-style'];
    const cardbgstatus = 'bg-status-'+volts_sensor['bg-status'];
    statusswtich = volts_sensor['switch']['switch-status'];
    {statusswtich == 'ON' ? toggleSlidermode = 'toggle-slider-on' : toggleSlidermode = 'toggle-slider-off' }
    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    const putSwitch = async (sensor_mac, command) => {
        const response = await axios.put(API_URL, null,{
            headers: {
                'Content-Type': 'application/json'
            },
            params:
                {
                    "sensor-mac": volts_sensor['sensor-mac'],
                    "gw-mac": "volts00000103",
                    "command": command,
                    "device-type": device_type,
                    "mac-address": mac_address,
                    "sensor-type": volts_sensor['sensor-type']
                }
        })
        return response.data;
    }

    const handleChange = (event) => { 
        if(event.target.checked){
            console.log("CHECKED")
            putSwitch(volts_sensor['sensor-mac'], 1)
            event.target.nextSibling.classList.remove(
                'toggle-slider-on',
                'toggle-slider-off'
              );
              event.target.nextSibling.classList.add( 'toggle-slider-on' );
        }else{
            console.log("UNCHECKED")
            putSwitch(volts_sensor['sensor-mac'], 0)
            event.target.nextSibling.classList.remove(
                'toggle-slider-on',
                'toggle-slider-off'
              );
              event.target.nextSibling.classList.add( 'toggle-slider-off' );
        }
    };
    let sensor_id = "s" + replaceAll(volts_sensor['sensor-mac'],":", "")

    //console.log(sensor_id, volts_sensor['attribute']['friendly-name'],volts_sensor['bg-status'])
    let link_to_sensor = "/admin/sensor/" + volts_sensor['gw-mac']+"/" + unit_uuid + "/" + volts_sensor['sensor-mac'] + "/" + volts_sensor['sensor-function'].toLowerCase()

    return (
        <> 

            <Card className={`card-stats card-option ${'bg-status-'+volts_sensor['bg-status'].toLowerCase()} ${statusbadgestyle=='DISABLED' ? "card-opacity" : ""}`}>
                <a href={link_to_sensor}>


                <CardBody>
                    <Row>

                        <div className="col">
                            <div className={`card-rectangule ${'card-status-battery-'+volts_sensor['attribute']['badge-style']} ${cardbgstatus != 'bg-status-standard'?'card-status-alert-special':''}`}>
                            <CardTitle tag="h5" className={`text-uppercase mb-0 font-weight-bold text-card-status ${'text-card-status-'+volts_sensor['attribute']['badge-style']} ${cardbgstatus != 'bg-status-standard'?'text-white':''}`}>
                            {volts_sensor['attribute']['badge-text']}
                            </CardTitle>
                            </div>
                        </div>
                        <Col className="col-auto col">
                            <div className="icon icon-card">
                            {statusbatery!= 'OK'
                                ?  <span className="batery-status"></span>
                                : ""
                            }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <div className="col">
                        <span id={sensor_id} className="card-font-montserrat h2  mb-0 text-white ">{volts_sensor['attribute']['friendly-name'] + " "}</span>
                        <UncontrolledTooltip
                            delay={0}
                            target={sensor_id}
                        >
                            {volts_sensor['sensor-mac']}

                        </UncontrolledTooltip>
                    </div>
                    <Col className="col-auto custom-toggle-flex">
                        {statusswtich!='DISABLED'
                            ?   <label className='custom-toggle'>
                                    <input onChange={handleChange} defaultChecked = { statusswtich === 'ON' ? 'defaultChecked' : '' } type="checkbox" />
                                    <span className={`${statusswtich === 'ON' ? toggleSlidermode : toggleSlidermode} custom-toggle-slider rounded-circle` } />
                                </label>
                        :""}
                    </Col>
                    </Row>
                    <Row>
                        <div className="col">
                        <p className="mb-0 text-sm">
                                <span className={`mr-2 font-weight-bold card-font-watios ${cardbgstatus=='bg-status-standard' ? "" : "text-white"}`}>
                                    {volts_sensor['attribute']['status-text']}
                                </span>{" "}
                        </p>
                        </div>
                        <Col className="col-auto">
                        <p className={`mb-0 text-sm card-font-montserrat ${cardbgstatus=='bg-status-standard' ? "" : "text-white"}`}>
                        <span className="text-nowrap size-card-montserrat text-white" >{" " + volts_sensor['attribute']['time-text']}</span>
                        </p>
                        </Col>
                    </Row>
                </CardBody>
                </a>
            </Card>
        </>
    );*/
    }

    var statusswtich = "",toggleSlidermode = "";
    const statusbatery = "OK";
    const statusbadgestyle  = "volts_sensor['attribute']['badge-style']";
    const cardbgstatus = "bg-status-+volts_sensor['bg-status']";
    statusswtich = "volts_sensor['switch']['switch-status']";

    {statusswtich === 'ON' ? toggleSlidermode = 'toggle-slider-on' : toggleSlidermode = 'toggle-slider-off' }

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    function replaceAll(str, find, replace) {
        console.log(str)
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }
    let unit_id = "u" + replaceAll(unit.unit['gw-mac'], ":", "")
    let link_to_unit = "/admin/unit/" + unit.unit['gw-mac']
    console.log("SERIES CARD: ", unit)

    let iconBoiler = unit["states"]["power"]["power-sensors"]["boiler"]
    return (
            <>
                <Card >

                    <CardHeader className={"justify-content-end text-xl-right"} >
                        <td className="text-center text-sm-right ">
                            {unit.unit['friendlyname']}
                        </td>
                        <FontAwesomeIcon className={"text-danger"} icon={icon({name: 'circle-dot', family: 'duotone', style: 'solid'})} />
                        <span className={"text-xl-right "}> <b>{"parseFloat(unit.states.temp.total).toFixed(2)"}ºc </b></span>
                        <span className="badge badge-success">Online</span>
                    </CardHeader>
                    <CardBody>
                        <td>
                            {unit.states.stay !== null ?
                                <a>
                                    <span className="badge badge-success">Reservation</span>
                                </a>: <></>}
                        </td>
                        <td className={"text-sm"}>
                            <FontAwesomeIcon className={"text-danger"} icon={icon({name: 'circle-dot', family: 'duotone', style: 'solid', animation: 'beat'})} />
                            {" "}{parseFloat(unit.states.power["total-power"]).toFixed(2)} {" watts"}
                        </td>
                    </CardBody>
                </Card>

                <Card className={"card-stats card-option bg-status-standard"}>
                    <CardHeader  className={"border-status-standart"}>

                        <td className="text-center text-sm-right ">
                            {unit.unit['friendlyname']}
                        </td>
                        <td className={"text-center text-sm justify-content-end"}>
                            <span className="badge badge-sm badge-success ">Online</span>
                            <VoltsUnitDropdown />
                        </td>
                    </CardHeader>
                    <a href={link_to_unit}>
                        <CardBody>
                            <Row>

                                <div className="col">
                                    <div >
                                        <CardTitle tag="h5">
                                            xxzzz
                                        </CardTitle>
                                    </div>
                                </div>
                                <Col className="col-auto col">
                                    <div className="icon icon-card">
                                        {statusbatery !== 'OK'
                                            ?
                                            <span className="batery-status"> </span>
                                            : <></>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="col">
                                    <span id={"unit_id"}
                                          className="card-font-montserrat h2  mb-0 text-white ">{"col 1" + " "}</span>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target={"unit_id"}
                                    >
                                        {"volts_sensor['sensor-mac']"}

                                    </UncontrolledTooltip>
                                </div>
                                <Col className="col">

                                    {/*<VoltsMultiRadialCard series={unit["states"]["power"]["series-power"]['series']} measureu={"wats"}/>*/}
                                    <span id={unit_id+"00"} className={"card-font-montserrat h2  mb-0 text-white "}>
                                    <td className="align-middle text-center text-sm">
                                        <span className="badge badge-sm badge-success">Online</span>
                                    </td>
                                    </span>
                                </Col>
                                <Col className="col-auto custom-toggle-flex">
                                </Col>
                            </Row>
                            <Row>
                                <div className="col">
                                    <p className="mb-0 text-sm">
                                <span
                                    className={`mr-2 font-weight-bold card-font-watios ${cardbgstatus == 'bg-status-standard' ? "" : "text-white"}`}>
                                    {unit.unit['friednlyname']}
                                </span>{" "}
                                    </p>
                                </div>
                                <Col className="col-auto">
                                    <p className={`mb-0 text-sm card-font-montserrat ${cardbgstatus == 'bg-status-standard' ? "" : "text-white"}`}>
                                        <span
                                            className="text-nowrap size-card-montserrat text-white">{moment(unit.states.pong.timestamp*1000).fromNow()}</span>
                                    </p>
                                </Col>
                            </Row>

                        </CardBody>
                    </a>

                    <VoltsBaseCardJson json_object={unit} />
                </Card>

            </>



    );
}
export default VoltsUnitCard;

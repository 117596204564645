import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import React from "react";

function Maincard(data) {
    //console.log("YYY",data.data)
    return (
        <>
            {data.data.map((sensor) => (
        <Card className="bg-gradient-default">
            <CardBody>
                <Row>
                    <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0 text-white">
                            {sensor['attribute']}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                            </span>
                    </div>
                    <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                            <i className="ni ni-active-40" />
                        </div>
                    </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                          <span className="text-white mr-2">
                            <i className="fa fa-arrow-up" />
                              {data['data'][0]['state']}
                          </span>
                    <span className="text-nowrap text-light">
                    {"data['attribute'][0]['friendly-name']"}
                          </span>
                </p>
            </CardBody>
        </Card>
                ))}
        </>
    );
}

export default Maincard;
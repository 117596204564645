import React from "react";
import axios from "axios";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {voltsIcons} from "../../variables/volts-icons";

const messagePlatformsIcons ={
    "messenger": "ni ni-send",
    "email": "ni ni-email-83",
    "pms": "ni ni-chat-round",
    "key": "ni ni-lock-circle-open"
}


function LockEventsTimeline({checkin}) {

    const [lockEvents, setLockEvents] = React.useState(null);
    async function handleSubmit(data) {
        console.log("LOCKMESSAGES parameter: ", data)
        axios({
            method: "POST",
            url: "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/checkins/lockevents",
            data: data,
            headers: {"x-api-key":"ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"}
        }).then((message_data ) => {
                console.log(message_data.data)
                setLockEvents(message_data.data)
                {/*this.setState({
            modalIsOpen: true
        })*/}
            }
        );
    }


    if (!lockEvents) {
        console.log("----LOCK-EVENTS parameter: ", checkin)
        handleSubmit({"booking-uuid": checkin["booking-uuid"]})
        return (<>No lock events</>)
    }

    return (
                <div
                    className="timeline timeline-one-side"
                    data-timeline-axis-style="dashed"
                    data-timeline-content="axis"
                >

                    {lockEvents.events.map((lockEvent) =>
                        <>
                            <div className="timeline-block">
                            <span className="timeline-step badge-success">
                              <i className={messagePlatformsIcons["key"]}/>
                            </span>
                                <div className="timeline-content">
                                    <div className="d-flex justify-content-between pt-1">
                                        <div>
                                          <span className="text-muted text-sm font-weight-bold">
                                            {lockEvent["integration"]}{" "}{lockEvent["action"]}
                                          </span>
                                        </div>
                                        <div className="text-right">
                                            <small className="text-muted">
                                                <i className="fas fa-clock mr-1"/>{new Date(lockEvent["timestamp"] * 1000).toLocaleString(
                                                    "es-ES",
                                                    {
                                                        month: "short",
                                                        day: "2-digit",
                                                        hour: "2-digit",
                                                        minute:"2-digit"
                                                    }
                                                )}{"h"}
                                            </small>
                                        </div>
                                    </div>
                                    <h6 className="text-sm mt-1 mb-0">
                                        {lockEvent["username"]}
                                    </h6>
                                </div>
                            </div>
                        </>
                    )}

                </div>
    )
}


class CheckinLockeventsModals extends React.Component {

    state = {
        exampleModal: false
    };
    checkin = this.props.checkin;
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    render() {
        return (
            <>
                {/*}
                <Button
                    color="primary"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal")}
                >
                    Launch demo modal
                </Button>
                */}
                <FontAwesomeIcon className={"text-grey"}
                                 onClick={() => this.toggleModal("exampleModal")}
                                 icon={voltsIcons["key"]} />
                {/*}</Button>*/}

                <Modal
                    className="modal-md"
                    isOpen={this.state.exampleModal}
                    toggle={() => this.toggleModal("exampleModal")}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {"Lock Activity"}{" "}{"Main Door"}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("exampleModal")}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {"TIMELINE LOCK"}
                        <LockEventsTimeline checkin={this.checkin} />
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("exampleModal")}
                        >
                            Close
                        </Button>
                        <Button color="primary" type="button">
                            Save changes
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default CheckinLockeventsModals;
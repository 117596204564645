import {
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import axios from "axios"
import React, { useState, useEffect, ReactDOM } from "react";
import moment from "moment";
import List from "list.js";


let data_items = []


const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/events/states";


async function axios_data() {
    const response = await axios.get(API_URL, {
        params:{
            'gw-mac': "vvbnmdn-001",
            'sensor-mac': '0x00124b002342bf55',
            'timestamp-offset': (moment().unix() -3600 ) + ".0",
            'timestamp-end': (moment().unix() )+ ".0",
            'attributes': 'state,linkquality',
            //'format': 'line-graph',
            'items': 90,
            //'frequency': 600
        }
    })
    return response.data
}


function VoltsBaseCard() {
    const [data_items, setDataItems] = React.useState(null);
    const [loaded, setLoaded] = React.useState(null);
    const domContainer = document.querySelector('#app');


    useEffect(() => {

        const loadData = async () => {
            const data = await axios_data();
            setDataItems(data);
            //set_data_items(data);
            console.log("DATA", data)
            setLoaded(true);

        };
        setTimeout(() => {
            loadData();
        }, 300);
    }, []);
    console.log(data_items)
    return (
        <>
            <Col xl="14">
                <Card>
                    <div>
                        <CardHeader>
                            <h6 className="surtitle">Title</h6>
                            <h5 className="h3 mb-0">Subtitle</h5>
                        </CardHeader>

                        <CardBody>
                            <div>
                                Card Content
                            </div>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        </>
    );
}

export default VoltsBaseCard;
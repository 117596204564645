import React from "react";
import axios from "axios"
import { useState, useEffect, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate
} from "react-router-dom";
import ListUnits from "../../../components/VoltsCards/ListUnits";
//import VoltsToDo from "../../../components/VoltsCards/volts-to-do";
//import VoltsEvents from "../../../components/VoltsCards/volts-events";
//import VoltsSensorCards from "../../../components/VoltsCards/volts-sensor-cards";
//import CardSimple from "../../../components/VoltsCards/CardSimple";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
//import { Chart } from "chart.js";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  //Badge,
  //Button,
  Card,
  CardHeader,
  //CardBody,
  //DropdownMenu,
  //DropdownItem,
  //DropdownToggle,
  //UncontrolledDropdown,
  //Form,
  //Input,
  //ListGroupItem,
  //ListGroup,
  //Media,
  //NavItem,
  //NavLink,
  //Nav,
  //Progress,
  //Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, CardFooter,
} from "reactstrap";

// core components
import DashHeader from "components/Headers/CardsHeaderDash.js";
import Pool from "../../../UserPool";
import VoltsDashPane from "components/VoltsCards/volts-dash-pane";


import VoltsMultiRadialCard from "../../../components/VoltsCards/volts-graph-cards/volts-multi-radial-graph";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'




{/*}
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

*/}


let unit_items = [];

async function axios_units(params) {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/units/ops_state", params)
  console.log("AXIOS UNITS", response)
  return response.data
}


function set_units(data) {
  unit_items = data
  //console.log("SET UNITS", unit_items)
  return unit_items
}


function Dashboard() {

  //console.log("PROCCESS: ", process.argv)
  const [currentUser, setcurrentUser] = React.useState(null);
  const [units, setUnits] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const thirdListRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    {/*setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");*/}
  };
  {/*if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }*/}
  useEffect(() => {
    const loadData = async (userName) => {
      //setcurrentUser(user)
      //console.log(userName)
      const data = await axios_units({params:{userName: userName}});
      setUnits(data);
      set_units(data);
      console.log("STATUS CODE ?????", data)
      //console.log("DATA", data)
      // check if response has units inside
      if (data['statusCode'] === 200){
        setLoaded(true);
      }
    };
    setTimeout(() => {
      //console.log("USER IN USE EFFECT: ", user['username'])
      loadData(user['username']);
    }, 1);
  },[]);
  //axios_unit().then(unit_items => set_units(unit_items));
  //console.log("UNITS", unit_items)
  let user = Pool.getCurrentUser()
  //setcurrentUser(user)
  //console.log("XXXXX>", user)

  return (
      <>
        <DashHeader name="volts" parentName="Home"/>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card className="bg-default shadow">
                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-white mb-0">{"Best AirBnB: Unidades Gestionadas"}</h3>
                </CardHeader>

                {/*<VoltsDashPane hello={"ddd"} units={unit_items.units}/>*/}
                {/*<div className="table-responsive" ref={thirdListRef}>
                </div>
                */}

                  {/*{loaded ? <ListUnits units={unit_items.items} /> : <h2>loading units</h2>}*/}
                  {loaded ? <VoltsDashPane units={unit_items.items} /> : <h2>------loading units</h2>}
                {/*}</div>*/}

              </Card>
            </div>
          </Row>
          {/*<Row>
            <Col xl="12">
              <VoltsSensorCards />
            </Col>
          </Row>*/}
          <Row>
            {/*}
            <Col xl="4">
              <VoltsToDo />
            </Col>
            */}
            <Col xl="6">
              {/*<VoltsEvents />*/}
            </Col>
          </Row>
            <Row>
              <div className="col">
                <Card className="border-0">
                  {/*{loaded ? <VoltsDashPane unit_data={unit_items.items} />: <h2>loading maps</h2>}
                  {loaded ? <MapDefault unit_data={unit_items.items} />: <h2>loading maps</h2>}*/}
                </Card>
              </div>
            </Row>
        </Container>
      </>
  );
}

export default Dashboard;
import React, { useRef, useState, useEffect } from "react";
import Maincard from "../../../components/VoltsCards/Card.js";
//import VoltsCard from "../../../components/VoltsCards/voltsCardProd";
import VoltsCard from "../../../components/VoltsCards/voltsCardAc"
import {Row, Col, Card, CardHeader, Container, Nav, NavItem, NavLink, CardBody} from "reactstrap";
import axios from "axios"
import {func} from "prop-types";

let events_items = [];

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/events/states";
const URL = 'wss://fxpyympclg.execute-api.eu-west-1.amazonaws.com/production';

const Socket =({unit, space}) => {
  const [events, setEvents] = React.useState(null);
  const [loadede, setLoadede] = React.useState(null);
  const [message, setMessage] = useState({});
  const [latestMessage, setlatestMessage] = useState("waiting for new events...")
  const [loaded, setLoaded] = useState(false)
  const [body, setBody] = useState("")
  const [isConnectionOpen, setConnectionOpen] = useState(false)
  const [spacesLink, setspacesLink] = useState([])
  const [activeSpace, setactiveSpace] = useState('MAIN-DOOR')
  const [cardSet, setcardSet] = useState([])

  //const [ws, setWs] = useState(new WebSocket(URL));

  const ws = useRef();

  function NavsLink({ spacesLinks, unit, space }) {
    let spaceLink = "/admin/space/" + unit + "/"
    let activeLink = ""
    console.log("SPACES LINKS", spacesLinks)
    console.log("SPACE: ", space)
    spacesLink.map((sp, idx) => {
      console.log("MAP SPACE ", idx, sp['space-name']  )
      if (sp['space-name'] === space.toUpperCase()) {
            console.log("IDX->", idx)
            spacesLink[idx]['active'] = true
            console.log('ACTIVE SPACE', sp['space-name'], space.toUpperCase)
          }
      else {
        spacesLink[idx]['active'] = false
      }
        }
        )

    return(

    <Nav pills>
      { loadede ? spacesLinks.map((spaceL) =>
          <div key={spaceL['space-name']}>
            <NavItem className={"mt-3 mr-2"}>
              {/*href={spaceLink + spaceL['space-name'].toLowerCase()} href={spaceLink + spaceL['space-name'].toLowerCase()} */}
              <NavLink active={spaceL['active']}
                  onClick={(e) => pill(e)}
              >
                {spaceL['space-name']}
              </NavLink>
            </NavItem>
          </div>
      ) : <></>}
    </Nav>
    )
  }


  async function axios_events(unit = "volts-test") {
    //CARD EVENT STATES
    //console.log("SUNDAY UNIT ->", unit)
    const response = await axios.get(API_URL, {
      params: {
        'gw-mac': unit['gw-mac'],
        'scope': "latest-states",
        'timestamp-offset': '1660151069.239336',
        'items': 50
      }
    })
    console.log("AXIOS events UNIT", response)
    return response.data
  }


  function setCards(events_items) {
    let cards = []
    console.log("SetCards", events_items)
    events_items.map((card, idx) => {
          if (card['sensor-location'] === activeSpace.toUpperCase()) {
            cards.push(card)
          }
        }
    )
    console.log("SetCards", cards)
    return(cards)
  }


  function pill(event) {
    console.log("PILL", event)
    //space = 'NOOOOOO'
    setactiveSpace(event['nativeEvent']['target']['innerText'])
    setcardSet(setCards(events_items['cards']))
  };

  function set_events(data) {
    events_items = data
    //console.log("DATA -----", data)
    //console.log("SET Events", events_items)
   set_space_links(events_items['spaces'])
    setcardSet(setCards(events_items['cards']))
    return events_items
  }
  function set_space_links(space_items) {
    let navLinkStatus = []
    if (space_items.length) {
      space_items.map((space, index) => {
        if (space['sensor-count']) {
          {/*}
          if ( index===0) {
            space['active'] = true
          }
          else {
            space['active'] = false
          }
          */
          }
          navLinkStatus.push(space)
        }
      })
    }
    console.log("SET SPACE LINKS",navLinkStatus)
    setactiveSpace(space)
    setspacesLink(navLinkStatus)
    return navLinkStatus
  }

  const submitMessage = (usr, msg) => {
    //const message = { user: usr, message: msg };
    //const message = {sensor: "0x001788010bcf233c"}
    if (body) {
      ws.send(JSON.stringify({gw: unit, sensor: ""}));
      setBody("");
    }
  }

  useEffect(() => {
    const loadData = async () => {
      const data = await axios_events(unit);
      console.log("CARDS", data)
      setEvents(data);
      set_events(data);
      console.log("CARDS", data)
      setLoadede(true);

    };
    setTimeout(() => {
      loadData();
    }, 300);

    ws.current = new WebSocket(URL + "?user_name=" + unit)
    console.log("WS Ready State: ", ws.current.readyState)
    if (ws.readyState !== 1) {
      console.log("Websocket state <> OPEN")
    } else {
      ws.send(JSON.stringify({gw: unit, sensor: ""}));
    }
    ws.current.onopen = () => {
      console.log('WebSocket reConnected');
      setConnectionOpen(true)
      //const message_to_send = {'name': 'volts-test2'};
      //ws.send(JSON.stringify(message_to_send));
    }


    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data)
      const new_message = event;
      //console.log("ON_MESSAGE", new_message)
      if ('data' in new_message) {
        //console.log('ON MESSAGE DATA: ', new_message)

        let datadict = JSON.parse(new_message.data)
        //console.log("TYPO: ", typeof(datadict), datadict)
        console.log("DATADICT: ", datadict)
        //console.log("DADA", events_items)
        //console.log(event_items['unit']['friendlyname'])
        events_items['cards'].map((card, index) => {

          //console.log("EVENT ITEMS", events_items['cards'][index]['sensor-mac'])
          //console.log("DATAD", datadict['sensor-mac'])
          if (events_items['cards'][index]['sensor-mac'] === datadict['sensor-mac']) {
            events_items['cards'][index] = datadict
            setlatestMessage(datadict['attribute']['time-text'])
            //console.log("INDEX MAP ->", index)

          }
          {/*events_items['cards'][0]['attribute']['time-text']*/
          }
          //setlatestMessage(datadict['attribute']['time-text'])
        });


        if ('attribute' in datadict) {
          setMessage(datadict)
          setLoaded(true)
        } else {
          console.log('NO ATTRIBUTE IN DICT')
        }
      } else {
        if ('message' in new_message) {
          console.log('ERROR: ', new_message.message)
        }
      }
    }

    return () => {
      ws.current.onclose = () => {
        console.log('WebSocket Disconnected');
        ws.current.close();
        //setWs(new WebSocket(URL));
      }
    };
    //}, [ws.onmessage, ws.onopen, ws.onclose], message );
  }, []);
  //console.log(typeof(message))
  //console.log('M',message)

  if (typeof (space) === 'undefined') {
    space="MAIN-DOOR"

    console.log("UNDEFINED SPACE", space)
  }
  else {
    console.log("NO UNDEFINED SPACE", space)

  }
  console.log("ACTIVE SPACE: ", activeSpace)
  //setactiveSpace(space)


  return (
      <>
      <Card>
        {/*<Container>*/}
          <CardHeader>
            <Row>
              <Col>
                <h5 className="h3 mb-0">Unit Sensors</h5>
              </Col>
              {/*<Col>
                                    <div className="progress-label">
                                        <span>{booking_items['active_stay']['status']}</span>
                                    </div>
                                </Col>*/}
              { loadede ?
              <Col className={"text-right"}>
                <small className="text-muted">
                  <i className="fas fa-clock mr-1"/>
                  {/*{events_items['cards'][0]['attribute']['time-text']*/}
                  {latestMessage}
                </small>
              </Col>
                  :
                  <></>
              }
            </Row>
          </CardHeader>
        <CardBody>
          { loadede ? <NavsLink spacesLinks={spacesLink} unit={unit['gw-mac']} space={activeSpace}/> : <></> }
          { loadede
            ?
              <Row className={"mt-3"}>
              {cardSet.map((card) => (
                      <div key={card['sensor-mac']} className="col-md-6">
                        <Col className={"mt-1"}>
                          <VoltsCard volts_sensor={card} device_type={unit['device-type']} mac_address={unit['mac-address']} unit_uuid={unit['uuid']} />
                        </Col>
                      </div>
              ))}
              </Row>

            :
            <>
              {"Loading..."}
            </>
        }
        </CardBody>
        </Card>
        {/*</Container>*/}
      </>
  )
}
export default Socket;

{/*
  const unixTimestamp = 1575909015

  const milliseconds = 1575909015 * 1000 // 1575909015000

  const dateObject = new Date(milliseconds)

  const humanDateFormat = dateObject.toLocaleString() //2019-12-9 10:30:15

  dateObject.toLocaleString("en-US", {weekday: "long"}) // Monday
  dateObject.toLocaleString("en-US", {month: "long"}) // December
  dateObject.toLocaleString("en-US", {day: "numeric"}) // 9
  dateObject.toLocaleString("en-US", {year: "numeric"}) // 2019
  dateObject.toLocaleString("en-US", {hour: "numeric"}) // 10 AM
  dateObject.toLocaleString("en-US", {minute: "numeric"}) // 30
  dateObject.toLocaleString("en-US", {second: "numeric"}) // 15
  dateObject.toLocaleString("en-US", {timeZoneName: "short"}) // 12/9/2019, 10:30:15 AM CST

*/}
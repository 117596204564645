import React, { useEffect } from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import Pool from "../../UserPool";
import { Account } from '../../views/pages/components/Accounts';
import Status from "../../views/pages/components/Status";
import axios from "axios"
import VoltsTimeline from "../VoltsCards/volts-timeline";
import {Redirect} from "react-router";
import {Modal} from "reactstrap";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import {useParams} from "react-router-dom";
import {voltsIcons} from "../../variables/volts-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ConfigModal from "../VoltsModals/configModal";


let sensor_items = [];
const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/incidents?gw-mac=volts40000000";


async function axios_sensors() {
  const response = await axios.get(API_URL, {
    params:{'gw-mac': "volts40000000" }
  })
  //console.log("AXIOS SENSORS",response)
  return response.data
}

function set_sensors(data) {
  sensor_items = data
  //console.log("SET SENSORS", sensor_items)
  return sensor_items
}

function AdminNavbar({ mUnits, unitValue, theme, sidenavOpen, toggleSidenav }) {
  const [sensors, setSensors] = React.useState(null);
  const [currentUser, setcurrentUser] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const [loggedOut, setLoggedOut] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [myUnits, setmyUnits] = React.useState(null);


  // TODO valor undefined, eliminar
  const { unit_uuid } = useParams();
  const { sensor_mac } = useParams();
  const { id } = useParams();

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };


  useEffect(() => {

    const user = Pool.getCurrentUser();
    setcurrentUser(user)
    //console.log("USER IN ...:", user)
    if (user === null) {
      setLoggedOut(true)
    }
    const loadData = async () => {

      const data = await axios_sensors();
      setSensors(data);
      set_sensors(data);
      //console.log("ADMIN DATA", data)
      setLoaded(true);
    };

    setTimeout(() => {
      loadData();
    }, 10);
  },[]);


  //console.log("ID----> admin navbar", id)
  let uservar = Pool.getCurrentUser()
  let optionsUnits = []
  //setmyUnits(mUnits)
  //console.log("MYunits: ", myUnits, mUnits, unitValue)
  //console.log("----->", typeof (currentUser), currentUser)
  //console.log("myUnits------>: ", myUnits)
  mUnits['items'].map((myUnit) => {
    //console.log("MY f NAME: ", myUnit['friendlyname'])
    optionsUnits.push({value: myUnit['gw-mac'], label: myUnit['friendlyname']})
  })


  console.log("Hello ADMIN NAv", unitValue, unit_uuid, sensor_mac, id)
  //setcurrentUser(Pool.getCurrentUser())
  return (
      <>
        <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-default": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": theme === "dark" },
                { "navbar-search-dark": theme === "light" }
              )}
            >
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-bed" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input value={unitValue} placeholder="Search" type="select">
                    {optionsUnits.map((option) => (
                        <option value={option.value}>{option.label}</option>
                    ))}
                  </Input>
                </InputGroup>
              </FormGroup>
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <i className="ni ni-bell-55" />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  <div className="px-3 py-3">

                    <h6 className="text-sm text-muted m-0">
// TODO marcar las alertas en create incident con username
                        {loaded  ?
                            <div>
                              You have <strong className="text-info">{sensor_items['incidents'].length}</strong>{" "}
                              alerts CAMBIAR A username ALERTS.
                            </div>

                              : "Loading...."}
                    </h6>
                  </div>
                  <ListGroup flush>
                    <ListGroupItem
                      className="list-group-item-action"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      tag="a"
                    >
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            alt="..."
                            className="avatar rounded-circle"
                            src={require("assets/img/theme/red-white.png").default}
                          />
                        </Col>
                        <div className="col ml--2">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h4 className="mb-0 text-sm">CC</h4>
                            </div>
                            <div className="text-right text-muted">
                              <small>2 hrs ago</small>
                            </div>
                          </div>
                          <p className="text-sm mb-0">
                            {loaded  ?
                                <>
                                  {sensor_items['incidents'][0]['incident-text']}
                                </>
                                : "Loading...."}
                          </p>
                        </div>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                  <DropdownItem
                    className="text-center text-info font-weight-bold py-3"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    View all
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            {/*<CognitoLogin />*/}
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="text-success" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle bg-white">
                      <FontAwesomeIcon className={"text-primary"} icon={voltsIcons["user"]} />
                      {/*<img alt="..." src={require("assets/img/brand/volts-blue-min.png").default} />*/}
                    </span>

                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        <Account>
                          <Status />
                        </Account>
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-primary text-overflow m-0"><b>volts</b></h6>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-single-02" />
                    <span>Mi cuenta</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-settings-gear-65" />
                    <span>Configuración</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-favourite-28" />
                    <span>Soporte</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <Account >
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        //console.log("LOGOUT DROPDOWN");
                        const user = Pool.getCurrentUser();
                        //console.log("LOGOUT",user)
                        if (user) {
                          user.signOut();
                          setLoggedOut(true)
                        }
                        else {
                          //console.log("NO USER IN LOGOUT",user)
                        }
                      }
                    }
                    >
                      <i className="ni ni-user-run" />
                      <span>Cerrar sesión</span>

                    </DropdownItem>
                    {loggedOut && (
                        <Redirect to="/auth" replace={true} />
                    )}
                  </Account>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;

import React, {useEffect} from "react";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import axios from "axios";

import VoltsBaseCardJson from "../../../components/VoltsCards/volts-base-cards/volts-base-card-json";

import VoltsAreaCard from "../volts-graph-cards/volts-area-graph"
import VoltsBubbleCard from "../volts-graph-cards/volts-bubble-graph";
import VoltsColumnCard from "../volts-graph-cards/volts-column-graph";
import VoltsRadialCard from "../volts-graph-cards/volts-radial-graph";
import VoltsBoolCard from "../volts-graph-cards/volts-bool-graph";
import ModalsJson from "./volts-base-modal-json";
//import modalsJson from "./volts-base-modal-json";


async function axios_sensor_series(params) {
    return await axios.get(
        "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/events/states/series",
            params
    )
}


function BodyCardSeriesGraph({seriesParams}) {
    const [graphSeries, setgraphSeries] = React.useState([])
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        const loadData = async (params) => {
            const data = await axios_sensor_series({params: params});
            console.log("GRAPSeries: " , data)
            {/*HAY QUE CAMBIAR ESTO*/}
            setgraphSeries( [
                {
                    name: seriesParams.title,
                    data: data.data.graph
                    // PARA RADIAL ???data: data['data']['series-total']['graph-totals']
                }
            ])
            if (data['status'] === 200) {
                setLoaded(true);
            }
        };
        setTimeout(() => {
            if (seriesParams) {
                loadData(seriesParams.params);
            }
            }, 1000);
    }, []);
    console.log("SERIES PARAMS GRAPH: ", seriesParams)
    return (
        <>
            <Col xl="14">
                <Card>
                    <div>
                        <CardHeader>
                            <h6 className="surtitle">{seriesParams.title}</h6>
                            <h5 className="h3 mb-0">{seriesParams.title}</h5>
                        </CardHeader>
                        <CardBody>
                            <div>

                                {loaded ?
                                    <>
                                        {seriesParams['default_graph'] === 'area' ?
                                            <>
                                                <VoltsAreaCard
                                                    //CAMBIAR estructura de parámetros
                                                    //options={options}
                                                    series={graphSeries}
                                                    measureu={seriesParams['measure-unit']}
                                                    title={"Hola!"}
                                                />

                                            </>

                                            : <></>
                                        }

                                        {seriesParams['default_graph'] === 'bubble' ?
                                            <>
                                                <VoltsBubbleCard
                                                    series={graphSeries}
                                                    measureu={seriesParams['measure-unit']}
                                                    title={"Hola!"}
                                                />
                                            </>

                                            : <></>
                                        }

                                        {seriesParams['default_graph'] === 'bool' ?
                                            <>
                                                <VoltsBoolCard
                                                    //CAMBIAR estructura de parámetros
                                                    //options={options}
                                                    series={graphSeries}
                                                    measureu={seriesParams['measure-unit']}
                                                    title={"Hola!"}
                                                />
                                            </>
                                            : <></>
                                        }

                                        <ModalsJson json_object={graphSeries}/>
                                        {/*<VoltsBaseCardJson json_object={graphSeries} />*/}
                                    </>
                                    :<></>
                                }
                            </div>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        </>
    );
}

export default BodyCardSeriesGraph


import React from "react";
import { Routes, Router, Switch, Route, Link, useParams} from "react-router-dom";
import Order from "./Order"
import { useState, useEffect, useCallback, useRef } from "react";
import SocketPower from "./volts-socket-power-graph";
import VoltsBookingProgress from "../../../components/VoltsCards/Volts-Booking-Progress";
import ListUnits from "../../../components/VoltsCards/ListUnits";
import VoltsToDo from "../../../components/VoltsCards/volts-to-do";
import VoltsMainDoor from "../../../components/VoltsCards/volts-alerts"
import VoltsEvents from "../../../components/VoltsCards/volts-events";
import VoltsSensorCards from "../../../components/VoltsCards/volts-sensor-cards";
import VoltsNoiseGraph from "../../../components/VoltsCards/volts-noise-graph";
import VoltsMapCard from "../../../components/VoltsCards/volts-map-card";
import Socket from "./volts-socket-cards";
import VoltsBar from "../../../components/VoltsCards/volts-bar"
import Activities from "../../../components/VoltsCards/volts-activities";
import CardSimple from "../../../components/VoltsCards/CardSimple";
import classnames from "classnames";
import { Chart } from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import VoltsCard from "../../../components/VoltsCards/voltsCardProd";
import VoltsBaseCard from "../../../components/VoltsCards/volts-base-card"
import VoltsGraphCard from "../../../components/VoltsCards/volts-graph-card"
import VoltsHeatmapCard from "../../../components/VoltsCards/volts-graph-heatmap";
import VoltsCardsCard from "../../../components/VoltsCards/volts-voltscards-card";


import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";

import CardsHeaderUnit from "components/Headers/CardsHeaderUnit.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2, chartExample3,
} from "variables/charts.js";

import axios from "axios"
import moment from "moment";
import VoltsSortable from "../tables/voltsSortable";
import SimpleHeader from "../../../components/Headers/SimpleHeader";

let unit_item = {};

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/dashboards/maindoor";
const API_HEATMAP_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/dashboards/maindoor/heatmap-graph";


async function axios_data_heatmap(id) {
  const response = await axios.get(API_HEATMAP_URL, {
    params:
        {
            "gw-mac": "vvbnmdn-001",
            "sensor-mac": "0x00158d00083a6858",
            "locations": ["MAIN-DOOR"],
            "functions": ["DOOR", "LOCK", "PRESENCE"],
            "graph-attribute": "contact",
            //"timestamp-offset": "1669304545.2466052",
            //"timestamp-end": "1669477345.247575",
            'timestamp-offset': ((moment().unix() - 28800)) + ".0",
            'timestamp-end': (moment().unix())+ ".0",
            "booking-uuid": ""
        }
  })
  console.log("RESPONSE -> GRAPH SERIES", response.data)
  return response.data
}


async function axios_data(id) {
    const response = await axios.get(API_URL, {
      params:
          {
            "gw-mac": id,
            "locations": ["MAIN-DOOR"],
            "functions": ["DOOR", "LOCK", "PRESENCE"],
              'timestamp-offset': ((moment().unix() - 28800)) + ".0",
              'timestamp-end': (moment().unix())+ ".0",
            //"timestamp-offset": "1668113589.908434",
            //"timestamp-end": "1668459191.7224178",
            "booking-uuid": ""
          }
    })
  console.log("RESPONSE DATA SERIES", response.data)
  return response.data
  }
{/*
function set_unit(data) {
  unit_item = data
  console.log("SET UNIT", unit_item)
  return unit_item
}
*/}
const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
  'Content-Type': 'application/json',
  'x-api-key': VOLTS_API_KEY
};


function Dashboard() {
  const [data_items, setDataItems] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  //const [loadedgraph, setLoadedgraph] = React.useState(null);
  //const [graph_items, setGraphItems] = React.useState(null);
  //const thirdListRef = React.useRef(null);
  //const [activeNav, setActiveNav] = React.useState(1);
  const { id } = useParams();
  const { maindoor } = useParams();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }


  useEffect(() => {
    const loadData = async () => {
      const data = await axios_data(id);
      setDataItems(data);
      setLoaded(true);
      console.log("LOAD DATA DASH MAINDOOR", data)
    };
      {/*
    const loadGraphData = async () => {
        const data = await axios_data_heatmap(id);
        setGraphItems(data);
        setLoadedgraph(true);
        console.log("LOAD DATA", data)
      };
      */}
    setTimeout(() => {
      loadData();
      //loadGraphData();
    }, 300);
  }, []);


  return (
      <>
        <SimpleHeader name="Reference Cards" parentName="Tables" />
        <Container className="mt--6" fluid>

          <Row>
            <Col xl="8">
                {/*heatmap_series={graph_items['graphs']['series']}*/}

                {
                    loaded ?
                        <>
                    {data_items['cards'].map((graph) => (
                        <VoltsHeatmapCard heatmap_series={graph}/>
                        ))}
                    </>
                    :
                    <></>
                }

                {/*<VoltsSortable/>*/}
                {/*<VoltsGraphCard />*/}
                {/*<VoltsNoiseGraph/>*/}

            </Col>
            <Col xl="4">
              {loaded ?
                  <VoltsCardsCard volts_cards={data_items['cards']} device_type={data_items.unit['device-type']} mac_address={data_items.unit['mac-address']} unit_uuid={data_items.unit['uuid']}/>
                  : <></>
              }
                <VoltsBaseCard/>
                <VoltsBaseCard/>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              { loaded ? data_items.unit['friendlyname']: <> NOT LOADED </> }
            </Col>
            <Col xl="6">
              COL XL 6
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default Dashboard;

{/*
{loaded ? <VoltsBookingProgress unit={unit.unit}/> : <>Loading</>}
              { loaded ? <VoltsMapCard params={unit['map-params']}/> : <> {"Loading MAP"}</>}
              {loaded && maindoor !== null? <VoltsEvents params={
                {params:{
                  'gw-mac': id,
                  'sensor-mac': unit.sensors[maindoor]['sensor-mac'],
                    'timestamp-offset': (moment().unix() - 36000) + ".0",
                    'timestamp-end': (moment().unix())+ ".0",
                  'attributes': 'contact',
                  'items': 41,
                }}}/> : <>Loading</>}
*/}
{/*<CardsHeaderUnit name={loaded ?  unit.unit.friendlyname: id} parentName="units"/>*/}

{/*
var indexSensor = data['sensors'].findIndex(function (el) {
        return el['sensor-location'] === 'MAIN-DOOR';
      });

      if (indexSensor !== -1) {
        setMaindoor(indexSensor)
        console.log("MAIN DOOR IS: ", data['sensors'][indexSensor]['friendly-name'])
      }
*/}
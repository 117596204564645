import React from "react";
import {useParams} from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios"
import moment from "moment";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import { Chart } from "chart.js";
import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";

//desconectado para cambiar parámetros de cabecera
import CardsHeaderUnit from "components/Headers/CardsHeaderUnit.js";
import VoltsIncidentsSortable from "../tables/voltsIncidentsSortable";
import CardsHeaderDash from "../../../components/Headers/CardsHeaderDash";



let incident_data = {};


async function axios_sensor_incidents(sensor_mac, gw_mac) {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/incidents", {params:{
        'gw-mac': gw_mac,
  }})
  console.log("AXIOS RESPONSE INCIDENTS ",response)
  return response.data
}


function set_incident_data(datar) {
  incident_data = datar
  return incident_data
}


const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
  'Content-Type': 'application/json',
  'x-api-key': VOLTS_API_KEY
};


function VoltsIncidents() {

  const [sensor, setSensor] = React.useState(null);
  const [incidentData, setincidentData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const [loadedIncidents, setloadedIncidents] = React.useState(null);
  const [timestampOffset, settimestampOffset] = React.useState((moment().unix() - 28800));
  const [timestampEnd, settimestampEnd] = React.useState((moment().unix()));
  const thirdListRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

  //PARAMETROS PASADOS EN URL

  const { sensor_mac } = useParams();
  const { gw_mac } = useParams(); // gw-mac
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {

    const loadSensorGraph = async () => {
      const datar = await axios_sensor_incidents(
          sensor_mac,
          gw_mac,
          timestampOffset,
          timestampEnd
      );
      setincidentData(datar)
      set_incident_data(datar)
      setloadedIncidents(true)
    };

    setTimeout(() => {
      loadSensorGraph()
    }, 1);
  },[]);
  console.log("INCIDENT DATA: ", incidentData)
  return (
      <>
        <CardsHeaderDash
            className="mb-3"
            unit={loaded ?  sensor.unit.friendlyname : gw_mac}
            address={loaded ?  sensor.unit.address : gw_mac}
            parentName={loaded ? sensor.unit['gw-mac']: gw_mac}
        />
        <Container className="mt--5" fluid>
          <Row>
            <Col xl="12">
              { loadedIncidents ?
                  <VoltsIncidentsSortable
                      incidents={incidentData.incidents}
                  />
                  :
                  <></>
              }

            </Col>
            {/*}
            <Col xl="4">

              {loaded ?
                  <VoltsCardsCard
                      volts_cards={[sensor.card]}
                      device_type={sensor.unit['device-type']}
                      mac_address={sensor.unit['mac-address']}
                      unit_uuid={sensor.unit['uuid']}
                  />
                  :
                  <></>
              }

              {loaded ?
                  <VoltsSensorLatestStateCard
                      sensor={sensor.sensor}
                      card={sensor.card}
                      latest_state={sensor['latest-state']}
                  />
                  :
                  <></>
              }

              {loaded ?
                  <VoltsSensorSpecCard
                      sensor={sensor.sensor}
                      card={sensor.card}/>
                  :
                  <></>
              }

            </Col>
            */}
          </Row>
        </Container>
      </>
  );
}

export default VoltsIncidents;
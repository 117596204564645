import React, {useEffect} from "react";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";


function DashHeaderCardSimple({link, card}) {
    console.log("CARD: ", card)
    let percentage = card.percentage+"%"
    let percentageText = card.percentageText
    let percentageIcon = card.percentageIcon
    let icon = card.icon
    let attributeText = card.attributeText
    let attributeState = card.attributeState
    let attributeUnit = card.attributeUnit


    return (
        <>
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                {attributeText}
                            </CardTitle>
                            <span className="h2 mb-0"><b>{attributeState}</b> {attributeUnit}</span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-neutral text-white rounded-circle shadow">
                                <a href={link} >
                                    <i className={"ni " + icon} />
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className={"fa " + percentageIcon} /> {percentage}
                      </span>{" "}
                        <span className="text-nowrap">{percentageText}</span>
                    </p>
                </CardBody>
            </Card>
        </>
    );
}

export default DashHeaderCardSimple


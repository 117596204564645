import Chart from "react-apexcharts";

const slugify = str =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');


const graphType = 'bubble'


function VoltsBubbleCard({series, measureu}) {
    const options = { //data on the x-axis
        chart: {
            id: slugify(series[0].name),
            type: graphType,
            height: 150,
            },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return (Math.round(val));
            },
            offsetY: -2,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        fill: {
            opacity: 0.8
        },
        yaxis: {
            max: 999,
            tickAmount: 12,

        },

        xaxis: {
            //categories: date,
            type: 'datetime',
            //min: new Date('1 JAN 2022').getTime(),
            //tickAmount: 12,

        },
    }
    {/*
        console.log("Bool Series: ", series)
        series[0].data.forEach((data, idx) => {
            console.log("D", data[1])
            if (data[1] === 'True') {

                series[0].data[idx][1] = 1.0
            } else {
                console.log(data[1])
                series[0].data[idx][1] = -1.0
            }

        })
    */}


    {/*}
    series['data'] = generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
        min: 10,
        max: 60
    })*/}
    console.log("GRAPH------>", series)
    series[0].data.map(( dato, idx) => {
        console.log(dato, idx)
    })



return(

    <Chart
        options={options}
        series={series}
        type={graphType}
        measureu={measureu}
        //width="300"
        //height="150"
    />

)}


export default VoltsBubbleCard
import React, { useState } from 'react';
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  //Card,
  //CardBody,
  //CardTitle,
  Dropdown, DropdownMenu, DropdownToggle, DropdownItem,
  Container,
  Row,
  Col,
  //NavItem,
  //NavLink,
  Nav,
  //TabContent,
  TabPane, UncontrolledDropdown, ListGroup, ListGroupItem, CardTitle, Card, CardBody
} from "reactstrap";
import DashHeaderHumansCard from "../VoltsCards/volts-base-cards/volts-dash-header-humans-card";


import Datepicker from "../../views/pages/forms/voltsDatePicker";
import DashHeaderCard from "../VoltsCards/volts-base-cards/volts-dash-header-card";
import DashHeaderCardSimple from "../VoltsCards/volts-base-cards/volts-dash-header-base-card";
import DashHeaderCardGrahp from "../VoltsCards/volts-base-cards/volts-dash-header-base-card_graph";
import VoltsMultiRadialCard from "../VoltsCards/volts-graph-cards/volts-multi-radial-graph";
import DashHeaderStayCard from "../VoltsCards/volts-base-cards/volts-dash-header-stay-card";
import DashHeaderCardGraph from "../VoltsCards/volts-base-cards/volts-dash-header-base-card_graph";



//class Navs extends React.Component {
function Navs ({unit, gw_mac})
{

  let state = {
    navPills: 1
  };
  const [cSelected, setCSelected] = useState([]);
  const [rSelected, setRSelected] = useState(1);

  const onCheckboxBtnClick = (selected) => {
    const index = cSelected.indexOf(selected);
    if (index < 0) {
      cSelected.push(selected);
    } else {
      cSelected.splice(index, 1);
    }
    setCSelected([...cSelected]);
  };

  {
    let dash_options = [
        {title: "Acceso Principal", text: "Accesos y actividad en la puerta principal ", route: "maindoor"},
        {title: "Cuadro Eléctrico", text: "Cuadro eléctrico y consumo de energía", route: "switchbox"},
        {title: "Control de Ruido", text: "Control de Ruido", route: "dash"},
    ]
    //console.log(dash_options);
    //console.log(unit)
    return (
        <>
          <Nav
              className="nav-pills flex-column flex-sm-row"
              id="tabs-pills"
              pills
              role="tab"
          >
            {/*<Row>*/}
                      <UncontrolledDropdown nav>
                        <DropdownToggle className="nav-link" color="" tag="a">
                          <i className="ni ni-ungroup" /> Dashboards
                        </DropdownToggle>
                        <DropdownMenu
                            className="dropdown-menu-xl py-0 overflow-hidden"
                            left
                        >
                          <div className="px-3 py-3">
                            <h6 className="text-sm text-muted m-0">
                              {/*}
                      {loaded  ?*/}
                              {/*}
                    <div>
                      You have <strong className="text-info">{"sensor_items['incidents'].length"}</strong>{" "}
                      alerts.
                    </div>
                    */}
                              {/*
                          : "Loading...."}
                          */}
                            </h6>
                          </div>
                          <ListGroup flush>
                            { dash_options.map((dash) =>(
                                <ListGroupItem
                                    className="list-group-item-action"
                                    href={"/admin/dashboard/" + gw_mac + "/" + dash.route}
                                    tag="a"
                                >
                                  {/*onClick={(e) => e.preventDefault()}*/}
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      {/*<i className="ni ni-collection rounded-circle"/>*/}
                                      <img
                                          alt="..."
                                          className="avatar rounded-circle"
                                          src={require("assets/img/theme/green-navy.png").default}
                                      />

                                    </Col>
                                    <div className="col ml--2">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          <h4 className="mb-0 text-sm">{dash.title}</h4>
                                        </div>
                                        {/*}
                            <div className="text-right text-muted">
                              <small>go</small>
                            </div>
                            */}
                                      </div>
                                      <p className="text-sm mb-0">
                                        {dash.text}
                                        {/*}
                            {loaded  ?
                                <>
                                  {sensor_items['incidents'][0]['incident-text']}
                                </>

                                : "Loading...."}
*/}
                                      </p>
                                    </div>
                                  </Row>
                                </ListGroupItem>
                            ))}
                          </ListGroup>

                          <DropdownItem
                              className="text-center text-info font-weight-bold py-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                          >
                            {/*View all*/}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {/*}</Row>*/}

                    {/*<p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Last 72 hours</span>
                    </p>
*/}
                    {/*}
            <UncontrolledDropdown nav>
              <Datepicker showTimeSelect dateFormat="MMMM d, yyyy HH:mm"/>
            </UncontrolledDropdown>
            */}
            {/*}
            <UncontrolledDropdown >
            <ButtonGroup >
              <Button
                  color="primary"
                  outline
                  onClick={() => setRSelected(1)}
                  active={rSelected === 1}
              >
                4hrs
              </Button>
              <Button
                  color="primary"
                  outline
                  onClick={() => setRSelected(2)}
                  active={rSelected === 2}
              >
                8hrs
              </Button>
              <Button
                  color="primary"
                  outline
                  onClick={() => setRSelected(3)}
                  active={rSelected === 3}
              >
                24hrs
              </Button>
            </ButtonGroup>
            </UncontrolledDropdown>
            */}
            {/*<p>Selected: {rSelected}</p>*/}
            {/*}
            <NavItem>
              <NavLink
                  aria-selected={this.state.navPills === 2}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.navPills === 2
                  })}
                  onClick={e => this.toggleNavs(e, "navPills", 2)}
                  href="http://volts.cloud/"
                  target="_blank"
                  role="tab"
              >
                <i className="ni ni-bell-55" /> Alarms
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  aria-selected={this.state.navPills === 3}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.navPills === 3
                  })}
                  onClick={e => this.toggleNavs(e, "navPills", 3)}
                  href="#pablo"
                  role="tab"
              >
                Actions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  aria-selected={this.state.navPills === 4}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.navPills === 4
                  })}
                  onClick={e => this.toggleNavs(e, "navPills", 4)}
                  href="#pablo"
                  role="tab"
              >
                Spaces
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  aria-selected={this.state.navPills === 5}
                  className={classnames("mb-sm-3 mb-md-0", {
                    active: this.state.navPills === 5
                  })}
                  onClick={e => this.toggleNavs(e, "navPills", 5)}
                  href="#pablo"
                  role="tab"
              >
                Sensors
              </NavLink>
            </NavItem>
            */}
            </Nav>
        </>
    );
  }
}


function CardsHeaderUnit({ unit }) {
  //{loaded ?  unit.unit.friendlyname: id} address={loaded ?  unit.unit.address: id} parentName={loaded ?  unit.unit['gw-mac']: id}
  console.log("header unit:", unit)
  let unit_link = "/admin/unit/" + unit['unit']['gw-mac']
  //let destination_link = "/"
  console.log("Unit_link: ", unit_link)

  return (
    <>
      <div className="header bg-default pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="9" xs="7">
                <a href="/admin/dashboard" >
                  <i className="fas fa-home" />
                </a>
                {" "}
                <h6 className="h2 text-white d-inline-block mb-0">{""}{unit['unit']['friendlyname']}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  {/*<BreadcrumbItem>
                    <a href="/admin/dashboard" >
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>*/}
                  <BreadcrumbItem>
                    {/*onClick={(e) => e.preventDefault()}*/}
                    <a href={unit_link}>
                      {unit['unit']['gw-mac']}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {unit['unit']['address']}
                  </BreadcrumbItem>
                </Breadcrumb>

              </Col>
              <Col className="text-right" lg="2" xs="5">
                <Navs unit={unit['unit']} gw_mac={unit['unit']['gw-mac']}/>
                {/*<Button
                  className="btn-neutral"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Presence!
                </Button>
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Booking Status!
                </Button>*/}
              </Col>
            </Row>
            <Row >
              {/*<Row >*/}
              <Col md="6" xl="3">
                <DashHeaderCardSimple link={"/admin/actions/" + unit['unit']['gw-mac']+ "/ww"} card={unit['actions']['card']}/>
              </Col>
                <Col md="6" xl="3">
                  <DashHeaderCardSimple link={"/admin/incidents/" + unit['unit']['gw-mac']+ "/ww"} card={unit['incidents']['card']}/>
                </Col>
              {/*}

              { typeof (unit['unit_ops']['dash']['stayInfo']['progress'])  != "undefined" ?
                <Col md="6" xl="3">
                  <DashHeaderStayCard link={"/admin/bookings/" + unit['unit']['gw-mac'] + "/ww"} card={unit}/>
                </Col>
                  :
                  <></>
              }
              */}
              {/*<VoltsMultiRadialCard series={unit['unit_ops']["states"]["power"]["series-power"]['series']} measureu={"wats"}/>*/}
              {/*}
              {
                unit['unit_ops']['dash']['power']['series-power']['labels'].length ?

                  <Col md="6" xl="3">

                    <DashHeaderCardGraph unit={unit} graph={unit['unit_ops']["states"]["power"]["series-power"]['series']} card={{"attributeUnit": "w", "attributeState": Number(unit['unit_ops']['dash']['power']['total-power']).toFixed(2), "attributeText": "Consumo Actual"}}/>
                  </Col>
                  :
                  <></>
              }*/}
              {/*}
              {
                typeof(unit['unit_ops']['dash']['humansInfo']['state']) != 'undefined' ?

                    <Col md="6" xl="3">
                      <DashHeaderHumansCard card={unit}/>
                    </Col>
                    :
                    <></>
              }
*/}

              {/*
              <Col md="6" xl="3">
                  <DashHeaderCard seriesParams={{
                    "title": "Relay Energy",
                    "measure-unit": "dB",
                    "params": {
                      "sensor-mac": "XzFvolts-test",
                      "attribute": "relay_energy",
                      "frequency": 900,
                      "format": "query",
                      "graph-series-attr": "",
                      "sensor-functionalities": "BOILER,HVAC",
                      "gw-mac": "voltsgy112",
                      "unit-uuid": "f00dcfd4-ba42-4e4e-bf4c-fad2d634226b"
                    }
                  }}/>
                </Col>
              */}
                {/*}</Row>*/}

            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeaderUnit.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeaderUnit;

import {Badge, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem} from "reactstrap";
import React, { useState, useEffect } from "react";

const MapDefault = ({marker_param}) => {
    const [units, setUnits] = React.useState();
    const mapRef = React.useRef(null);
    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;
        let myLatlng = new google.maps.LatLng(marker_param.lat, marker_param.lng);
        console.log("MARKER-->", marker_param)
        const mapOptions = {
            zoom: 15,
            center: myLatlng,
            scrollwheel: false,
            zoomControl: true,
        };

        map = new google.maps.Map(map, mapOptions);

        let marker, u = 0, infowindow, contentString, content

        myLatlng = new google.maps.LatLng(marker_param['lat'], marker_param['lng']);
        marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,
            title: marker_param['friendlyname'],
        });

        contentString = '<div class="info-window-content"><h2>${}</h2>' + "<p>volts</p></div>";
        google.maps.event.addListener(marker, 'click', (function (marker, u) {
            return function () {
                infowindow = new google.maps.InfoWindow({
                    content: myLatlng,
                });
                infowindow.setContent({content: "marker_param['friendlyname']"});
                infowindow.open(map, marker);
            }
        })(marker, u));
    }, []);
    console.log("MARKER PARAM", marker_param)
    return (
        <div
            style={{ height: `300px` }}
            className="map-canvas"
            id="map-default"
            ref={mapRef}
        />
    );
};


function VoltsMapCard({params}) {

    console.log("MAP PARAMS", params)
    return (
        <>
            <Card>
                <CardHeader>
                    <h5 className="h3 mb-0">{params.friendlyname}</h5>
                </CardHeader>
                <CardBody>
                    <MapDefault marker_param={params.location} />
                    <small className="text-muted">
                        <i className="ni ni-pin-3 mr-1"/>{params.address}
                    </small>
                </CardBody>
            </Card>
        </>
    );
}
export default VoltsMapCard;


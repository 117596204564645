import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// reactstrap components
import {
  Badge,
    Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from "axios";

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/switchs"

function Sortable({switchs}) {
  const firstListRef = React.useRef(null);
  const secondListRef = React.useRef(null);
  const thirdListRef = React.useRef(null);


  //"00178801023698ca"
  const getData = async (sensor_mac, command) => {
    const response = await axios.put(API_URL, null,{
      headers: {
        'Content-Type': 'application/json'
      },
      params:
          {
            "sensor-mac": sensor_mac,
            "gw-mac": "volts00000103",
            "command": command
          }
    })
    return response.data;
  }
  {/*}
  const handleData = async () => {
    const resp = await axios.get("api/city");
    const data = await resp.data.content;
    setData(data);
    setFilteredData(data);
    //setData(data);
  };
  */}

  React.useEffect(() => {
    new List(firstListRef.current, {
      valueNames: ["name", "budget", "status", "completion"],
      listClass: "list",
    });
    new List(secondListRef.current, {
      valueNames: ["name", "budget", "status", "completion"],
      listClass: "list",
    });
    new List(thirdListRef.current, {
      valueNames: ["name", "budget", "status", "completion"],
      listClass: "list",
    });
  }, []);
  function OnOption(e, sensor_mac, sw_command)
  {
    getData(sensor_mac, sw_command)
    e.preventDefault()
    console.log("E", e.button)
    //alert(sensor_mac +" " + sw_command);
  }
  console.log(switchs)
  return (
    <>

      {/*<Container className="mt--6" fluid>
        <Row>
          <div className="col">*/}
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Light table</h3>
              </CardHeader>
              <div className="table-responsive" ref={firstListRef}>
                <Table className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Switch Name
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Location
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Status
                      </th>
                      <th scope="col">Users</th>
                      <th className="sort" data-sort="completion" scope="col">
                        Watts.
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                  { switchs.map((switch_row, idx) => (
                    <tr key={"child"+switch_row['sensor-mac']}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            id={"s" + idx.toString()}
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={
                                require("assets/img/theme/bootstrap.jpg")
                                  .default
                              }
                            />
                            <UncontrolledTooltip
                                delay={0}
                                target={"s"+idx.toString()}
                            >
                              {switch_row['sensor-mac']}
                            </UncontrolledTooltip>
                          </a>
                          <Media>
                            <span className="name mb-0 text-sm">
                              {switch_row['attribute']['friendly-name']}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td className="budget">{switch_row['sensor-location']}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          <span className="status">{switch_row['attribute']['badge-text']}</span>
                        </Badge>
                      </td>
                      <td>
                        <Button color="success" outline size="sm" type="button">
                          Toggle
                        </Button>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="completion mr-2">60%</span>
                          <div>
                            <Progress max="100" value="60" color="warning" />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            color=""
                            role="button"
                            size="sm"
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => OnOption(e, switch_row['sensor-mac'], 1)}
                            >
                              Action ON
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => OnOption(e,switch_row['sensor-mac'],0)}
                            >
                              Action OFF
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => OnOption(e,switch_row['sensor-mac'],0)}
                            >
                              Power Bill
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    ))}


                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
      {/*}
          </div>
        </Row>


        <Row>
          <div className="col">


          </div>
        </Row>
      </Container>
*/}
    </>
  );
}

export default Sortable;

import React, { useRef, useState, useEffect } from "react";
import {Row, Col, Card, CardHeader, Container, CardBody} from "reactstrap";
import axios from "axios"
import {Doughnut} from "react-chartjs-2";


let events_items = [];
let graphData = [];
let powerSensorTypes = ['HVAC', 'POWER', 'BOILER']

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/events/states";
const URL = 'wss://fxpyympclg.execute-api.eu-west-1.amazonaws.com/production';

// Colors
var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#002842",
    primary: "#00e7bd",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#ff5f69",
    warning: "#ffbcbb",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};
var chartPower = {
  data: {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          colors.theme["warning"],
          colors.theme["primary"],
        ],
        label: "Realtime power",
      }
]
  },
  options: {
    responsive: true,
    legend: {
      position: "top",
    },
    animation: {
      animateScale: false,
      animateRotate: false,
    },
  },
};


const SocketPower =({unit}) => {
  const [events, setEvents] = React.useState(null);
  const [graph, setGraph] = React.useState(null);
  //const [graphData, setgraphData] = React.useState(null);
  const [loadede, setLoadede] = React.useState(null);
  const [message, setMessage] = useState({});
  const [latestMessage, setlatestMessage] = useState("connecting...")
  const [loaded, setLoaded] = useState(false)
  const [body, setBody] = useState("")
  const [isConnectionOpen, setConnectionOpen] = useState(false)
  //const [ws, setWs] = useState(new WebSocket(URL));


  const ws = useRef();

  async function axios_events() {
    const response = await axios.get(API_URL, {
      params:{
        'gw-mac': unit,
        'scope': "latest-states",
        'timestamp-offset': '',
        'items': 50
      }
    })
    //console.log("UNIT en axios_events", unit)
    //console.log("AXIOS events GRAPH", response)
    return response.data
  }


  function set_events(data) {
    events_items = data
    let powerSensors = []
    let powerData = []
    let powerObject = {}
    data.cards.map((card) => {
      if (powerSensorTypes.includes(card['sensor-function'])) {
        //console.log(card['sensor-function'])
        //console.log(card['sensor-mac'])
        //console.log(card['attribute']['friendly-name'])
        //powerSensors.push(card['sensor-mac'])
        //powerData.push(card['attribute']['status-text'].replace(' Watts', ''))
        // creamos un array con los power sensors detectados
        powerObject = {
          'sensor-mac': card['sensor-mac'],
          'watts': card['attribute']['status-text'].replace(' Watts', ''),
          'friendly-name': card['attribute']['friendly-name'],
          'sensor-location': card['attribute']['sensor-location']
        }
        powerData.push(powerObject)

      }
    })
    graphData = powerData

    //console.log("SENSORS POWER DATA ARRAY", powerData)
    //console.log("CARDS PARA POWER; ", data.cards[0]['sensor-function'])
    //console.log("SET CARDS", events_items)
    return events_items
    }

  const submitMessage = (usr, msg) => {
    //const message = { user: usr, message: msg };
    //const message = {sensor: "0x001788010bcf233c"}
    if (body) {
      ws.send(JSON.stringify({gw: "", sensor: ""}));
      setBody("");
    }

  }

  useEffect(() => {
    const loadData = async () => {
      const data = await axios_events(unit);
      setEvents(data);
      set_events(data);
      //console.log("CARDS", data)
      setLoadede(true);
    };
    setTimeout(() => {
      loadData();
    }, 300);
    //DESCONECTADO PARA NO VER MSG EN CONSOLA
    ws.current = new WebSocket(URL)
    //console.log("WS Ready State: ", ws.current.readyState)
    if (ws.readyState !== 1) {
      //console.log("Websocket state <> OPEN")
    }
    ws.current.onopen = () => {
        //console.log('WebSocket reConnected');
        setConnectionOpen(true)
        //const message_to_send = {'name': 'volts-test2'};
        //ws.send(JSON.stringify(message_to_send));
      }


    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data)
      const new_message = event;
      //console.log("ON_MESSAGE", new_message)
      if ('data' in new_message) {
        //console.log('ON MESSAGE DATA: ', new_message)

        let datadict = JSON.parse(new_message.data)
        //console.log("TYPO: ", typeof(datadict), datadict)
        //console.log("DATADICT: ", datadict)
        //console.log("DADA", events_items)
        //console.log(event_items['unit']['friendlyname'])
        //buscamos si es un power sensor

        var indexSensor = graphData.findIndex(function (el) {
          return el['sensor-mac'] === datadict['sensor-mac'];
        });

        if (indexSensor !== -1){
          //es un power sensor
          var sens = graphData[indexSensor]
          //console.log("GRAPHDATA ONMESSAGE", graphData, indexSensor)
          //actualizar el dato en el gráfico
          chartPower['data']['datasets'][0]['data'][indexSensor] = datadict['attribute']['status-text'].replace(' Watts', '')
          chartPower['data']['labels'][indexSensor] = datadict['sensor-function'] + " " +  datadict['sensor-location']
          //console.log('CHARPOWER', chartPower)
        }
        events_items['cards'].map((card, index) => {

          //console.log("EVENT ITEMS", events_items['cards'][index]['sensor-mac'])
          //console.log("DATAD", datadict['sensor-mac'])
          if (events_items['cards'][index]['sensor-mac'] === datadict['sensor-mac']) {
              events_items['cards'][index] = datadict
              setlatestMessage()
              //console.log("INDEX MAP ->", index)

            }
          {/*events_items['cards'][0]['attribute']['time-text']*/}
          setlatestMessage(datadict['attribute']['time-text'])
          });


        if ('attribute' in datadict)
        {
          setMessage(datadict)
          setLoaded(true)
        }
        else
        {
          console.log('NO ATTRIBUTE IN DICT')
        }
      }
      else {
        if ('message' in new_message)
          {
            console.log('ERROR: ', new_message.message)
          }
      }

    }
    return () => {
      ws.current.onclose = () => {
        //console.log('WebSocket Disconnected');
        ws.current.close();
        //setWs(new WebSocket(URL));
      }
    };
  //}, [ws.onmessage, ws.onopen, ws.onclose], message );
  }, []);
  //console.log(typeof(message))
  //console.log('M',message)
  //console.log("INIT CHARTPOWER", chartPower)

  return (
      <>
        {/*{events_items['cards'][0]['attribute']['time-text']*/}

        {/*<Container>*/}
        {/*<Card>*/}
          {/*
          <CardHeader>
            <Row>
              <Col>
                <h5 className="h3 mb-0">Live Sensorss {typeof(unit)}</h5>
              </Col>

              { loadede ?
              <Col className={"text-right"}>
                <small className="text-muted">
                  <i className="fas fa-clock mr-1"/>
                  {latestMessage}
                </small>
              </Col>
                  :
                  <></>
              }
            </Row>
          </CardHeader>
          */}

        { loadede
            ?


                      <Card>
                        <CardHeader>
                          <h6 className="surtitle">Power Spending</h6>
                          <h5 className="h3 mb-0">Realtime</h5>
                        </CardHeader>
                        <CardBody>
                          <div className="chart">
                            <Doughnut
                                data={chartPower.data}
                                options={chartPower.options}
                                className="chart-canvas"
                                id="chart-doughnut"
                            />
                          </div>
                        </CardBody>
                      </Card>



            :
            <>
              {"Loading..."}
            </>
        }
        {/*}</Card>*/}
      </>
  )
}
export default SocketPower;

{/*
  const unixTimestamp = 1575909015

  const milliseconds = 1575909015 * 1000 // 1575909015000

  const dateObject = new Date(milliseconds)

  const humanDateFormat = dateObject.toLocaleString() //2019-12-9 10:30:15

  dateObject.toLocaleString("en-US", {weekday: "long"}) // Monday
  dateObject.toLocaleString("en-US", {month: "long"}) // December
  dateObject.toLocaleString("en-US", {day: "numeric"}) // 9
  dateObject.toLocaleString("en-US", {year: "numeric"}) // 2019
  dateObject.toLocaleString("en-US", {hour: "numeric"}) // 10 AM
  dateObject.toLocaleString("en-US", {minute: "numeric"}) // 30
  dateObject.toLocaleString("en-US", {second: "numeric"}) // 15
  dateObject.toLocaleString("en-US", {timeZoneName: "short"}) // 12/9/2019, 10:30:15 AM CST

*/}
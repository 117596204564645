export const AttributeArray = [
    {sensor_function: "PRESENCE",
        "graph-series-attr": "end-state", main_attribute: "occupancy", "true_label": "MOTION", "false_label": "NO MOTION", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "SMOKE",
        main_attribute: "smoke", "graph-series-attr": "end-state", "true_label": "SMOKE", "false_label": "NO SMOKE", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "DOOR",
        "graph-series-attr": "end-state", main_attribute: "contact", "true_label": "CLOSED", "false_label": "OPEN", "unit": "", "true_color": "success", "false_color": "warning", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "WINDOW",
        "graph-series-attr": "end-state", main_attribute: "contact", "true_label": "CLOSED", "false_label": "OPEN", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "FLOOD",
        main_attribute: "water_leak", "graph-series-attr": "end-state", "true_label": "FLOOD", "false_label": "DRY", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "SWITCH",
        main_attribute: "state", "true_label": "ON", "false_label": "OFF", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "BOILER",
        main_attribute: "relay_energy", second_attribute: "relay_power", third_attribute: "relay", "graph-series-attr": "end-start-diff","true_label": "ON", "false_label": "OFF", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},

    {sensor_function: "POWER",
        main_attribute: "relay_energy", second_attribute: "relay_power", third_attribute: "relay", "graph-series-attr": "end-start-diff","true_label": "ON", "false_label": "OFF", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},

    {sensor_function: "EV_CHARGER",
        main_attribute: "relay_energy", second_attribute: "relay_power", third_attribute: "relay", "graph-series-attr": "end-start-diff","true_label": "ON", "false_label": "OFF", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "HVAC",
        main_attribute: "relay_energy", second_attribute: "relay_power", third_attribute: "relay","graph-series-attr": "end-start-diff", "true_label": "ON", "false_label": "OFF", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "LIGHT",
        main_attribute: "state", "true_label": "ON", "false_label": "OFF", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "DIMMER",
        main_attribute: "state", "true_label": "ON", "false_label": "OFF", "unit": "", "true_color": "warning", "false_color": "success", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "NOISE",
        main_attribute: "decibel", percentage_attr: "decibel", "graph-series-attr": "numeral-avg", "true_label": "NOISE", "false_label": "OK", "unit": "dBa", "true_color": "warning", "false_color": "success", "default_graph": "area", "three_d": "True"},
    {sensor_function: "TEMP",
        "graph-series-attr": "numeral-avg", main_attribute: "temperature", "true_label": "temperature", "false_label": "temperature", "unit": "ºC", "true_color": "success", "false_color": "success", "default_graph": "area", "three_d": "False"},
    {sensor_function: "LOCK",
        main_attribute: "state", "true_label": "LOCK", "false_label": "UNLOCK", "unit": "", "true_color": "success", "false_color": "warning", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "PONG",
        main_attribute: "ip", "true_label": "LOCK", "false_label": "UNLOCK", "unit": "", "true_color": "success", "false_color": "warning", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "UPTIME",
        main_attribute: "state", "true_label": "UP", "false_label": "DOWN", "unit": "", "true_color": "success", "false_color": "warning", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "STAY",
        main_attribute: "state", "true_label": "UP", "false_label": "DOWN", "unit": "", "true_color": "success", "false_color": "warning", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "HUMAN_ACTIVITY",
        main_attribute: "human-activity", "true_label": "PRESENCE", "false_label": "EMPTY", "unit": "", "true_color": "success", "false_color": "warning", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "VOLTS_STATUS",
        main_attribute: "electrical-supply", percentage_attr: "ok-ratio","true_label": "SUPPLY OK", "false_label": "SUPPLY DOWN", "unit": "", "true_color": "success", "false_color": "warning", "default_graph": "bool", "three_d": "False"},
    {sensor_function: "WIFI_ACCESS_POINT",
        main_attribute: "state", percentage_attr: "satisfaction", second_attribute: "users", "graph-series-attr": "numeral-avg", third_attribute: "users", "true_label": "ACTIVE", "false_label": "DOWN", "unit": "", "true_color": "success", "false_color": "warning", "default_graph": "area", "three_d": "False"},

]


function GetMainAttr(sensorFunction){
    let rAttr
    AttributeArray.map((attr, idx) => {
        //console.log(attr.sensor_function, sensorFunction)
        if (attr.sensor_function === sensorFunction) {
            //console.log("Main Attribute----->: ", idx, attr.main_attribute)
            rAttr= attr.main_attribute
        }
    })
    //console.log("retorna_ ---->", rAttr)
    return rAttr
}


export function GetAttrObject(sensorFunction){
    let rObject
    AttributeArray.map((attr, idx) => {
        //console.log(attr.sensor_function, sensorFunction)
        if (attr.sensor_function === sensorFunction) {
            //console.log("Main Attribute----->: ", idx, attr.main_attribute)
            rObject= attr
        }
    })
    //console.log("retorna_ ---->", rAttr)
    return rObject
}

export default GetMainAttr

import React from "react";
import {Card, CardBody, CardTitle, Col, Row, FormGroup, UncontrolledTooltip} from "reactstrap";
//import {useState} from 'react';
import axios from "axios";

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/switchs"

function VoltsCardAc({ volts_sensor, device_type, mac_address, unit_uuid }){
    var statusswtich = "",toggleSlidermode = "";
    const statusbatery = volts_sensor['battery-status'];
    const statusbadgestyle  = volts_sensor['attribute']['badge-style'];
    const cardbgstatus = 'bg-status-'+volts_sensor['bg-status'];
    statusswtich = volts_sensor['switch']['switch-status'];
    {statusswtich == 'ON' ? toggleSlidermode = 'toggle-slider-on' : toggleSlidermode = 'toggle-slider-off' }
    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    const putSwitch = async (sensor_mac, command) => {
        const response = await axios.put(API_URL, null,{
            headers: {
                'Content-Type': 'application/json'
            },
            params:
                {
                    "sensor-mac": volts_sensor['sensor-mac'],
                    "gw-mac": "volts00000103",
                    "command": command,
                    "device-type": device_type,
                    "mac-address": mac_address,
                    "sensor-type": volts_sensor['sensor-type']
                }
        })
        return response.data;
    }

    const handleChange = (event) => { 
        if(event.target.checked){
            console.log("CHECKED")
            putSwitch(volts_sensor['sensor-mac'], 1)
            event.target.nextSibling.classList.remove(
                'toggle-slider-on',
                'toggle-slider-off'
              );
              event.target.nextSibling.classList.add( 'toggle-slider-on' );
        }else{
            console.log("UNCHECKED")
            putSwitch(volts_sensor['sensor-mac'], 0)
            event.target.nextSibling.classList.remove(
                'toggle-slider-on',
                'toggle-slider-off'
              );
              event.target.nextSibling.classList.add( 'toggle-slider-off' );
        }
    };
    let sensor_id = "s" + replaceAll(volts_sensor['sensor-mac'],":", "")

    //console.log(sensor_id, volts_sensor['attribute']['friendly-name'],volts_sensor['bg-status'])
    let link_to_sensor = "/admin/sensor/" + volts_sensor['gw-mac']+"/" + unit_uuid + "/" + volts_sensor['sensor-mac'] + "/" + volts_sensor['sensor-function'].toLowerCase()
    return (
        <> 

            <Card className={`card-stats card-option ${'bg-status-'+volts_sensor['bg-status'].toLowerCase()} ${statusbadgestyle=='DISABLED' ? "card-opacity" : ""}`}>
                <a href={link_to_sensor}>


                <CardBody>
                    <Row>

                        <div className="col">
                            <div className={`card-rectangule ${'card-status-battery-'+volts_sensor['attribute']['badge-style']} ${cardbgstatus != 'bg-status-standard'?'card-status-alert-special':''}`}>
                            <CardTitle tag="h5" className={`text-uppercase mb-0 font-weight-bold text-card-status ${'text-card-status-'+volts_sensor['attribute']['badge-style']} ${cardbgstatus != 'bg-status-standard'?'text-white':''}`}>
                            {volts_sensor['attribute']['badge-text']}
                            </CardTitle>
                            </div>
                        </div>
                        <Col className="col-auto col">
                            <div className="icon icon-card">
                            {statusbatery!= 'OK'
                                ?  <span className="batery-status"></span>
                                : ""
                            }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <div className="col">
                        <span id={sensor_id} className="card-font-montserrat h2  mb-0 text-white ">{volts_sensor['attribute']['friendly-name'] + " "}</span>
                        <UncontrolledTooltip
                            delay={0}
                            target={sensor_id}
                        >
                            {volts_sensor['sensor-mac']}

                        </UncontrolledTooltip>
                    </div>
                    <Col className="col-auto custom-toggle-flex">
                        {statusswtich!='DISABLED'
                            ?   <label className='custom-toggle'>
                                    <input onChange={handleChange} defaultChecked = { statusswtich === 'ON' ? 'defaultChecked' : '' } type="checkbox" />
                                    <span className={`${statusswtich === 'ON' ? toggleSlidermode : toggleSlidermode} custom-toggle-slider rounded-circle` } />
                                </label>
                        :""}
                    </Col>
                    </Row>
                    <Row>
                        <div className="col">
                        <p className="mb-0 text-sm">
                                <span className={`mr-2 font-weight-bold card-font-watios ${cardbgstatus=='bg-status-standard' ? "" : "text-white"}`}>
                                    {volts_sensor['attribute']['status-text']}
                                </span>{" "}
                        </p>
                        </div>
                        <Col className="col-auto">
                        <p className={`mb-0 text-sm card-font-montserrat ${cardbgstatus=='bg-status-standard' ? "" : "text-white"}`}>
                        <span className="text-nowrap size-card-montserrat text-white" >{" " + volts_sensor['attribute']['time-text']}</span>
                        </p>
                        </Col>
                    </Row>
                </CardBody>
                </a>
            </Card>
        </>
    );
}
export default VoltsCardAc;

import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from './Accounts';
import {Redirect} from "react-router";

//import {DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, UncontrolledDropdown} from "reactstrap";
//import { useNavigate } from "react-router-dom";


export default () => {
    const [status, setStatus] = useState(false);
    const [session, setSession] = useState(false);
    const { getSession } = useContext(AccountContext);
    const [loggedIn, setLoggedIn] = useState(true);

    useEffect(() => {
        getSession()
            .then(session => {
                //console.log('Session:', session);
                setStatus(true);
                setSession(session)
                setLoggedIn(true)
                //console.log("EMAIL: ", status)
            })

    }, []);



    return (
        <div>
            {/*}
            {
                status && (
                        <div>
                            {session.email}
                        </div>)
            }*/}
            {
                loggedIn === false && (
                    <div>
                        <Redirect to="/auth" replace={true}/>
                    </div>)
            }

        </div>
    );
};
import React from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    FormGroup,
    UncontrolledTooltip,
    UncontrolledDropdown,
    DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import CheckinModal from "./volts-checkin-modal";
import CheckinMessagesModals from "./volts-checkin-messages-modal";
import CheckinLockeventsModals from "./volts-checkin-lockevents-modal";


function CheckinBadgePin({checkin}){
    return (
        <>
            <a>
                <span className="badge badge-success">{checkin["lock-pin"] !== "undefined" ? checkin["lock-pin"] : <></>}</span>
            </a>
        </>
    )
}

function VoltsCheckinDates({checkin}) {
    return (
        <>
            <row>
                <td>
                    <p className={"mb-0 text-sm card-font-montserrat"}>
                        <b>{new Date(checkin["check-in-int"] * 1000).toLocaleString(
                            "es-ES",
                            {
                                month: "short",
                                day: "2-digit"
                            }
                        )
                        }</b>
                    </p>

            <p className={"mb-0 text-sm card-font-montserrat"}>
                {new Date(checkin["check-in-int"] * 1000).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}
            </p>
                </td>
                <td>
                    <p className={"mb-0 ml-2 text-sm card-font-montserrat"}>
                        <b>{new Date(checkin["check-out-int"] * 1000).toLocaleString(
                            "es-ES",
                            {
                                month: "short",
                                day: "2-digit"
                            }
                        )}
                        </b>
                    </p>
            <p className={"mb-0 ml-2 text-sm card-font-montserrat"}>
                {new Date(checkin["check-out-int"] * 1000).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })}
            </p>
                </td>
            {/*}
                                            {new Date(checkin["check-in-int"] * 1000).getMinutes()}
                                            */}

            {/*{new Date(checkin["check-in-int"] * 1000).getTimezoneOffset()}*/}

            </row>

        </>
    )
}

let API_URL = ""
function VoltsCardCheckin({ checkin }){
    var statusswtich = "",toggleSlidermode = "";
    const statusbatery = "OK"
    const statusbadgestyle  = "standard";
    const cardbgstatus = 'bg-status-standard';

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    let badge_text = "NOT-SENT"
    let registered_string = " " + checkin["checkin-guests-checked"]+ "/" + checkin["adults"]

    {/*if (checkin["checkin-link-status"] === "SENT")
    {*/}
        console.log(checkin["checkin-status"])
        switch(checkin["checkin-status"]) {
            case 'NEW':
                badge_text = checkin["checkin-status"] + registered_string
                break
            case 'DRAFT':
                badge_text = checkin["checkin-status"] + registered_string
                break
            case 'FINALIZED':
                badge_text = checkin["checkin-status"] + registered_string
                break
            default:
                badge_text = "NOT-SENT" + registered_string
    }
    {/* }*/}
    console.log("CHECKINNNNN: ", checkin)
    let holder_circle_color = "text-danger"
    if (checkin["checkin-holder"]=== true ){
        holder_circle_color = "text-success"
    }

    return (
        <>
            <Card className={`card-stats card-option bg-status-standard`}>
                <CardBody>
                    <Row>

                        <div className="col">
                            <div className={`card-rectangule ${'card-status-battery-NORMAL'} ${cardbgstatus !== 'bg-status-standard'?'card-status-alert-special':''}`}>
                                <CardTitle id={"badge"+checkin.uuid} tag="h5" className={`text-uppercase mb-0 font-weight-bold text-card-status ${'text-card-status-NORMAL'} ${cardbgstatus !== 'bg-status-standard'?'text-white':''}`}>
                                {badge_text}
                                    <UncontrolledTooltip
                                        delay={0}
                                        target={"badge"+checkin.uuid}
                                    >
                                        {checkin["adults"]}{" Adults "}{checkin["kids"]}{" Kids "}{checkin["infants"]}{" Enfants "}{checkin["pets"]}{" Pets"}
                                    </UncontrolledTooltip>

                                </CardTitle>

                            </div>

                        </div>

                        <Col className="col-auto col">
                            <div className={"justify-right"}>
<td id={"lang"+checkin.uuid} className="mr-2 font-weight-bold card-font-watios text-gray ">
                            {checkin["language"].toLowerCase()}{" "}
                        </td>
                                <td className={"text-sm"}>
                                    <FontAwesomeIcon className={holder_circle_color} icon={icon({name: 'circle-dot', family: 'duotone', style: 'solid', animation: 'beat'})} />
                                    {""}
                                </td>

                                <td className={"mr-2"}>
                                    {/*<VoltsCheckinDropdown />*/}
                                    { badge_text !== "NOT-SENT" ?
                                        <>
                                        <CheckinMessagesModals checkin={checkin}/>
                                        <CheckinLockeventsModals checkin={{"booking-uuid": checkin["uuid"]}} />
                                        </>
                                        :
                                        <></>
                                    }
                                </td>
                                <td className={"mr-2"}>
                                    <CheckinModal json_object={checkin}/>
                                </td>

                            </div>
                        </Col>

                    </Row>

                    <Row>
                    <div className="col">
                        <span id={"uuid"+checkin.uuid} className="card-font-montserrat h2  mb-0 text-white ">
                            {checkin["name"].toUpperCase()}{" "}
                        </span>

                        <UncontrolledTooltip
                            delay={0}
                            target={"uuid"+checkin.uuid}
                        >
                            {checkin["booking-provider"]}{"-"}{checkin["channel"]}{"-"}{checkin["booking-reference"]}
                        </UncontrolledTooltip>
                    </div>
                    <Col className="col-auto custom-toggle-flex">
                        {/*}
                        {statusswtich!='DISABLED'
                            ?   <label className='custom-toggle'>
                                    <input onChange={handleChange} defaultChecked = { statusswtich === 'ON' ? 'defaultChecked' : '' } type="checkbox" />
                                    <span className={`${statusswtich === 'ON' ? toggleSlidermode : toggleSlidermode} custom-toggle-slider rounded-circle` } />
                                </label>
                        :""}
                        */}
                    </Col>
                    </Row>
                    <Row>
                        <div className="col">
                        <p className="mb-0 text-sm">
                                <span className={`mr-2 font-weight-bold card-font-watios ${cardbgstatus=='bg-status-standard' ? "" : "text-white"}`}>
                                    {checkin["unit-friendlyname"]}
                                </span>{" "}
                        </p>


                        </div>
                        <Col className="col-auto">

                            <td>
                                {/*<CheckinBadgePin checkin={checkin}/>*/}
                                <VoltsCheckinDates checkin={checkin} />
                            </td>

                            <p className={`mb-0 text-sm card-font-montserrat ${cardbgstatus=='bg-status-standard' ? "" : "text-white"}`}>
                                <span id={"status"+checkin.uuid} className="text-nowrap size-card-montserrat text-white" >{checkin["status-label"].toUpperCase()}</span>
                            </p>
                            <UncontrolledTooltip
                                delay={0}
                                target={"status"+checkin.uuid}
                            >

                                <b>{checkin["booking-provider"]}{" -> "}</b>{checkin["provider-status"]}

                            </UncontrolledTooltip>
                        </Col>
                    </Row>
                </CardBody>

            </Card>
        </>
    );
}

export default VoltsCardCheckin;

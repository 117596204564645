import {Container, Badge, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import VoltsCard from "./voltsCardProd";
import axios from "axios"
import React, { useRef, useState, useEffect } from "react";

let events_items = [];

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/events/states";


async function axios_events(unit) {
    const response = await axios.get(API_URL, {
        params:{
            'gw-mac': unit,
            'scope': "latest-states",
            'timestamp-offset': '1660151069.239336',
            'items': 50
        }
    })
    console.log("AXIOS events CARDS", response)
    return response.data
}


function set_events(data) {
    events_items = data
    console.log("SET CARDS", events_items)
    return events_items
}


function VoltsSensorCards({ unit }) {
    const [events, setEvents] = React.useState(null);
    const [loaded, setLoaded] = React.useState(null);
    //console.log("HEllo CARDS")
    useEffect(() => {
        const loadData = async () => {
            const data = await axios_events(unit);
            setEvents(data);
            set_events(data);
            //console.log("CARDS", data)
            setLoaded(true);

        };
        setTimeout(() => {
            loadData();
        }, 300);
    }, []);
    console.log("EVENTS: ", events)
    return (
        <>
            {/*<div className="header bg-white pb-0">*/}
            {
                loaded ?
                    <Card>
                        <CardHeader>
                            {/*<h5 className="h3 mb-0">Sensors in {events.unit.friendlyname}</h5>*/}
                        </CardHeader>
                        <CardBody>
                    <Container>
                            <Row>
                                <Col>
                                    {events['cards'].map((event) => (
                                        <div  key={event['sensor-mac']}>
                                            <VoltsCard volts_sensor={event}/>
                                        </div>)
                                    )}
                                </Col>
                            </Row>
                    </Container>
                        </CardBody>
                    </Card>
                    : "Loading...."
            }
        </>
    );
}


export default VoltsSensorCards;
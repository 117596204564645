import React, { useEffect } from "react";
import ReactJson from 'react-json-view'
import {

    Card,
    CardBody,
    CardHeader,
    Col

} from "reactstrap";


function VoltsBaseCardJson({json_object}) {

    useEffect(() => {

        setTimeout(() => {

        }, 300);
    }, []);
    //console.log(data_items)
    return (
        <>
            <Col xl="14">
                <Card>
                    <div>
                        <CardHeader>
                            <h6 className="surtitle">Atributos</h6>
                            <h5 className="h3 mb-0">Sensor</h5>
                        </CardHeader>

                        <CardBody>
                            <div>
                                <ReactJson src={json_object} />
                            </div>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        </>
    );
}

export default VoltsBaseCardJson;
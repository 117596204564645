import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../../UserPool";

const  AccountContext = createContext();
const Account = props => {
    const getSession = async () =>
        await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            //console.log("ACCOUNT---getSession", user)
            if (user) {
                user.getSession(async (err, session) => {
                    if (err) {
                        reject();
                    } else {
                        const attributes = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                                if (err) {
                                    reject(err);
                                } else {
                                    const results = {};

                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute;
                                        results[Name] = Value;
                                    }

                                    resolve(results);
                                }
                            });
                        });

                        resolve({
                            user,
                            ...session,
                            ...attributes
                        });
                    }
                });
            } else {
                reject();
            }
        });

    const authenticate = async (Username, Password) =>
        await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });
            const authDetails = new AuthenticationDetails({ Username, Password });

            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    //console.log("onSuccess:", data);
                    resolve(data);
                },

                onFailure: err => {
                    //console.error("onFailure:", err);
                    reject(err);
                },

                newPasswordRequired: data => {
                    data.newPasswordRequired = true
                    //console.log("newPasswordRequired:", data);
                    //console.log("DETAILS", authDetails)
                    //resolve(authDetails);
                    resolve(data);

                }
            });
        });

    const changePw = () => {
        const user = Pool.getCurrentUser();
        //var cognitoUser = result.user;
        //console.log("ChangePassword",user)
        if (user) {
            user.changePassword("1PLsaFh?", "amperio!Faradio");
        }
        //console.log("changepw2",user)
    };

    const logout = () => {
        const user = Pool.getCurrentUser();
        //console.log("LOGOUT",user)
        if (user) {
            user.signOut();
        }
        //console.log("LOGOUT2",user)
    };

    return (
        <AccountContext.Provider
            value={{
                authenticate,
                getSession,
                logout,
                changePw
            }}
        >
            {props.children}
        </AccountContext.Provider>
    );
};

export { Account, AccountContext };
import {
    //CardFooter,
    //DropdownMenu,
    //DropdownItem,
    //DropdownToggle,
    //UncontrolledDropdown,
    //Media,
    //Pagination,
    //PaginationItem,
    //PaginationLink,
    //Progress,
    ///Table,
    //Container,
    //Row,
    //UncontrolledTooltip,Badge,
    Card,
    CardBody,
    CardHeader,
    //Col,
    //ListGroup,
    //ListGroupItem
} from "reactstrap";
//import axios from "axios"
import React, { useState, useEffect, ReactDOM } from "react";
//import moment from "moment";
import Chart from "react-apexcharts";
//let data_items = []
//const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/events/states";


const MyCharts = ({title, data_series_par, data_series_name, data_series_unit}) => {
    //const [averageTemp, setAverageTemp] = useState([]);
    const [date, setDate] = useState([]);


    useEffect(() => {
    }, []);
    const series = [ //data on the y-axis
        {
            name: data_series_name,
            data: data_series_par
        }];
    const options = { //data on the x-axis
        colors:['#00e7bd'],
        chart: {
            id: 'area-chart',
            type: 'area',
            height: 350,
            zoom: {
                autoScaleYaxis: true
            }},
        plotOptions: {
            bar: {
                borderRadius: 1,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + "" + data_series_unit;
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },


        yaxis: {
            labels: {
                show: true,
                formatter: function(val) {return val + data_series_unit}
            },
            title: {
                text: data_series_name
            },
        },
        stroke: {curve: 'stepline'},

        xaxis: {
            categories: date,
            type: 'datetime',
            //min: new Date('1 JAN 2022').getTime(),
            tickAmount: 6,
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0.2,
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 500, 1000]
                },
            },
        },
        tooltip: {
            x: {
                format: 'dd MMM yy HH:MM:ss'
            }
        },
    }

    //console.log("XAverageTemp:", series)
    //console.log("dataseriespar: ", data_series_par)

    return (
        <div key={'g-'+ data_series_name}>
            <Chart
                options={options}
                series={series}
                type="area"
                width="700"
                height="200"
            />
        </div>
    )
}


function VoltsNumeralGraphCard({ data_series }) {

    {/*const [data_items, setDataItems] = React.useState(null);*/}
    const [loaded, setLoaded] = React.useState(true);
    //const domContainer = document.querySelector('#app');
    {/*}
    useEffect(() => {

        const loadData = async () => {
            const data = await axios_data();
            setDataItems(data);
            setLoaded(true);
            //console.log("LOAD DATA", data)
        };
        setTimeout(() => {
            loadData();
        }, 300);
    }, []);

    */}
    //console.log("GRAPH DATA_ITEMS:", data_items)
    console.log("DATA SERIES", data_series)

    return (
        <>
            {/*<Col xl="24">*/}
            <Card>
                {/*<div>*/}
                <CardHeader>
                    {
                        loaded ? <>
                                <h6 className="surtitle">{"relay_series['unit']['address']"}</h6>
                                <h5 className="h3 mb-0">{"relay_series['unit']['friendlyname']"}</h5></>
                            :
                            <></>}
                </CardHeader>
                <CardBody>
                    <div>
                        { loaded ?
                            <div>
                                {data_series['graphs']['series'].map((graph) => (
                                    <MyCharts data_series_name={graph['name']} data_series_par={graph['data']} data_series_unit={"graph['unit']"} title={"kkkkkkk"} />
                                ))}
                            </div>
                            : <></>}
                    </div>
                </CardBody>
                {/*}</div>*/}
            </Card>
            {/*}</Col>*/}
        </>
    );
}

export default VoltsNumeralGraphCard;


{/*
const getData = async () => {
            const url = 'http://localhost:8000/temperature';
            try {
                const response = await fetch(url);
                const data = await response.json();
                console.log("GraphData", data);
                setAverageTemp(data?.map((item) => item.average_temp));
                setDate(data?.map((item) => item.date));
            } catch (error) {
                console.log(error);
            }
        };

        //getData();
*/}

{/*heat map
options = {
  plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [{
              from: -30,
              to: 5,
              color: '#00A100',
              name: 'low',
            },
            {
              from: 6,
              to: 20,
              color: '#128FD9',
              name: 'medium',
            },
            {
              from: 21,
              to: 45,
              color: '#FFB200',
              name: 'high',
            }
          ]
        }
      }
    }
  series: [
    {
      name: "Series 1",
      data: [{
        x: 'W1',
        y: 22
      }, {
        x: 'W2',
        y: 29
      }, {
        x: 'W3',
        y: 13
      }, {
        x: 'W4',
        y: 32
      }]
    },
    {
      name: "Series 2",
      data: [{
        x: 'W1',
        y: 43
      }, {
        x: 'W2',
        y: 43
      }, {
        x: 'W3',
        y: 43
      }, {
        x: 'W4',
        y: 43
      }]
    }
  ]
}

*/}

{/*
  [
                [1327359600000,30.95],
                [1327446000000,31.34],
                [1327532400000,31.18],
                [1327618800000,31.05],
                [1327878000000,31.00],
        ]

*/}
//import Alternative from "views/pages/dashboards/Alternative.js";
import VoltsDash from "views/pages/dashboards/volts-dash.js";
import VoltsDashCheckins from "views/pages/dashboards/volts-dash-checkins.js";
//import Socket from "views/pages/volts/volts-socket-cards.js";
import Buttons from "views/pages/components/Buttons.js";
import VoltsBooking from "./views/pages/volts/volts-booking";
//import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Cardsac from "views/pages/components/cardsac.js";
//import Charts from "views/pages/Charts-power.js";
import Components from "views/pages/forms/Components.js";
//import Dashboard from "views/pages/dashboards/Dashboard.js";
import Sensor from "views/pages/volts/volts-sensor.js";
import BookingDetails from "views/pages/volts/volts-booking-details.js"
import Bookings from "views/pages/volts/volts-booking"
import Incidents from "views/pages/volts/volts-incidents.js";
import Actions from "views/pages/volts/volts-actions.js";
import Unit from "views/pages/volts/volts-unit.js";
import Login from "views/pages/examples/Login.js";
import voltsGoogle from "./views/pages/volts/voltsGoogle";
import SocketPower from "./views/pages/volts/volts-socket-power-graph";
import VoltsDashBase from "./views/pages/volts/volts-dash-base";
import VoltsDashMaindoor from "./views/pages/volts/volts-dash-maindoor"
import VoltsDashSwitchbox from "./views/pages/volts/volts-dash-switchbox"
import Elements from "views/pages/forms/Elements.js";
//import Mapview from "views/pages/maps/Mapview.js";
import Google from "views/pages/maps/Google.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/examples/Lock.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "views/pages/examples/Register.js";
//import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Vector from "views/pages/maps/Vector.js";
//import Widgets from "views/pages/Widgets.js";


const routes = [
  {
    path: "/dash",
    name: "Mis Propiedades",
    icon: "ni ni-building text-default",
    miniName: "P",
    component: VoltsDash,
    layout: "/admin",
  },
  {
    path: "/checkins",
    name: "Mis Checkins",
    icon: "ni ni-building text-default",
    miniName: "P",
    component: VoltsDashCheckins,
    layout: "/admin",
  },
  {
    path: "/key/:lock/:sync",
    name: "My Key",
    icon: "ni ni-building text-default",
    miniName: "P",
    component: Icons,
    layout: "/auth",
  },
  {
    collapse: true,
    name: "Bookings",
    icon: "ni ni-world-2 text-danger",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/dashboard/:id/dash",
        name: "Dash Test",
        miniName: "D",
        component: VoltsDashBase,
        layout: "/admin",
      },
      {
        path: "/dashboard/:id/maindoor",
        name: "Dash Maindoor",
        miniName: "MD",
        component: VoltsDashMaindoor,
        layout: "/admin",
      },
      {
        path: "/dashboard/:id/switchbox",
        name: "Dash Switchbox",
        miniName: "SW",
        component: VoltsDashSwitchbox,
        layout: "/admin",
      },
      {
        path: "/booking/:gw_mac/:unit_uuid",
        name: "Unit Bookings",
        miniName: "BK",
        component: VoltsBooking,
        layout: "/admin",
      },
      {
        path: "/:gw_mac/:booking_uuid/booking",
        name: "Booking",
        miniName: "Bk",
        component: BookingDetails,
        layout: "/admin",
      },
      {
        path: "/dashboardac",
        name: "Check-Ins Hoy",
        miniName: "A",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/dashboardac",
        name: "Check-Outs Hoy",
        miniName: "A",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/dashboardac",
        name: "Active Bookings",
        miniName: "A",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/dashboardac",
        name: "Future Bookings",
        miniName: "A",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/dashboardac",
        name: "Past Bookings",
        miniName: "P",
        component: Login,
        layout: "/auth",
      }
    ],
  },
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-building text-default",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/dashboard",
        name: "List view",
        miniName: "D",
        component: VoltsDash,
        layout: "/admin",
      },
      {
        path: "/mapview",
        name: "Map view",
        miniName: "G",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/sensor/:gw_mac/:unit_uuid/:sensor_mac/:sensor_function",
        name: "Sensor",
        miniName: "S",
        component: Sensor,
        layout: "/admin",
      },
      {
        path: "/bookings/:gw_mac/:unit_uuid",
        name: "Bookings",
        miniName: "S",
        component: Bookings,
        layout: "/admin",
      },
      {
        path: "/actions/:gw_mac/:sensor_mac",
        name: "Actions",
        miniName: "A",
        component: Actions,
        layout: "/admin",
      },
      {
        path: "/incidents/:gw_mac/:sensor_mac",
        name: "Incidents",
        miniName: "I",
        component: Incidents,
        layout: "/admin",
      },
      {
        path: "/unit/:id",
        name: "Unit",
        miniName: "U",
        component: Unit,
        layout: "/admin",
      },
      {
        path: "/space/:id/:space",
        name: "Space",
        miniName: "S",
        component: Unit,
        layout: "/admin",
      },
      {/*
      {
        path: "/socketcard",
        name: "Socket",
        miniName: "S",
        component: Socket,
        layout: "/admin",
      },*/},
      {
        path: "/socketpower",
        name: "SocketPower",
        miniName: "P",
        component: SocketPower,
        layout: "/auth",
      },{/*},
      {
        path: "/voltsmap",
        name: "volts Map",
        miniName: "M",
        component: voltsGoogle,
        layout: "/admin",
      }*/}
    ],
  },
  {
    collapse: true,
    name: "Incidencias",
    icon: "ni ni-bell-55 text-danger",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Critical",
        miniName: "C",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Warning",
        miniName: "W",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Info",
        miniName: "I",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/timeline",
        name: "Timeline",
        miniName: "T",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Login,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "Check-INs",
    icon: "ni ni ni-bold-right text-primary",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        miniName: "B",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/cards",
        name: "Cards",
        miniName: "C",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/grid",
        name: "Grid",
        miniName: "G",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/notifications",
        name: "Notifications",
        miniName: "N",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/icons",
        name: "Icons",
        miniName: "I",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/typography",
        name: "Typography",
        miniName: "T",
        component: Login,
        layout: "/auth",
      },
      {
        collapse: true,
        name: "Multi Level",
        miniName: "M",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/auth",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/auth",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/auth",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Check-OUTs",
    icon: "ni ni-bold-left text-primary",
    state: "formsCollapse",
    views: [
      {
        path: "/elements",
        name: "Elements",
        miniName: "E",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/components",
        name: "Components",
        miniName: "C",
        component: Components,
        layout: "/auth",
      },
      {
        path: "/validation",
        name: "Validation",
        miniName: "V",
        component: Validation,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "Mantenimiento",
    icon: "ni ni-settings text-default",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Tables",
        miniName: "T",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/sortable",
        name: "Sortable",
        miniName: "S",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/react-bs-table",
        name: "React BS Tables",
        miniName: "RBT",
        component: Login,
        layout: "/auth",
      },
    ],
  }, {/*},
  {
    path: "/widgets",
    name: "Widgets",
    icon: "ni ni-archive-2 text-green",
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "ni ni-chart-pie-35 text-info",
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/admin",
  },*/}
];

export default routes;

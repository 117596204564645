import React, { useState, useEffect } from "react";
import {Card, CardBody, Row, Col, CardHeader, Progress, Button, Modal, Form, Input} from "reactstrap";
import axios from "axios";
import {Line} from "react-chartjs-2";
import {chartExample3} from "../../variables/charts";
import {useParams} from "react-router-dom";
import VoltsBookingSortable from "../../views/pages/tables/voltsBookingSortable";


let booking_items = [];


const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/bookings";
const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
    'Content-Type': 'application/json',
    "Accept": "application/json",
    'x-api-key': VOLTS_API_KEY
};


async function axios_booking(unit) {
    console.log("AX BOOKING PRE: ", unit)
    const response = await axios.get(API_URL, {params:{
            'gw-mac': unit['gw-mac']}})
    console.log("AXIOS BOOKING", response, "PAR  ")
    console.log({params:{
            'gw-mac': unit['gw.mac']}})
    return response.data
}


function set_bookings(data) {
    booking_items = data
    console.log("SET BOOKING", booking_items)
    return booking_items
}


function Progresses ({unit}) {
    class Modals extends React.Component {

        state = {
            exampleModal: false
        };
        toggleModal = state => {
            this.setState({
                [state]: !this.state[state]
            });
        };

        handleSubmit(event)
        {
            event.preventDefault()
            console.log('EVENT FORM', event)
            console.log(put_booking(event))
            //console.log("STATE_VALUE", this.state.value)

        }
        render() {
            return (
                <>
                <div className={"text-right"} >
                    <Button
                        color="primary"
                        className={"my-4"}
                        type="button"
                        onClick={() => this.toggleModal("formModal")}
                    >
                        Crear Booking
                    </Button>
                </div>
                    <Modal
                        className="modal-xl"
                        size="sm"
                        isOpen={this.state.formModal}
                        toggle={() => this.toggleModal("formModal")}
                    >
                        <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent pb-5">
                                    {/*<div className="text-muted text-center mt-2 mb-3">
                      <small>Create New Booking</small>
                    </div>*/}
                                    <h5 className="h3 mb-0">Crear Booking</h5>
                                    {/*<div className="btn-wrapper text-center">
                    </div>*/}
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={() => this.toggleModal("formModal")}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>
                                </CardHeader>
                                <CardBody className="px-lg-5 py-lg-5">
                                    {/*<div className="text-center text-muted mb-2">
                      <medium>Create</medium>
                    </div>*/}
                                    <Form role="form"
                                          onSubmit={this.handleSubmit
                                            }>

                                        <label
                                            className="form-control-label"
                                            htmlFor="example-search-input"
                                        >
                                            Check-In Date
                                        </label>
                                        <Input
                                            defaultValue={new Date().getFullYear() + "2023-4-26T11:00:00"}
                                            id="checkin-datetime-local-input"
                                            type="datetime-local"
                                        />

                                        <label
                                            className="form-control-label"
                                            htmlFor="example-search-input"
                                        >
                                            Check-Out Date
                                        </label>
                                        <Input
                                            defaultValue={new Date().getFullYear() + "2023-4-26T15:00:00"}
                                            id="checkout-datetime-local-input"
                                            type="datetime-local"
                                        />
                                        <label
                                            className="form-control-label"
                                            htmlFor="example-search-input"
                                        >
                                            Pax Total
                                        </label>
                                        <Input
                                            defaultValue={"2"}
                                            id="pax-input"
                                            type="number"
                                        />
                                        <div className="custom-control custom-control-alternative custom-checkbox">

                                            <input
                                                className="custom-control-input"
                                                id="child-check"
                                                type="checkbox"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="child-check"
                                            >
                                                <span className="text-muted">Have Kids?</span>
                                            </label>
                                        </div>
                                        <label
                                            className="form-control-label"
                                            htmlFor="reference-input"
                                        >
                                            Booking Reference
                                        </label>
                                        <Input
                                            defaultValue={"#"}
                                            id="reference-input"
                                            type="text"
                                        />

                                        <div className="text-right">
                                            <Button
                                                color="secondary"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={() => this.toggleModal("formModal")}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="my-4"
                                                color="primary"
                                                type="submit" value="Submit"
                                                onClick={() => this.toggleModal("formModal")}

                                            >
                                                Create Booking
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Modal>

                </>
            );
        }
    }


    async function put_booking(event) {
        const axios_config = {'headers': api_headers}
        const booking = {
            'gw-mac': unit['gw-mac'],
            'unit-uuid': unit['uuid'],
            'check-in': event.target[0].value,
            'check-out': event.target[1].value,
            'pax-total': event.target[2].value,
            'kids':event.target[3].value,
            'booking-reference': event.target[4].value,
            'booking-provider': "volts-app"
            //'country-origin': ,
            //'booking-provider':,
            //'status':
            //'language':
        }
        console.log("PUT_BOOKING headers", api_headers)
        console.log(event)
        console.log(booking)
        const res = await axios.put('https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/bookings', booking, axios_config);
        console.log("BOOKING: ->>>", res)
        return (res)
    }


    const { id } = useParams();
    const [booking, setBookings] = React.useState(null);
    const [loaded, setLoaded] = React.useState(null);
    useEffect(() => {
    const loadData = async () => {
        console.log("PARAMS----> UUU", unit)
        const data = await axios_booking(unit);
        setBookings(data);
        set_bookings(data);
        console.log("BOOKINGs", data)
        setLoaded(true);
    };
    setTimeout(() => {
    loadData();
    }, 300);
    }, []);
    console.log("HEllo BOOKINGS", unit, booking_items)
    return (
            <>
                {loaded && typeof(booking_items['active_stay']) !== 'undefined' ?
                    <Card>
                        <CardHeader>
                            <Row>
                            <Col>
                                <h5 className="h3 mb-0">Stay Progress</h5>
                            </Col>
                                {/*<Col>
                                    <div className="progress-label">
                                        <span>{booking_items['active_stay']['status']}</span>
                                    </div>
                                </Col>*/}
                            <Col className={"text-right"}>
                                <small className="text-muted">
                                    <i className="fas fa-clock mr-1"/>{booking_items['active_stay']['time-to-checkout']}
                                </small>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <div className="progress-wrapper">
                                        <div className="progress-info">
                                            <div className="progress-label">
                                                <span>{booking_items['active_stay']['status']}</span>
                                            </div>
                                            <div className="progress-percentage">
                                                <span>{booking_items['active_stay']['progress']}%</span>
                                            </div>
                                        </div>
                                        <Progress max="100" value={booking_items['active_stay']['progress']} color="default"/>
                                    </div>
                                    <h5 className="h5 mb-0">Started on {booking_items['active_stay']['check-in-datetime']}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <div >
                                    <Modals />
                                </div>
                                {/*
                                {loaded  ? <VoltsBookingSortable bookings={booking_items.bookings}/> : <></>}
                                */}
                            </Row>
                        </CardBody>
                    </Card>
                    :
                    <Card>

                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5 className="h3 mb-0">Sin reservas activas</h5>
                                </Col>

                                {/*<Col>
                                    <div className="progress-label">
                                        <span>{booking_items['active_stay']['status']}</span>
                                    </div>
                                </Col>*/}

                                <Col className={"text-right"}>
                                    <small className="text-muted">
                                        {/*<i className="fas fa-clock mr-1"/>{booking_items['active_stay']['time-to-checkout']}*/}
                                    </small>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <div >
                                    <Modals />
                                </div>
                                {/*
                                {loaded  ? <VoltsBookingSortable bookings={booking_items.bookings}/> : <></>}
                                */}
                            </Row>

                        </CardBody>

                    </Card>
                }
            </>
        );
    }

export default Progresses;
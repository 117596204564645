import Chart from "react-apexcharts";

const slugify = str =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');


const graphType = 'area'


function VoltsAreaCard({series, measureu}) {
    const options = { //data on the x-axis
        colors:['#00e7bd'],
        chart: {
            id: slugify(series[0].name),
            type: graphType,
            //height: 350,
            zoom: {
                autoScaleYaxis: true
            }},
        plotOptions: {
            bar: {
                borderRadius: 1,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return (Math.round(val * 100) / 100 ) + measureu;
            },
            offsetY: -2,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },

        yaxis: {
            //min: 20,
            //max: 90,
            labels: {
                show: false,
                formatter: function(val) {return (Math.round(val * 100) / 100 ) +  measureu}
            },
            //title: {
            //    text: title
            //},
        },
        stroke: {curve: 'smooth'},

        xaxis: {
            //categories: date,
            type: 'datetime',
            //min: new Date('1 JAN 2022').getTime(),
            tickAmount: 6,
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 0.2,
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 500, 1000]
                },
            },
        },
        tooltip: {
            x: {
                format: 'dd MMM yy HH:MM:ss'
            }
        },
    }
    console.log("Series en area graph: ", series, measureu)
return(

    <Chart
        options={options}
        series={series}
        type={graphType}
        measureu={measureu}
        //width="300"
        //height="150"
    />

)}


export default VoltsAreaCard
import {Badge, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem} from "reactstrap";
import axios from "axios"
import React, { useState, useEffect, ReactDOM } from "react";
import {Line, Bar, Bubble, HorizontalBar} from "react-chartjs-2";
import ApexChart from "./apex_test"
import ApexCharts from 'apexcharts'
import ReactApexChart from 'apexcharts'
import moment from "moment";
import { Component } from "react";
import Chart from "react-apexcharts";
import Chart1 from "../../views/pages/volts/mycharts";



let line_graph_items = [];

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/events/states";

var colors = {
    gray: {
        100: "#f6f9fc",
        200: "#e9ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#8898aa",
        700: "#525f7f",
        800: "#32325d",
        900: "#212529",
    },
    theme: {
        default: "#172b4d",
        primary: "#5e72e4",
        secondary: "#f4f5f7",
        info: "#11cdef",
        success: "#2dce89",
        danger: "#f5365c",
        warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
};




async function axios_line_graph() {
    const response = await axios.get(API_URL, {
        params:{
            'gw-mac': "volts40000000",
            'sensor-mac': 'Xzivolts40000000',
            'timestamp-offset': (moment().unix() -3600 ) + ".0",
            'timestamp-end': (moment().unix() )+ ".0",
            'attributes': 'decibel',
            'format': 'line-graph',
            'items': 90,
            'frequency': 600
        }
    })
    console.log("AXIOS line graph", response)
    return response.data
}


function set_line_graph(data) {
    line_graph_items = data
    console.log("SET line graph", line_graph_items)
    return line_graph_items
}


function VoltsNoiseGraph() {
    const [graph, setGraph] = React.useState(null);
    const [loaded, setLoaded] = React.useState(null);
    const domContainer = document.querySelector('#app');
    const graph_options = {
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: colors.gray[200],
                        zeroLineColor: colors.gray[700],
                    },
                    ticks: {},
                },
            ],
        },
    }
    console.log("HEllo NOISE Graph")
    useEffect(() => {
        const loadData = async () => {
            const data = await axios_line_graph();
            setGraph(data);
            set_line_graph(data);
            console.log("GRAPH", data)
            setLoaded(true);

        };
        setTimeout(() => {
            loadData();
        }, 300);
    }, []);
    console.log("NOISE EVENTS: ", graph)
    //ReactDOM.render(React.createElement(ApexChart), domContainer);
    let unixDate = moment().unix()
    console.log("unix date",moment().unix() + ".0")

    return (
        <>
                    <Col xl="14">

                        {/*<ApexChart />
                        <ApexChart />*/}
                        <Chart1 />
                        {loaded ?
                            <Card>
                                <div>
                                    <CardHeader>
                                        <h6 className="surtitle">{graph.sensor['friendly-name']}</h6>
                                        <h5 className="h3 mb-0">{graph.graph.data.datasets[0].label} in {graph.sensor['sensor-location']}</h5>
                                    </CardHeader>
                            <CardBody>
                                <div>
                                    Alerts
                                    800 dB
                                </div>
                                <div className="chart">
                                    <Line
                                        data={graph.graph.data}
                                        options={graph_options}
                                        id="chart-line"
                                        className="chart-canvas"
                                    />
                                </div>
                            </CardBody>
                </div>
                        </Card>

                    : "Loading...."
            }
                    </Col>
        </>
    );
}

export default VoltsNoiseGraph;
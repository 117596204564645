import React from "react";
import {useParams} from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from "react";
//import {func} from "prop-types";
import axios from "axios"
import moment from "moment";
import {
  //Button,
  //Card,
  //CardHeader,
  //CardBody,
  //Form,
  //Input,
  Container,
  Row,
  Col,
  //Modal,
} from "reactstrap";

import VoltsBaseCardJson from "../../../components/VoltsCards/volts-base-cards/volts-base-card-json";
import VoltsTemperatureGauge from "./volts-temperature-gauge";
import CardsHeaderSensor from "components/Headers/CardsHeaderSensor.js";
import VoltsSensorSortable from "../tables/voltsSensorSortableContact";
import VoltsCardsCard from "../../../components/VoltsCards/volts-voltscards-card";
import VoltsSensorSpecCard from "../../../components/VoltsCards/volts-sensor-specs-card";
import VoltsSensorLatestStateCard from "../../../components/VoltsCards/volts-sensor-latest-state-card";
import VoltsSwitchboxCard from "../../../components/VoltsCards/volts-sensor-graph-card";
import VoltsSensorNumeralGraph from "../../../components/VoltsCards/volts-sensor-graph-card-numeral"
import GetMainAttr from "../../../variables/attributeArray"
import {GetAttrObject} from "../../../variables/attributeArray";
//import Datepicker from "../forms/voltsDatePicker";
import BodyCardSeriesGraph from "../../../components/VoltsCards/volts-base-cards/volts-body-card-series-graph";
import Modals from "../../../components/VoltsCards/volts-base-cards/volts-base-modal-json"


{/*}
import {
  chartOptions,
  parseOptions,
  //chartExample1,
  //chartExample2, chartExample3,
} from "variables/charts.js";
*/}

{/*
import { Chart } from "chart.js";
*/}


//import Progresses from "../../../components/VoltsCards/Volts-Booking-Progress";
//import ListUnits from "../../../components/VoltsCards/ListUnits";
//import VoltsToDo from "../../../components/VoltsCards/volts-to-do";
//import VoltsMainDoor from "../../../components/VoltsCards/volts-alerts"
//import VoltsEvents from "../../../components/VoltsCards/volts-events";
//import VoltsSensorCards from "../../../components/VoltsCards/volts-sensor-cards";
//import VoltsNoiseGraph from "../../../components/VoltsCards/volts-noise-graph";
//import VoltsMapCard from "../../../components/VoltsCards/volts-map-card";
//import Socket from "./volts-socket-cards";
//import VoltsBar from "../../../components/VoltsCards/volts-bar"
//import ActivitiesGyg from "../../../components/VoltsCards/volts-activities";
//import CardSimple from "../../../components/VoltsCards/CardSimple";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
//import { Line, Bar } from "react-chartjs-2";


let sensor_item = {};
let graph_data = {};


async function axios_graph(unit_uuid, sensor_mac, gw_mac, functionality, timestamp_offset, timestamp_end) {
  //let json_test = {attributes: ["temperature", "occupancy"]}
  const response = await axios.get(
      "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/dashboards/switchbox/relay-graph",
      {params:{
          'gw-mac': gw_mac,
          'sensor-list': sensor_mac,
          'unit-uuid': unit_uuid,
          'graph-attribute': GetMainAttr(functionality),
          'power-sensor-types': 'ZIGBEE',
        //'timestamp-offset': (moment().unix() - 28800) + ".0",
        //'timestamp-end': (moment().unix())+ ".0",
          'timestamp-offset': timestamp_offset,
          'timestamp-end': timestamp_end,
        //'attributes': JSON.stringify(json_test)
      //'timestamp-offset':'1671397069.396703',
       // 'timestamp-end': '1671483469.397184'
    }})
  //console.log("AXIOS graph", response)
  //console.log("%%%%%%%%%%", functionality)
  return response.data
}


async function axios_sensor(unit_uuid, sensor_mac, gw_mac) {
  const response = await axios.get("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/sensors", {params:{
        'gw-mac': gw_mac,
        'sensor-mac': sensor_mac,
        'unit-uuid': unit_uuid
  }})
  //console.log("AXIOS RESPONSE SENSOR ",response)
  return response.data
}


function set_graph_data(datar) {
  graph_data = datar
  console.log("GRAPH DATA: ", graph_data)
  return graph_data
}


function set_sensor(data) {
  sensor_item = data
  console.log("SET SENSOR", sensor_item)
  return sensor_item
}


const VOLTS_API_KEY = "ePPjGErRhN9s5NfNbOEF4y9d75tls7q4vXkNCwc7"
const api_headers = {
  'Content-Type': 'application/json',
  'x-api-key': VOLTS_API_KEY
};


function VoltsSensor() {

  //let param = useParams();
  const [sensor, setSensor] = React.useState(null);
  const [graphData, setgraphData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const [loadedr, setLoadedr] = React.useState(null);
  const [mainAttr, setmainAttr] = React.useState(null);
  const [mainAttrObject, setmainAttrObject] = React.useState(null);
  const [timestampOffset, settimestampOffset] = React.useState((moment().unix() - 86400));
  const [timestampEnd, settimestampEnd] = React.useState((moment().unix()));
  const thirdListRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

  //PARAMETROS PASADOS EN URL
  const { unit_uuid } = useParams();
  const { sensor_mac } = useParams();
  const { gw_mac } = useParams(); // gw-mac
  const { sensor_function } = useParams();


  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };


  {/*}
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  */}


  useEffect(() => {

    const loadSensorGraph = async () => {
      //console.log("TIMES: " , timestampOffset, timestampEnd)
      const datar = await axios_graph(
          unit_uuid,
          sensor_mac,
          gw_mac,
          sensor_function.toUpperCase(),
          timestampOffset,
          timestampEnd
      );
      console.log("SENSORGRPAH--->", datar)
      setgraphData(datar)
      set_graph_data(datar)
      setLoadedr(true)
    };


    const loadSensorData = async () => {

      const data = await axios_sensor(unit_uuid, sensor_mac, gw_mac);
      console.log("dddddddddATA: ", data)
      setSensor(data);
      set_sensor(data);
      setLoaded(true);
      setmainAttr(GetMainAttr(data['sensor']['sensor-function']));
      setmainAttrObject(GetAttrObject(data['sensor']['sensor-function']))
      console.log("MAINATTR", mainAttr)

    };
    setTimeout(() => {
      loadSensorData();
      loadSensorGraph();
    }, 1);
  },[]);


  {/*let sensorf = sensor_function*/}

  //console.log ("SSSENNSOR: ", sensorf, sensor_function)
  //console.log("sensor-->", sensor)
  return (
      <>

        { loaded ? <CardsHeaderSensor
            className="mb-3"
            unit={sensor.unit}
        /> : <></>}

        <Container className="mt--5" fluid>
          <Row>
            <Col xl="8">
              {/*<Datepicker />
              { loadedr ?
                  <VoltsSwitchboxCard
                      data_series={graphData}
                      functionality={sensorf.toUpperCase()}
                  />
                  :
                  <></>
              }
              */}

              {/*}
              { loadedr ?
                  <VoltsSensorNumeralGraph
                      data_series={graphData}
                      functionality={sensor_function.toUpperCase()}
                  />
                  :
                  <></>
              }
*/}

              { loaded && mainAttr && mainAttrObject ?
                  <>

                  <BodyCardSeriesGraph
                  seriesParams={{
                    "title": "Gráfica Sensor " + sensor['sensor']['friendly-name'],
                    "measure-unit": mainAttrObject['unit'],
                    "default_graph": mainAttrObject['default_graph'],
                    "params": {
                        "sensor-mac": sensor['sensor']['sensor-mac'],
                        "attribute": mainAttr,
                        "frequency": 900,
                        "format": "series",
                        "graph-series-attr": mainAttrObject['graph-series-attr'],
                        //"sensor-functionalities": "BOILER,HVAC",
                        "gw-mac": sensor['sensor']['gw-mac'],
                        "unit-uuid": sensor['sensor']['uuid'],
                        //"timestamp-end": Date.now().toString(),
                        //"timestamp-offset": (Date.now() - 28800000).toString(),
                      "three-d": mainAttrObject['three_d'],
                      "timestamp-end": timestampEnd * 1000,
                      "timestamp-offset": timestampOffset * 1000,
                    }
                  }
                  } />


                  </>
                :
                    <></>
              }

              { loaded && loadedr ?
                  <>

                  <VoltsSensorSortable
                      sensor={sensor.sensor}
                      states={graphData.states}
                  />

                  </>

                  :
                  <></>
              }

              {/*loaded ? <ActivitiesGyg unit={sensor.unit}/>: <></>*/}
              {/*loaded ? <VoltsSensorCards unit={unit.unit['gw-mac']} /> : <></>*/}

            </Col>
            <Col xl="4">

              {loaded ?
                  <VoltsCardsCard
                      volts_cards={[sensor.card]}
                      device_type={sensor.unit['device-type']}
                      mac_address={sensor.unit['mac-address']}
                      unit_uuid={sensor.unit['uuid']}
                  />
                  :
                  <></>
              }


              {loaded ?
                  <VoltsSensorLatestStateCard
                      sensor={sensor.sensor}
                      card={sensor.card}
                      latest_state={sensor['latest-state']}
                  />
                  :
                  <></>
              }


              { loaded ?
                  <>
                    <VoltsBaseCardJson json_object={sensor['latest-state']} />
                  </>
                  :
                  <></>
              }

              {loaded ?
                  <VoltsSensorSpecCard
                      sensor={sensor.sensor}
                      card={sensor.card}/>
                  :
                  <></>
              }

              {loaded && sensor_function === 'temp' ?
                  <VoltsTemperatureGauge
                      temp={sensor['latest-state']}
                  />
                  :
                  <></>
              }

            </Col>
          </Row>
          <Row>
            <Col xl="4">

            </Col>
            <Col xl="6">
              {/*<VoltsEvents />*/}
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default VoltsSensor;
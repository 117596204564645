import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import React from "react";
import moment from "moment";


const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/events";

function VoltsTimeline(sensors) {
    //console.log("TIMELINE3: ", sensors.sensors.sensors)
    return (
        <>
            <Card>
                <CardHeader>
                    <h5 className="h3 mb-0">Latest events in {}</h5>
                </CardHeader>
                <CardBody>
                    <div
                        className="timeline timeline-one-side"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis"
                    >
                        {sensors.sensors.sensors.map((sensor) => (
                        <div key={sensor.uuid} className="timeline-block">
                    <span className="timeline-step badge-success">
                      <i className="ni ni-bell-55" />
                    </span>
                            <div className="timeline-content">
                                <div className="d-flex justify-content-between pt-1">
                                    <div>
                          <span className="text-muted text-sm font-weight-bold">
                            {sensor['uuid']}
                          </span>
                                    </div>
                                    <div className="text-right">
                                        <small className="text-muted">
                                            <i className="fas fa-clock mr-1" />
                                            {sensor.timestamp}
                                        </small>
                                    </div>
                                </div>
                                <h6 className="text-sm mt-1 mb-0">
                                    {sensor['friendly-name']}
                                </h6>
                            </div>
                        </div>
                            ))}
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default VoltsTimeline;
import {
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    Table,
    Media,
    Badge,
    UncontrolledTooltip,
    Progress,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Pagination,
    PaginationItem,
    PaginationLink, CardFooter, Card, CardHeader, CardBody
} from "reactstrap";

//import { faBolt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {voltsIcons} from "../../variables/volts-icons";
import React from "react";
import VoltsCard from "./voltsCardAc";
import VoltsCardCheckin from "./voltsCardCheckin";
import CheckinModal from "./volts-checkin-modal";


function TableCheckins({checkins}) {
    const thirdListRef = React.useRef(null);
    return (
        <>
            <Card className="bg-default shadow">

                <CardBody >
                    <div className="table-responsive"  ref={thirdListRef}>
                        <Table className="align-items-center table-dark table-flush">
                            <thead className="thead-dark">
                            <tr>
                                <th className="sort" data-sort="propiedad" scope="col">
                                    Localización
                                </th>
                                <th className="sort" data-sort="alertas" scope="col">
                                    Energía
                                </th>
                                <th className="sort" data-sort="acciones" scope="col">
                                    Presencia
                                </th>
                                <th scope="col">Estados</th>
                                <th className="sort" data-sort="booking" scope="col">
                                    Bookings
                                </th>
                                <th scope="col"/>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {checkins.map((checkin) => (
                                <tr >
                                    <th scope="row">
                                        <Media className="align-items-center">
                                            <UncontrolledTooltip
                                                delay={0}
                                                target={"avatar-" + checkin.uuid}
                                            > {checkin.status}

                                            </UncontrolledTooltip>
                                            <a
                                                className="avatar bg-gradient-secondary rounded-circle mr-3"
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <FontAwesomeIcon id={"avatar-" + checkin.uuid} className={"text-danger"}
                                                                 icon={voltsIcons["key"]} />
                                            </a>

                                            <UncontrolledTooltip
                                                delay={0}
                                                target={"fname" + checkin["unit-uuid"]} >
                                                {checkin["gw-mac"]}
                                            </UncontrolledTooltip>
                                            <span id={"fname"+checkin["unit-uuid"]} className="propiedad mb-0 text-sm">
                                                    <a className="propiedad mb-0 text-sm"
                                                       href={"/admin/unit/" + checkin['gw-mac']} >
                                                            {checkin.name.toUpperCase()}
                                                    </a>
                                                </span>
                                        </Media>
                                    </th>
                                    <td>
                                        {new Date(checkin["check-in-int"] * 1000).toLocaleString(
                                            "es-ES",
                                            {
                                                month: "short",
                                                day: "2-digit"
                                            }
                                        )
                                        }
                                        <p>
                                            {new Date(checkin["check-in-int"] * 1000).toLocaleTimeString()}
                                        </p>
                                        {new Date(checkin["check-out-int"] * 1000).toLocaleString(
                                            "es-ES",
                                            {
                                                month: "short",
                                                day: "2-digit"
                                            }
                                        )}
                                        <p>
                                            {new Date(checkin["check-out-int"] * 1000).toLocaleTimeString("es-ES")}
                                        </p>
                                        {/*}
                                            {new Date(checkin["check-in-int"] * 1000).getMinutes()}
                                            */}

                                        {/*{new Date(checkin["check-in-int"] * 1000).getTimezoneOffset()}*/}
                                    </td>
                                    {/*
                                        <td className="alertas">

                                            {
                                                unit['dash']['power']["states"].map((powers) => (
                                                    <>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={"ps" + powers["sensor-mac"]}>
                                                        {powers.toolTip}
                                                    </UncontrolledTooltip>
                                                    <tr id={"ps" + powers["sensor-mac"]} className={"ml-1 badge badge-"+ powers['color']}>
                                                        <span><b>{powers.state}</b></span>
                                                    </tr>
                                                    </>
                                                ))
                                            }

                                            </td>
                                            */}

                                    { typeof(checkin["checkin-status"]) !== "undefined" ?
                                        <td>
                                            <Badge color="" className="badge-dot mr-4">

                                                {checkin["checkin-status"]}

                                                {/*<i className="bg-warning"/>*/}
                                                {/*
                                                    <FontAwesomeIcon className={"text-danger mr-2"} icon={icon({
                                                        name: 'circle-dot',
                                                        family: 'duotone',
                                                        style: 'solid'
                                                    })}/>*/}
                                                {/*
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={"humans-" + unit.dash.unitInfo['unit-uuid']}
                                                    > {unit.dash.humansInfo.state.toolTip + "" + new Date(unit.dash.humansInfo.timestamp * 1000).toLocaleString()}

                                                    </UncontrolledTooltip>

                                                    <span id={"humans-" + unit.dash.unitInfo['unit-uuid']}
                                                          className={"ml-1 badge " + unit.dash.humansInfo.state.color}><b>{unit.dash.humansInfo.state.text}</b></span>
*/}
                                                {/*<span className="acciones">GOH</span>*/}
                                            </Badge>
                                        </td>
                                        :
                                        <td></td>
                                    }


                                    <td>
                                        <div className="avatar-group">

                                            <>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    target={"state" + checkin.uuid}
                                                >
                                                    {checkin.status}
                                                </UncontrolledTooltip>
                                                <a
                                                    className="bg-default avatar avatar-sm rounded-circle"
                                                    href="#pablo"
                                                    id={"state" + checkin.uuid}
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    {/*}
                                                                <FontAwesomeIcon className={state.color}
                                                                                 icon={voltsIcons[state.icon]} />
                                                                                 */}
                                                </a>
                                            </>

                                        </div>
                                    </td>


                                    {/*}
                                        {typeof (unit.dash.stayInfo.progress) != "undefined" ?
                                            <td>
                                                <UncontrolledTooltip
                                                    delay={0}
                                                    target={"progress" + unit.dash.unitInfo.uuid}>
                                                    {Date(unit.dash.stayInfo.progress.timestamp)}
                                                </UncontrolledTooltip>
                                                <div>
                                                    <span
                                                        className="booking mr-2">{unit.dash.stayInfo.progress.state}%</span>
                                                    <div id={"progress" + unit.dash.unitInfo.uuid}
                                                         className="d-flex align-items-center">
                                                        <Progress max="100" value={unit.dash.stayInfo.progress.state}
                                                                  color="warning"/>
                                                    </div>
                                                </div>
                                            </td>
                                            :
                                            <></>
                                        }
                                        */}
                                    <td className="text-right">
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                className="btn-icon-only text-light"
                                                color=""
                                                role="button"
                                                size="sm"
                                            >
                                                <i className="fas fa-ellipsis-v"/>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem
                                                    href={"/admin/incidents/" + checkin['gw-mac'] + "/" +"xx"}
                                                >
                                                    Ver Alertas
                                                </DropdownItem>
                                                <DropdownItem
                                                    href={"/admin/actions/" + checkin['gw-mac'] + "/" +"xx"}
                                                >
                                                    Acciones
                                                </DropdownItem>
                                                <DropdownItem
                                                    href={"/admin/dash/" + checkin['gw-mac'] + "/" +"maindoor"}
                                                >
                                                    Acceso Principal
                                                </DropdownItem>
                                                <DropdownItem
                                                    href={"/admin/dash/" + checkin['gw-mac'] + "/" +"switchbox"}
                                                >
                                                    Cuadro Eléctrico
                                                </DropdownItem>
                                                <DropdownItem
                                                    href={"/admin/dash/" + checkin['gw-mac'] + "/" +"dash"}
                                                >
                                                    Control de Ruido
                                                </DropdownItem>
                                                <DropdownItem
                                                    href={"/admin/bookings/" + checkin['gw-mac'] + "/ll"}
                                                >
                                                    Bookings
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody >
                <CardFooter className="py-4">
                    {/*}
                <FontAwesomeIcon icon={icon({name: 'user-secret'})} />
                <FontAwesomeIcon icon={icon({name: 'coffee', style: 'regular'})} />
                <FontAwesomeIcon icon={icon({name: 'coffee', family: 'classic', style: 'light'})} />
                <FontAwesomeIcon icon={icon({name: 'coffee', family: 'classic', style: 'thin'})} />
                <FontAwesomeIcon icon={icon({name: 'coffee', family: 'duotone', style: 'solid'})} />
                <FontAwesomeIcon icon={icon({name: 'user-secret', family: 'sharp', style: 'solid'})} />
                <FontAwesomeIcon icon={icon({name: 'plate-utensils', family: 'sharp', style: 'regular'})} />
                <FontAwesomeIcon icon={icon({name: 'starship', family: 'sharp', style: 'light'})} />
                */}
                    <nav aria-label="...">
                        <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                        >
                            <PaginationItem className="disabled">
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    tabIndex="-1"
                                >
                                    <i className="fas fa-angle-left"/>
                                    <span className="sr-only">Previous</span>
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="active">
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    1
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    2 <span className="sr-only">(current)</span>
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    3
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <i className="fas fa-angle-right"/>
                                    <span className="sr-only">Next</span>
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                    </nav>
                </CardFooter>
            </Card >

        </>
    );
}

export default TableCheckins;
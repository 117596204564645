import {Card, CardHeader, CardBody, CardTitle, Badge} from "reactstrap";
import Chart from "react-apexcharts";
import React from "react";


let events_items = [];
let graphData = [];


// Colors
var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#002842",
    primary: "#00e7bd",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#ff5f69",
    warning: "#ffbcbb",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};





const VoltsTemperatureGauge =({temp}) => {
    console.log("TEMP", temp)
    let temperature = []
    let MAX_GAUGE = 50
    temp.attributes.map((attribute_row, idx) => {
        let att = {}
        if (attribute_row['attribute'] == 'temperature' || attribute_row['attribute'] == 'humidity' ) {
            att['attribute'] = attribute_row['attribute']
            att['state'] =  parseFloat(attribute_row['state'])
            att['unit'] = attribute_row['unit']
            temperature.push(att)
        }
    })
    console.log("TEMPERATURE", temperature)
    const temperatureIndex = temperature.findIndex((attrs) => attrs['attribute'] == 'temperature');
    console.log(temperatureIndex)
    var chartPower = {chart:
            {
                height: 300,
                type: "radialBar",
            },
        series: [0],
        colors: [colors.theme.info],
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#293450"
                },
                track: {
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        blur: 4,
                        opacity: 0.15
                    }
                },
                dataLabels: {
                    name: {
                        offsetY: -20,
                        color: "#fff",
                        fontSize: "20px"
                    },
                    value: {
                        color: "#fff",
                        fontSize: "40px",
                        show: true,
                        formatter: function ( val ) { return val * (50/100) + temperature[temperatureIndex].unit}
                    }
                }
            }
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "vertical",
                gradientToColors: [colors.theme.danger],
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: "round"
        },
        labels: ["Temperatura"]
    }


  return (
      <>
                <Card>
                        <CardHeader>
                          <h6 className="surtitle">Power Spending</h6>
                          <h5 className="h3 mb-0">Realtime</h5>
                        </CardHeader>
                        <CardBody>
                          <div className="chart">
                            <Chart
                                options={chartPower}
                                series={[(100/MAX_GAUGE) * temperature[temperatureIndex].state]}
                                type="radialBar"
                                width="300"
                                height="300"
                            />
                          </div>
                        </CardBody>
                </Card>
      </>
  )
}
export default VoltsTemperatureGauge;
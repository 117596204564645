import React, { useState, useContext } from 'react';
import {Account, AccountContext} from './Accounts';
import Pool from '../../../../src/UserPool'
import Status from "../../pages/components/Status";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
import classnames from "classnames";
import {Redirect} from "react-router";
import { UncontrolledAlert } from "reactstrap";
//import UserPool from "../../../UserPool";
//import poolData from "../../../UserPool";
//import AmazonCognitoIdentity from "amazon-cognito-identity-js";

{/*}
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';

*/}

//import Pool from "../../UserPool";
//import { Account } from '../../views/pages/components/Accounts';


var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
//var cognitoUser, sessionUserAttributes;


var authenticationData = {
    Username: 'jose@volts360.com', //'d664db9b-9a35-456f-ad3c-4ad6334d8b84',
    Password: 'B6sOgm1,',
};
var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
);

var userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: 'eu-west-1_dMKEAGjGf',
    ClientId: '13o995tuboj0if84sgnnk2tp9m'
});

var sessionUserAttributes; // global variables to handle completeNewPasswordChallenge flow

var userData = {
    Username: "", //'hola@volts360.com', //'d664db9b-9a35-456f-ad3c-4ad6334d8b84',
    Pool: userPool,
};

var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);




export const GetAuthenticatedUser = () => {
    //console.log("POOL function user GETauthUser:", Pool.getCurrentUser())
    return Pool.getCurrentUser();

};

function handleNewPassword(username, newPassword, sessionAtt)
{
    //console.log("USERNAME:" , username, newPassword)
    {/*}
    userData = {
        Username: username, //'hola@volts360.com', //'d664db9b-9a35-456f-ad3c-4ad6334d8b84',
        Pool: userPool,
    };
    cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

*/}

    //console.log(username, newPassword, "handleNewPassword: ", cognitoUser, sessionUserAttributes, "SESSION ATT: ", sessionAtt)
    cognitoUser.completeNewPasswordChallenge(newPassword, {},{
        onSuccess: function(result) {
            //console.log("RESULT: ", result)
            return(200)
        },

        onFailure: function(err) {
            //console.log("ERROR completeNewPasswordChallenge: ", err)
            return(400)
            //seterrorMessage(err)
        },} );

}


function ChangePassword(username, oldPassword, password) {
    //console.log("USERNAME CHP: ", username)
    userData = {
        Username: username, //'hola@volts360.com', //'d664db9b-9a35-456f-ad3c-4ad6334d8b84',
        Pool: userPool,
    };
    cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    var authenticationData = {
        Username: username, //'d664db9b-9a35-456f-ad3c-4ad6334d8b84',
        Password: oldPassword,
    };
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
        authenticationData
    );

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function(result) {
            // User authentication was successful
        },

    onFailure: function(err) {
            //console.log(err)
        },

        mfaRequired: function(codeDeliveryDetails) {
            // MFA is required to complete user authentication.
            // Get the code from user and call
            var mfaCode = "222222"
            cognitoUser.sendMFACode(mfaCode, this)
        },

        newPasswordRequired: function(userAttributes, requiredAttributes) {
            // User was signed up by an admin and must provide new
            // password and required attributes, if any, to complete
            // authentication.
            //console.log("USER_ATTRIBUTES: ", userAttributes)
            // the api doesn't accept this field back
            delete userAttributes.email_verified;
            // store userAttributes on global variable
            sessionUserAttributes = userAttributes;
            //console.log("SESSIONUSERATTRIBUTES", sessionUserAttributes)
            return(handleNewPassword(username, password,sessionUserAttributes))
        }
    });

    // ... handle new password flow on your app

    //{

    //}
}


export default () => {
    const [focusedEmail, setfocusedEmail] = React.useState(false);
    const [focusedPassword, setfocusedPassword] = React.useState(false);
    const [loginError, setloginError] = React.useState(false);
    const [errorMessage, seterrorMessage] = React.useState("www");
    const [loggedIn, setLoggedIn] = useState('');
    const [newPassword, setnewPassword] = useState('')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword1, setcheckPassword1] = useState('');
    const [checkPassword2, setcheckPassword2] = useState('');
    //const [loginData, setLoginData] = useState('');
    //const { authenticate } = useContext(AccountContext);
    const { changePw } = useContext(AccountContext);
    const { getSession, authenticate } = useContext(AccountContext);


    class Alerts extends React.Component {
        render() {
            return (
                <>
                    <UncontrolledAlert color="danger" fade={false}>
                    <span className="alert-inner--icon">
                    <i className="ni ni-bell-55" />
                  </span>{" "}
                        <span className="alert-inner--text">
                        <strong>{errorMessage.name}!</strong> {errorMessage.message}!
                    </span>
                    </UncontrolledAlert>
                </>
            );
        }
    }

    const onSubmit = event => {
        event.preventDefault();
        authenticate(email, password)
            .then(data => {
                //console.log('Logged in!', data);
                if ('newPasswordRequired' in data)
                {
                    setnewPassword(true)
                    //console.log("preChangePwDATA ", data)
                }
                else {
                    setLoggedIn(data)
                }
            })
            .catch(err => {
                seterrorMessage(err)
                setloginError(true)
                //console.error('CONSOLE Failed to login!', err);
                //console.log(errorMessage)
            })
        };

    const onSubmitChangePassword = event => {
        event.preventDefault();
        //console.log(event, checkPassword1, checkPassword2)
        //getSession().then(({ user, email }) => {
        if (checkPassword1 === checkPassword2) {
            let responseChangePassword = ChangePassword(email, password, checkPassword1)
            //console.log("response change password: ", responseChangePassword)
            if (responseChangePassword === 200)
                setLoggedIn(true)
                setnewPassword(false)
        }
        };


    return (
        <Account >
        <div>
            <div>
                {loginError ? <Alerts /> : <></>}
            </div>
            {loggedIn && (
                <Redirect to="/admin/dashboard" replace={true} />
            )}
            {newPassword ?
                <div>
                    <Form role="form" onSubmit={onSubmitChangePassword}>
                        <FormGroup
                            className={classnames("mb-3", {
                                focused: focusedPassword,
                            })}
                        >
                            <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder=""
                                    type="password"
                                    onFocus={() => setfocusedPassword(true)}
                                    onBlur={() => setfocusedPassword(true)}
                                    value={checkPassword1}
                                    onChange={event => setcheckPassword1(event.target.value)}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup
                            className={classnames({
                                focused: focusedPassword,
                            })}
                        >
                            <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder=""
                                    type="password"
                                    onFocus={() => setfocusedPassword(true)}
                                    onBlur={() => setfocusedPassword(true)}
                                    value={checkPassword2}
                                    onChange={event => setcheckPassword2(event.target.value)}
                                />
                            </InputGroup>
                        </FormGroup>
                        {/*
                            <div className="text-center">
                                <Button type="submit" className="my-4" color="info">Cambiar Contraseña</Button>
                            </div>
                            */}
                    </Form>

                </div>

                :

                <Form role="form" onSubmit={onSubmit}>
                    <FormGroup
                        className={classnames("mb-3", {
                            focused: focusedEmail,
                        })}
                    >
                        <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-email-83"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Email"
                                type="email"
                                onFocus={() => setfocusedEmail(true)}
                                onBlur={() => setfocusedEmail(true)}
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup
                        className={classnames({
                            focused: focusedPassword,
                        })}
                    >
                        <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-lock-circle-open"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Password"
                                type="password"
                                onFocus={() => setfocusedPassword(true)}
                                onBlur={() => setfocusedPassword(true)}
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                            />
                        </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                        />
                        <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                        >
                            <span className="text-muted">Recordar mis datos</span>
                        </label>
                    </div>
                    <div className="text-center">
                        <Button type="submit" className="my-4" color="primary">
                            Acceder
                        </Button>
                    </div>
                </Form>
            }
        </div>
        <Status />
        </Account>
    );
};
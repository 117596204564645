import React from "react";
import axios from "axios"
import { useState, useEffect, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate
} from "react-router-dom";
import ListUnits from "../../../components/VoltsCards/ListUnits";
//import VoltsToDo from "../../../components/VoltsCards/volts-to-do";
//import VoltsEvents from "../../../components/VoltsCards/volts-events";
//import VoltsSensorCards from "../../../components/VoltsCards/volts-sensor-cards";
//import CardSimple from "../../../components/VoltsCards/CardSimple";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
//import { Chart } from "chart.js";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  //Badge,
  //Button,
  Card,
  CardHeader,
  //CardBody,
  //DropdownMenu,
  //DropdownItem,
  //DropdownToggle,
  //UncontrolledDropdown,
  //Form,
  //Input,
  //ListGroupItem,
  //ListGroup,
  //Media,
  //NavItem,
  //NavLink,
  //Nav,
  //Progress,
  //Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip, Pagination, PaginationItem, PaginationLink, CardFooter,
} from "reactstrap";

// core components
import DashHeader from "components/Headers/CardsHeaderDash.js";
import Pool from "../../../UserPool";
import VoltsDashPaneCheckins from "components/VoltsCards/volts-dash-pane-checkins";


import VoltsMultiRadialCard from "../../../components/VoltsCards/volts-graph-cards/volts-multi-radial-graph";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'




{/*}
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

*/}


let checkin_items = [];

async function axios_checkins(body) {
  console.log("BODY: ", body)
  const response = await axios.post("https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/checkins", body)
  console.log("AXIOS CHECKINS", response)
  return response.data
}


function set_checkins(data) {
  checkin_items = data
  //console.log("SET UNITS", checkin_items)
  return checkin_items
}


function Dashboard() {

  //console.log("PROCCESS: ", process.argv)
  const [currentUser, setcurrentUser] = React.useState(null);
  const [checkins, setCheckins] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);
  const thirdListRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    {/*setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");*/}
  };
  {/*if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }*/}
  useEffect(() => {
    const loadData = async (userName) => {
      //setcurrentUser(user)
      //console.log(userName)
      const data = await axios_checkins({userName: userName});
      setCheckins(data);
      set_checkins(data);
      console.log("STATUS CODE ?????", data)
      console.log("DATA", data)
      // check if response has units inside
      if (data['statusCode'] === 200){
        setLoaded(true);
      }
    };
    setTimeout(() => {
      //console.log("USER IN USE EFFECT: ", user['username'])
      loadData(user['username']);
    }, 1);
  },[]);
  //axios_unit().then(unit_items => set_units(unit_items));
  //console.log("UNITS", unit_items)
  let user = Pool.getCurrentUser()
  //setcurrentUser(user)
  //console.log("XXXXX>", user)

  return (
      <>
        <DashHeader name="volts" parentName="Home"/>
        <Container className="mt--6" fluid>
          <Row>
            <div className="bg-white col">
              {/*}<Card className="bg-white shadow">*/}

                <CardHeader className="bg-transparent border-0">
                  <h3 className="text-default mb-0">{"Bookings"}</h3>
                </CardHeader>
                {loaded ? <VoltsDashPaneCheckins checkins={checkin_items.items} /> : <h2>------loading checkins</h2>}
              {/*}</Card>*/}
            </div>
          </Row>
        </Container>
      </>
  );
}

export default Dashboard;
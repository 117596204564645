import React from "react";
import classnames from "classnames";

// reactstrap components
import {
    Card,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane, CardHeader, ListGroup, ListGroupItem
} from "reactstrap";



function GuestList({checkin}) {
    let guest_list = checkin["checkin-guest-list"]
    console.log("Guests: ", guest_list.length)
    return (
        <>
            { checkin["checkin-guest-list"] ?
                <Card>
                    <CardHeader>
                        <b>{"Registered Guests"}</b>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <ul>
                                {guest_list.map((guest) =>
                                    <li><b>{guest["guestName"]}{" "}{guest["guestSurname"]}</b>{" "}{guest["phone"]}{" "}{guest["email"]}</li>
                                )}
                            </ul>
                        </div>
                    </CardBody>
                </Card> : <></>
            }
        </>
    )
}


function CheckinMessageList({checkin}) {
    if (!checkin["lock-messages"]) {
        return (<></>)
    }


    return (
        <Card>
            <CardHeader>
                <h5 className="h3 mb-0">Access Information</h5>
            </CardHeader>
            <CardBody className="p-0">
                <ListGroup flush>
                    { checkin["lock-messages"]["locks"].map((lock) =>


                        <>
                            <ListGroupItem
                                className="list-group-item-action flex-column align-items-start py-4 px-4"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                tag="a"
                            >
                                <div className="d-flex w-100 justify-content-between">
                                    <div>
                                        <div className="d-flex w-100 align-items-center">
                                            {/*
                                            <img
                                                alt="..."
                                                className="avatar avatar-xs mr-2"
                                                src={require("assets/img/brand/volts-blue-min.png").default}
                                            />*/}
                                            <h5 className="mb-1">volts</h5>
                                        </div>
                                    </div>
                                    <small>volts</small>
                                </div>
                                <h4 className="mt-3 mb-1">{lock["door"]}{"-"}{"DOOR"}</h4>
                                <p className="text-sm mb-0">
                                    {lock.message}
                                </p>
                            </ListGroupItem>
                        </>
                    )}
                    {checkin["lock-messages"]["wifi"] ?
                        <>
                            <ListGroupItem
                                className="list-group-item-action flex-column align-items-start py-4 px-4"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                tag="a"
                            >
                                <div className="d-flex w-100 justify-content-between">
                                    <div>
                                        <div className="d-flex w-100 align-items-center">
                                            {/*<img
                                                alt="..."
                                                className="avatar avatar-xs mr-2"
                                                src={require("assets/img/theme/team-1.jpg").default}
                                            />*/}
                                            <h5 className="mb-1">wi-fi</h5>
                                        </div>
                                    </div>
                                    <small>volts</small>
                                </div>
                                <h4 className="mt-3 mb-1">{"WI-FI Details"}</h4>
                                <p className="text-sm mb-0">
                                    {checkin["lock-messages"]["wifi"]["message"]}
                                </p>
                            </ListGroupItem>
                        </> : <></>
                    }


                </ListGroup>
            </CardBody>
        </Card>

    )

}


class VoltsNavs extends React.Component {
    checkin = this.props.checkin;
    state = {
        tabs: 1
    };
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };
    render() {
        return (
            <>
                <div className="nav-wrapper">
                    <Nav
                        className="nav-fill flex-column flex-md-row"
                        id="tabs-icons-text"
                        pills
                        role="tablist"
                    >
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 1}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 1
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 1)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-circle-08 mr-2" />
                                {"Guests"}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 2}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 2
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 2)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-lock-circle-open mr-2" />
                                {"Access Info"}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 3}
                                className={classnames("mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 3
                                })}
                                onClick={e => this.toggleNavs(e, "tabs", 3)}
                                href="#pablo"
                                role="tab"
                            >
                                <i className="ni ni-email-83 mr-2" />
                                Messages
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <Card className="shadow">
                    <CardBody>
                        <TabContent activeTab={"tabs" + this.state.tabs}>
                            <TabPane tabId="tabs1">
                                <GuestList checkin={this.checkin} />
                                <p className="description">
                                </p>
                                <p className="description">
                                </p>
                            </TabPane>
                            <TabPane tabId="tabs2">
                                <CheckinMessageList checkin={this.checkin} />
                                <p className="description">
                                </p>
                            </TabPane>
                            <TabPane tabId="tabs3">
                                <p className="description">
                                </p>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default VoltsNavs;
import React from "react";
import { useState, useEffect, useCallback, useRef } from "react";

import List from "list.js";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {voltsIcons} from "../../../variables/volts-icons";

const API_URL = "https://b4wh4gjbhc.execute-api.eu-west-1.amazonaws.com/test/switchs"

let bookings_list = []


function VoltsBookingsPagination(){
  return(
  <>
    <nav aria-label="...">

    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
  >
    <PaginationItem className="disabled">
      <PaginationLink
          href="#pablo"
          onClick={(e) => e.preventDefault()}
          tabIndex="-1"
      >
        <i className="fas fa-angle-left" />
        <span className="sr-only">Previo</span>
      </PaginationLink>
    </PaginationItem>
    <PaginationItem className="active">
      <PaginationLink
          href="#pablo"
          onClick={(e) => e.preventDefault()}
      >
        1
      </PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink
          href="#pablo"
          onClick={(e) => e.preventDefault()}
      >
        2 <span className="sr-only">(current)</span>
      </PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink
          href="#pablo"
          onClick={(e) => e.preventDefault()}
      >
        3
      </PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink
          href="#pablo"
          onClick={(e) => e.preventDefault()}
      >
        <i className="fas fa-angle-right" />
        <span className="sr-only">Siguiente</span>
      </PaginationLink>
    </PaginationItem>
  </Pagination>
    </nav>
  </>
    )
}
function GetLocalTimeText(timestamp){
  return new Date(Number(timestamp) * 1000).toLocaleString('es-ES',
      {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      })
}

function GetLocalDateText(timestamp){
  return new Date(Number(timestamp) * 1000).toLocaleString('es-ES',
      {
        day: 'numeric',
        month: 'numeric',
        weekday: 'long',
        hour12: false
      })
}

function VoltsTableDropdown(){
  const switch_row = [{}]
  function OnOption(e, sensor_mac, sw_command)
  {
    getData(sensor_mac, sw_command)
    e.preventDefault()
    console.log("E", e.button)
    //alert(sensor_mac +" " + sw_command);
  }
  const getData = async (sensor_mac, command) => {
    const response = await axios.put(API_URL, null,{
      headers: {
        'Content-Type': 'application/json'
      },
      params:
          {
            "sensor-mac": sensor_mac,
            "gw-mac": "volts00000103",
            "command": command
          }
    })
    return response.data;
  }
return (
    <>
  <UncontrolledDropdown>
    <DropdownToggle
        className="btn-icon-only text-light"
        color=""
        role="button"
        size="sm"
    >
      <i className="fas fa-ellipsis-v" />
    </DropdownToggle>
    <DropdownMenu className="dropdown-menu-arrow" right>
      <DropdownItem
          href="#pablo"
          onClick={(e) => OnOption(e, switch_row['sensor-mac'], 1)}
      >
        Action ON
      </DropdownItem>
      <DropdownItem
          href="#pablo"
          onClick={(e) => OnOption(e,switch_row['sensor-mac'],0)}
      >
        Action OFF
      </DropdownItem>
      <DropdownItem
          href="#pablo"
          onClick={(e) => OnOption(e,switch_row['sensor-mac'],0)}
      >
        Power Bill
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
</>
)
}

function BookingSortable({bookings}) {
  const bookingsListRef = React.useRef(null);
  const [loaded, setLoaded] = React.useState(null);

  //const secondListRef = React.useRef(null);
  //const thirdListRef = React.useRef(null);

  const getData = async (sensor_mac, command) => {
    const response = await axios.put(API_URL, null,{
      headers: {
        'Content-Type': 'application/json'
      },
      params:
          {
            "sensor-mac": sensor_mac,
            "gw-mac": "volts00000103",
            "command": command
          }
    })
    return response.data;
  }
  {/*}
  const handleData = async () => {
    const resp = await axios.get("api/city");
    const data = await resp.data.content;
    setData(data);
    setFilteredData(data);
    //setData(data);
  };
  */}

  React.useEffect(() => {
    new List(bookingsListRef.current, {
      valueNames: ["ref", "status", "check-in"],
      listClass: "list",
    });
    let timeNow = Math.floor(Date.now() / 1000)
      let timeNowLocal = GetLocalDateText(timeNow)
    bookings.map((booking) => {
      //console.log(booking)
      bookings_list.push({
            booking_uuid: booking.uuid,
            check_in_time_text: GetLocalTimeText(booking['check-in-int']),
            check_out_time_text: GetLocalTimeText(booking['check-out-int']),
            check_in_date_text: GetLocalDateText(booking['check-in-int']),
            check_out_date_text: GetLocalDateText(booking['check-out-int']),
            booking_provider: booking['booking-provider'],
            booking_status: booking['status'],
            guest_name: booking['name'],
            guest_language: booking['language'],
            booking_creation_int: Math.trunc(Number(booking['booking-creation'])),
            gw_mac: booking['gw-mac'],
            unit_url: "/admin/unit/"+ booking['gw-mac'],
            unit_uuid: booking['unit-uuid'],
            booking_reference: booking['booking-reference'],
            guest_pax_total: booking['pax-total'],
            guest_kids: booking['kids'],
            guest_email: booking['email'],
            booking_provider_um_email: booking['email'],
            stay_sensor: booking['sensor-mac'],
            check_in_int: booking['check-in-int'],
            check_out_int: booking['check-out-int']
      })
    })
    console.log(bookings_list)
      setLoaded( true)
    {/*}
    new List(secondListRef.current, {
      valueNames: ["name", "budget", "status", "completion"],
      listClass: "list",
    });
    new List(thirdListRef.current, {
      valueNames: ["name", "budget", "status", "completion"],
      listClass: "list",
    });
    */}
  }, []);
  function OnOption(e, sensor_mac, sw_command)
  {
    getData(sensor_mac, sw_command)
    e.preventDefault()
    console.log("E", e.button)
    //alert(sensor_mac +" " + sw_command);
  }
  console.log("BOOKINGS ON TABLE", bookings)





  return (
    <>

            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Last Bookings</h3>
              </CardHeader>
              { loaded ?
                  <div className="table-responsive" ref={bookingsListRef}>
                    <Table className="align-items-center table-responsive">
                      <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="ref" scope="col">
                          Ref
                        </th>
                        <th className="sort" data-sort="check-in" scope="col">
                          Check-In
                        </th>
                        <th className="sort" data-sort="status" scope="col">
                          Status
                        </th>
                      </tr>
                      </thead>

                      <tbody className="list">
                      {bookings_list.map((booking_row, idx) => (
                          <tr key={"child" + booking_row.booking_uuid}>
                              <th scope="row">
                                  <Media className="align-items-center">
                                      <a
                                          className="avatar bg-gradient-secondary rounded-circle mr-3"
                                          href={booking_row.unit_url}
                                          //onClick={e => e.preventDefault()}
                                      >

                                              <FontAwesomeIcon id={"avatar-" + booking_row.booking_uuid} className={"text-primary"}
                                                               icon={voltsIcons["bell-concierge"]} />

                                      </a>
                                  </Media>
                              </th>
                                  <th>
                                          <Media className="align-items-center">

                                              <Button color="success" outline size="sm" type="button">
                                                  {booking_row.booking_status}
                                              </Button>

                                            <p className={"mb-0 text-xs"}>
                                                {booking_row.guest_name}
                                            </p>

                                          </Media>
                              </th>
                            <th scope="row">
                              <Media>
                                  <span className="check-in mb-0 text-sm">
                                    <div>
                                      {booking_row.check_in_date_text}
                                    </div>
                                      <div className={"text-xs"}>
                                      {booking_row.check_in_time_text}
                                    </div>
                                      <div>
                                      {booking_row.check_out_date_text}
                                    </div>
                                      <div className={"text-xs"}>
                                      {booking_row.check_out_time_text}
                                    </div>
                                </span>
                              </Media>
                            </th>
                              {/*}
                              <td>
                                  <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-warning"/>
                                      <span className="status">{booking_row.booking_status}</span>
                                  </Badge>
                              </td>
                              */}
                              {/*}
                            <th>
                              <Media className="align-items-center">
                                <Button color="success" outline size="sm" type="button">
                                  {booking_row.booking_status}
                                </Button>
                              </Media>
                            </th>
                            */}
                            {/*}
                          <td className="budget">{booking_row['pax']}</td>

                          <td>
                            <Button color="success" outline size="sm" type="button">
                              Toggle
                            </Button>
                          </td>

                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">60%</span>
                              <div>
                                <Progress max="100" value="60" color="warning" />
                              </div>
                            </div>
                          </td>
                          */}
                            <td className="text-right">
                              <VoltsTableDropdown/>
                            </td>
                          </tr>
                      ))}

                      </tbody>

                    </Table>
                  </div>
                  :
                  <></>
              }
              <a
                  className="text-center text-info font-weight-bold py-3"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
              >
                View all Bookings
              </a>
              {/*<CardFooter className="py-4">
                <VoltsBookingsPagination />
              </CardFooter>
              */}
            </Card>
    </>
  );
}

export default BookingSortable;
